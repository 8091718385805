export default function extrasFromInitialData(initialData, numPassengers) {
  return {
    // HACK: This is a bit of a hack to handle `stroller` and `pet` being set to
    // `NaN` in the initial data after being redirected from the home page search.
    // Why this is I don't know, but this is a quick fix to handle it for now...
    stroller:
      initialData.stroller === "true" || Number.isNaN(initialData.stroller),
    pet: initialData.pet === "true" || Number.isNaN(initialData.pet),
    wheelchairs:
      (numPassengers >= 1 && initialData.wheelchairs === "1") ||
      (numPassengers >= 2 && initialData.wheelchairs === "2")
        ? Number(initialData.wheelchairs)
        : 0,
    walkers:
      (numPassengers >= 1 && initialData.walkers === "1") ||
      (numPassengers >= 2 && initialData.walkers === "2")
        ? Number(initialData.walkers)
        : 0,
  };
}
