import PropTypes from "prop-types";
import React from "react";

import styles from "./ErrorsList.module.css";

ErrorsList.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
};

export default function ErrorsList({ errors }) {
  if (errors.length <= 0) {
    return null;
  }

  return (
    <ul className={styles.errors}>
      {errors.map((message, index) => (
        <li key={index} data-cy="form-error">
          {message}
        </li>
      ))}
    </ul>
  );
}
