import PropTypes from "prop-types";
import React from "react";

import { onWindowResize } from "#utils/dom";

import cssGlobals from "../../main/css/globals";

const instances = new Set();

/**
 * Render something based on the window size. Automatically re-renders when the
 * window size changes. Good for doing responsive stuff when CSS is not enough.
 */
export default class WithWindowSize extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    onResize: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  };

  static defaultProps = {
    onResize: undefined,
  };

  state = {
    // makes sure that the first render matches the server output.
    // We update to the real windowSize in componentDidMount()
    windowSize: getWindowSize({ useServerWindowSize: true }),
  };

  componentDidMount() {
    instances.add(this);
    this.setState({
      windowSize: getWindowSize(),
    });
  }

  componentWillUnmount() {
    instances.delete(this);
  }

  render() {
    const { children } = this.props;
    const { windowSize } = this.state;

    if (typeof children === "function") {
      return children(windowSize);
    }

    return children;
  }
}

function getWindowSize({ useServerWindowSize = false } = {}) {
  const width = useServerWindowSize
    ? 1366
    : document.documentElement.clientWidth;
  const height = useServerWindowSize
    ? 768
    : document.documentElement.clientHeight;

  return {
    width,
    height,
    useServerWindowSize,
    xsDown: width < cssGlobals["g-minWidth_sm"],
    smDown: width < cssGlobals["g-minWidth_md"],
    mdDown: width < cssGlobals["g-minWidth_lg"],
    lgDown: width < cssGlobals["g-minWidth_xl"],
    smUp: width >= cssGlobals["g-minWidth_sm"],
    mdUp: width >= cssGlobals["g-minWidth_md"],
    lgUp: width >= cssGlobals["g-minWidth_lg"],
    xlUp: width >= cssGlobals["g-minWidth_xl"],
  };
}

// Add only one resize listener and batch updates.
onWindowResize(() => {
  const windowSize = getWindowSize();

  instances.forEach((instance) => {
    const { onResize } = instance.props;

    instance.setState({ windowSize });

    if (onResize) {
      onResize();
    }
  });
});
