import React from "react";

import { PASSENGER_TYPES } from "../consts";

export default function getPassengerTypeName(passengerType) {
  const type = PASSENGER_TYPES.find((type2) => type2.id === passengerType);
  return type == null ? undefined : (
    <>
      {type.nameSingular}
      {React.cloneElement(type.age, {
        render: function render(state) {
          return state.type === "success" &&
            /\S/.test(state.content.props.children) ? (
            <>, {state.content}</>
          ) : null;
        },
      })}
    </>
  );
}
