import PropTypes from "prop-types";

import {
  BookingClass,
  PassengerType,
  TierLevel,
  TRAFFIC_PROVIDERS,
  VEHICLE_TYPES,
} from "./enums";

export const ticketPropType = PropTypes.shape({
  passenger_type: PropTypes.oneOf(Object.values(PassengerType)),
  booking_class: PropTypes.oneOf(Object.values(BookingClass)).isRequired,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  price: PropTypes.number,
  phone: PropTypes.string,
  has_stroller: PropTypes.bool,
  has_pet: PropTypes.bool,
  has_wheelchair: PropTypes.bool,
  has_walker: PropTypes.bool,
  addons: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  seats: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      display: PropTypes.number.isRequired,
      carriage: PropTypes.string,
    })
  ),
}).isRequired;

export const dateTimePropType = PropTypes.shape({
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
});

export const userPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstname: PropTypes.string.isRequired,
  lastname: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  last_changed_identity_at: PropTypes.string,
  tier_level: PropTypes.shape({
    tier_level: PropTypes.oneOf(Object.values(TierLevel)),
    created_at: PropTypes.string,
    expires_at: PropTypes.string,
  }),
  loyalty_points: PropTypes.number.isRequired,
  next_loyalty_level: PropTypes.object,
  membership_number: PropTypes.string.isRequired,
  corporate_memberships: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
});

export const MoneyPropType = PropTypes.shape({
  amount: PropTypes.number,
  currency: PropTypes.string,
});

export const directionPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const inboundOutboundPropType = PropTypes.shape({
  departure_at: dateTimePropType.isRequired,
  arrival_at: dateTimePropType.isRequired,
  origin: directionPropType,
  destination: directionPropType,
});

export const legPropType = PropTypes.shape({
  destination_id: PropTypes.string,
  origin_id: PropTypes.string,
  travel_direction: PropTypes.oneOf([0, 1]),
  vehicle_types: PropTypes.arrayOf(PropTypes.number).isRequired,
});

export const departurePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  departure_at: dateTimePropType.isRequired,
  arrival_at: dateTimePropType.isRequired,
  origin: directionPropType,
  destination: directionPropType,
  legs: PropTypes.arrayOf(legPropType),
  schedule_reference: PropTypes.number.isRequired,
  tickets: PropTypes.arrayOf(ticketPropType),
  traffic_provider: PropTypes.oneOf(Object.values(TRAFFIC_PROVIDERS))
    .isRequired,
  transport_type: PropTypes.oneOf(Object.values(VEHICLE_TYPES)).isRequired,
});

export const journeyPropTypes = PropTypes.shape({
  id: PropTypes.string,
  departure_at: dateTimePropType,
  arrival_at: dateTimePropType,
  origin: directionPropType,
  destination: directionPropType,
  vat_amount: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
  }),
  departures: PropTypes.arrayOf(departurePropType),
  booking: PropTypes.shape({
    id: PropTypes.string.isRequired,
    reference: PropTypes.string.isRequired,
  }),
});
