import { Node } from "djedi-react";
import PropTypes from "prop-types";
import React from "react";

// import CloseIcon from "#icons/cross.svg";
// import WarningIcon from "#icons/warning.svg";
import Button from "../Button";
import ModalBase from "./base";
import styles from "./warning.module.css";

ModalWarning.propTypes = {
  // The title can be either a string or a djedi `<Node>`.
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]).isRequired,
  children: PropTypes.node.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onConfirmText: PropTypes.element,
  onRequestCloseText: PropTypes.element,
  // ...restProps passed on to ModalBase.
};

ModalWarning.defaultProps = {
  onConfirm: undefined,
  onConfirmText: undefined,
  onRequestCloseText: undefined,
};

export default function ModalWarning({
  title,
  children,
  onRequestClose,
  onRequestCloseText,
  onConfirm,
  onConfirmText,
  ...restProps
}) {
  return (
    <MaybeDjediString value={title}>
      {(titleString) => (
        <ModalBase
          contentLabel={titleString}
          onRequestClose={onRequestClose}
          {...restProps}
        >
          <div className={styles.root} data-cy="modal">
            <div className={styles.inner}>
              <div className={styles.titleBar}>{titleString}</div>
              <div>{children}</div>

              <div className={styles.buttons}>
                {onRequestClose && onRequestCloseText && (
                  <Button
                    type="button"
                    color="tertiary"
                    variant="outlined"
                    onClick={onRequestClose}
                  >
                    {onRequestCloseText}
                  </Button>
                )}
                {onConfirm && onConfirmText && (
                  <Button type="button" color="primary" onClick={onConfirm}>
                    {onConfirmText}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </ModalBase>
      )}
    </MaybeDjediString>
  );
}

MaybeDjediString.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]).isRequired,
  children: PropTypes.func.isRequired,
};

function MaybeDjediString({ value, children }) {
  if (typeof value === "string") {
    return children(value);
  }

  if (value.type === Node) {
    return React.cloneElement(value, {
      edit: false,
      render: (state) =>
        state.type === "success" ? children(state.content) : null,
    });
  }

  throw new Error(
    `Invalid <ModalConfirm title>. Expected a string or a djedi <Node> but got: ${value}`
  );
}
