import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./withRoundness.module.css";

// face: PropTypes.oneOf(["up", "right", "down", "left"]),

const withRoundness =
  (Component) =>
  ({ face: defaultFace } = { face: undefined }) => {
    const WrappedComponent = ({ className, face = defaultFace, ...props }) => (
      <Component
        {...props}
        className={classnames(
          styles.root,
          {
            [styles.faceUp]: face === "up",
            [styles.faceRight]: face === "right",
            [styles.faceDown]: face === "down",
            [styles.faceLeft]: face === "left",
          },
          className
        )}
      />
    );

    WrappedComponent.propTypes = {
      face: PropTypes.oneOf(["up", "down", "right", "left", undefined]),
      className: PropTypes.string,
    };

    WrappedComponent.defaultProps = {
      face: undefined,
      className: undefined,
    };

    return WrappedComponent;
  };

export default withRoundness;
