import { debounce } from "lodash";
import { useState } from "react";

import useEventListener from "./useEventListener";

const useScroll = (ms = 1 / 60, options = { maxWait: 400 }) => {
  const [scrollY, setScrollY] = useState(0);

  const handler = debounce(
    () => {
      const { top } = document.body.getBoundingClientRect();
      setScrollY(-top);
    },
    ms,
    options
  );

  useEventListener("scroll", handler, true);

  return scrollY;
};
export default useScroll;
