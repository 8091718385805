import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import StyledLink from "#components/StyledLink";
import ArrowRight from "#icons/new/specific-text-arrow-time@14px14px.svg";

import styles from "./ArrowLink.module.css";

const ArrowLink = React.forwardRef(function ArrowLink(
  { direction, className, size, children, ...props },
  _
) {
  return (
    <StyledLink
      className={classnames(styles.root, className, {
        [styles.md]: size === "md",
        [styles.lg]: size === "lg",
      })}
      {...props}
    >
      {direction === "left" && (
        <ArrowRight
          className={classnames(styles.svg, styles.leftFacing)}
          fill="currentColor"
          stroke="currentColor"
        />
      )}
      {children}
      {direction !== "left" && (
        <ArrowRight
          className={styles.svg}
          fill="currentColor"
          stroke="currentColor"
        />
      )}
    </StyledLink>
  );
});

ArrowLink.propTypes = {
  direction: PropTypes.oneOf(["left", "right"]),
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(["md", "lg"]),
  color: PropTypes.oneOf(["primary", "secondary", "textPrimary", "white"]),
};

ArrowLink.defaultProps = {
  direction: "right",
  children: null,
  size: "md",
  className: undefined,
  color: "secondary",
};

export default ArrowLink;
