export default function individualAddonTotal(tickets, addonsType) {
  return tickets.reduce(
    (ticketsTotal, { addons = [] }) =>
      ticketsTotal +
      addons.reduce(
        (addonsTotal, { type, price: { amount = 0 } }) =>
          addonsType === type ? addonsTotal + amount : addonsTotal,
        0
      ),
    0
  );
}
