import { md, Node } from "djedi-react";
import React from "react";

import { ABSOLUTE_MAX_PASSENGERS, MAX_PER_TYPE } from "../../../../consts";

export const MAX_PER_TYPE_MESSAGE = (
  <Node uri="PassengerChooser/MAX_PER_TYPE_MESSAGE" num={MAX_PER_TYPE}>
    Max [num]
  </Node>
);

export const GENERIC_PASSENGERS = {
  namePlural: (
    <Node uri="PassengerChooser/GENERIC_PASSENGERS">Passagerare</Node>
  ),
  nameSingular: (
    <Node uri="PassengerChooser/GENERIC_PASSENGERS">Passagerare</Node>
  ),
};

export const CLOSE = <Node uri="PassengerChooser/close">Stäng</Node>;

export const ERRORS = {
  TOO_MANY_PASSENGERS_ABSOLUTE: (
    <Node
      uri="PassengerChooser/error/max-passengers/text.md"
      max={ABSOLUTE_MAX_PASSENGERS}
    >{md`
      **Ni ser ut att bli ett stort gäng. Roligt!**

      Är ni fler än [max] resenärer som planerar att åka med MTRX? Då får ni gärna kontakta oss via formuläret för gruppbokning så hjälper vi er att hitta en lämplig resa.

      **[Skicka en förfrågan om gruppbokning till oss!](/sv/kundservice/TODO)**
    `}</Node>
  ),
  TOO_MANY_PASSENGERS_VOUCHER: (
    <Node
      uri="PassengerChooser/error/too-many-passengers-for-discount"
      num={Infinity}
    >
      Du kan max boka [num] passagerare med ditt valda värdebevis/pendlarkort.
    </Node>
  ),
  TOO_MANY_INFANTS: (
    <Node uri="PassengerChooser/error/too-many-infants">
      Du kan bara ta med ett spädbarn per övrig resenär eftersom de inte har en
      egen sittplats.
    </Node>
  ),
};
