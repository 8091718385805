import PropTypes from "prop-types";
import { useEffect, useState } from "react";

Countdown.propTypes = {
  to: PropTypes.instanceOf(Date).isRequired,
};

export default function Countdown({ to }) {
  const [now, setNow] = useState(Date.now());
  const then = to.getTime();
  const secondsLeft = Math.floor(Math.max(0, then - now) / 1000);
  const minutes = Math.floor(secondsLeft / 60);
  const seconds = secondsLeft - minutes * 60;

  useEffect(() => {
    setNow(Date.now()); // Otherwise the first tick seems to jump 2 seconds.
    const intervalId = setInterval(() => {
      setNow(Date.now());
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [then]);

  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
}
