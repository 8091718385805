import { addDays } from "date-fns";

import { range } from "#utils/";

import getWeekday from "./getWeekday";

// The default date in the calendar should be today (or the first available day
// of a voucher). But the weekday of that day might be disabled (by a voucher),
// so also try all the following weekdays. If everything fails, return
// `minDate`. It is not valid, but all code expects the default date to always
// exist. There's always supposed to be at least one valid day anyway.
export default function getDefaultDate(minDate, maxDate, weekdays, passedDate) {
  if (passedDate == null) {
    return undefined;
  }
  return (
    range(7, (n) => addDays(minDate, n)).find(
      (date) =>
        date >= minDate &&
        date <= maxDate &&
        weekdays.includes(getWeekday(date))
    ) || minDate
  );
}
