import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./Container.module.css";

export const MAX_WIDTH_REGULAR = 1136;

const Container = React.forwardRef(function Container(
  { maxWidth, fullHeight, className, children },
  ref
) {
  return (
    <div
      ref={ref}
      style={{ maxWidth }}
      className={classnames(
        styles.root,
        { [styles.fullHeight]: fullHeight },
        className
      )}
    >
      {children}
    </div>
  );
});

Container.propTypes = {
  maxWidth: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  children: PropTypes.node,
  fullHeight: PropTypes.bool,
  className: PropTypes.string,
};

Container.defaultProps = {
  maxWidth: MAX_WIDTH_REGULAR,
  className: "",
  children: null,
  fullHeight: false,
};

export default Container;
