import PropTypes from "prop-types";
import React from "react";

import styles from "./Burger.module.css";

Burger.propTypes = {
  cross: PropTypes.bool,
  barHeight: PropTypes.number,
  barGap: PropTypes.number,
  aspectRatio: PropTypes.number,
};

Burger.defaultProps = {
  cross: false,
  barHeight: 2, // px
  barGap: 4, // px
  aspectRatio: 10 / 7,
};

/**
 * A hamburger icon, that can animate to a cross.
 */
export default function Burger({ cross, barHeight, barGap, aspectRatio }) {
  const height = barHeight * 3 + barGap * 2;

  return (
    <span
      className={styles.root}
      style={{
        height,
        width: height * aspectRatio,
      }}
    >
      <span
        className={styles.bar}
        style={{
          height: barHeight,
          top: 0,
          transform: cross
            ? `translateY(${
                barHeight + barGap
              }px) rotate(45deg) scaleX(0.5) translateX(-50%)`
            : undefined,
        }}
      />
      <span
        className={styles.bar}
        style={{
          height: barHeight,
          top: barHeight + barGap,
          transform: cross ? `rotate(-45deg)` : undefined,
        }}
      />
      <span
        className={styles.bar}
        style={{
          height: barHeight,
          top: barHeight * 2 + barGap * 2,
          transform: cross
            ? `translateY(${-(
                barHeight + barGap
              )}px) rotate(45deg) scaleX(0.5)  translateX(50%)`
            : undefined,
        }}
      />
    </span>
  );
}
