import { Node } from "djedi-react";
import React from "react";

export const EXPIRED_MESSAGES = {
  404: (
    <Node uri="booking/session/expired/not-found">
      Vi kunde inte hitta din bokningssession.
    </Node>
  ),
  409: (
    <Node uri="booking/session/expired/conflict">
      Avgången har tyvärr hunnit bli fullbokad.
    </Node>
  ),
  410: (
    <Node uri="booking/session/expired/gone">
      Tiden för din bokningssession har tagit slut.
    </Node>
  ),
  412: (
    <Node uri="booking/session/expired/precondition-failed">
      Det blev tyvärr fel på din bokningssession.
    </Node>
  ),
  1404: (
    <Node uri="booking/session/expired/not-found-cookie">
      Vi kunde inte hitta din bokningssession (cookie).
    </Node>
  ),
  default: (
    <Node uri="booking/session/expired/default">
      Tiden för din bokningssession har tagit slut.
    </Node>
  ),
};

export const EXPIRED_BACK = (
  <Node uri="booking/session/expired-back">Börja om</Node>
);

export const EXPIRED_RESTART = (
  <Node uri="booking/session/expired-restart">Starta ny session</Node>
);
