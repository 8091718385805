import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./NavBar.module.css";

/**
 * The navbar used in the Drawer resembles the main nav too much to be separated,
 * Use this intermediary component to keep the styles in sync.
 */

const NavBar = ({ children, className, ...props }) => {
  return (
    <nav className={classnames(styles.root, className)} {...props}>
      <div className={styles.navChildren}>{children}</div>
    </nav>
  );
};

NavBar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

NavBar.defaultProps = {
  children: undefined,
  className: undefined,
};

export default NavBar;
