import { deleteNil, toISODateString } from "#utils/";
import { BookingClass, getKey } from "#utils/enums";

import { Steps } from "../consts";
import countsToInitialData from "./countsToInitialData";
import extrasToInitialData from "./extrasToInitialData";

function escapeTime(timeString) {
  return timeString.replace(/:/g, ".");
}

export default function makeQueryParamsBooking({
  from,
  to,
  counts = undefined,
  extras = undefined,
  showInbound,
  outbound,
  inbound,
  event,
  show,
  voucherCode,
  rebookJourneyId,
  upgradeJourneyId,
}) {
  const outboundTime =
    outbound.journey?.id == null
      ? undefined
      : escapeTime(outbound.journey.departure_at.time);
  const inboundTime =
    inbound.journey?.id == null
      ? undefined
      : escapeTime(inbound.journey.departure_at.time);

  const showKey = getKey(Steps, show).toLowerCase();

  return deleteNil({
    from: from.id,
    to: to.id,
    date: toISODateString(outbound.date),
    time: outboundTime,
    class:
      outbound.bookingClass != null
        ? getKey(BookingClass, outbound.bookingClass)
        : undefined,
    "date-back": showInbound ? toISODateString(inbound.date) : undefined,
    "time-back": inboundTime,
    "class-back":
      inboundTime != null && inbound.bookingClass != null
        ? getKey(BookingClass, inbound.bookingClass)
        : undefined,
    ...countsToInitialData(counts),
    ...extrasToInitialData(extras),
    show: showKey,
    event,
    "voucher-code": voucherCode,
    "rebook-journey-id": rebookJourneyId,
    "upgrade-journey-id": upgradeJourneyId,
  });
}
