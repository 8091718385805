import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./Select.module.css";

Select.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["regular", "rounded", "secondary", "grey", "red"]),
  // ...restProps
};

Select.defaultProps = {
  label: undefined,
  className: "",
  variant: "regular",
};

export default function Select({
  label,
  children,
  className,
  variant,
  ...restProps
}) {
  return (
    <>
      {label != null && <label className={styles.label}>{label}</label>}
      <select
        {...restProps}
        className={classnames(
          styles.root,
          { [styles.rounded]: variant === "rounded" },
          { [styles.grey]: variant === "grey" },
          { [styles.red]: variant === "red" },
          { [styles.secondary]: variant === "secondary" },
          className
        )}
      >
        {children}
      </select>
    </>
  );
}
