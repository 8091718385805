import { Node } from "djedi-react";
import React from "react";

export const INCLUDED_SEATS = (
  <Node uri="booking/session/seating/included-seats">
    Förvalda platser (ingår)
  </Node>
);

export const SEATMAP_OPEN = (
  <Node uri="booking/session/seating/display-seatmap">Byt platser</Node>
);

export const SEATMAP_CLOSE = (
  <Node uri="booking/session/seating/hide-seatmap">Dölj platskarta</Node>
);

export const BUS_SEATING_NOTICE = (
  <Node uri="booking/session/seating/bus-notice">
    Då denna sträcka trafikeras med buss går det inte att välja platser
  </Node>
);

export const SEATING_WARNING = (
  <Node uri="booking/session/seating/warning">
    Viktig information om dina platser
  </Node>
);
