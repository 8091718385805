/* global module */

const SWEDISH_LOCALE = "sv";
const ENGLISH_LOCALE = "en";
const LOCALES = [SWEDISH_LOCALE, ENGLISH_LOCALE];

const LANGUAGE_COOKIE = {
  name: "NEXT_LOCALE",
  expires: 90, // days
};

const LANGUAGE_COOKIE_ADMIN = {
  name: "language",
  expires: 90, // days
};

module.exports = {
  locales: LOCALES,
  defaultLocale: SWEDISH_LOCALE,
  LANGUAGE_COOKIE,
  LANGUAGE_COOKIE_ADMIN,
};
