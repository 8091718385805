import { useBooking } from "..";

const SCROLL_TIMEOUT = 400; // ms
const SCROLL_OFFSET = 15; // px

// When using the scrollToElement function the height of the DOM is usually
// changing while we are attempting to scroll. Therefore a poor mans solution
// to not end up somewhere crazy is a timeout.

export default function useScrollToElement() {
  const { layout } = useBooking();
  const { scrollSelector, navSelector } = layout;

  const scrollTo = (
    element,
    // When using the getSCrollOffset func there's no air
    // above the element we scorll to. Add another 15px to
    // make it look sweeter.
    extraOffset = SCROLL_OFFSET,
    timeout = SCROLL_TIMEOUT
  ) => {
    const offset =
      (document.querySelector(navSelector)?.clientHeight || 0) + extraOffset;

    setTimeout(() => {
      const elementOffset = element?.current?.offsetTop || element?.offsetTop;

      if (elementOffset == null) {
        return;
      }
      (document.querySelector(scrollSelector) || window).scrollTo({
        top: elementOffset - offset,
        left: 0,
        behavior: "smooth",
      });
    }, timeout);
  };

  return scrollTo;
}
