import classnames from "classnames";
import { md, Node } from "djedi-react";
import PropTypes from "prop-types";
import React from "react";

import Checkbox from "../Checkbox";
import Text from "../Text";
import styles from "./Terms.module.css";

export const ACCEPT_TERMS = {
  label: (
    <Node uri="AcceptTerms/session/label">
      Jag godkänner härmed Köp- och Resevillkoren och samtycker till behandling
      av personuppgifter enligt lämnad information.
    </Node>
  ),
  acceptRegistrationTerms: (
    <Node uri="AcceptTerms/register/label">
      Jag godkänner härmed villkoren och samtycker till behandling av
      personuppgifter enligt lämnad information.
    </Node>
  ),
  required: <Node uri="AcceptTerms/required">Du måste godkänna villkoren</Node>,
  text: (
    <Node uri="AcceptTerms/session/text.md">{md`
      [Köpvillkor,](/static/kopvillkor_mtrx_2018_05_04.pdf)
      [Resevillkor,](/static/allmanna_resevillkor_mtrx_2018_05_04.pdf)
      [Integritetspolicy,](/static/integritetspolicy_mtrx_2018_05_04.pdf)
      [Rättigheter när du åker tåg](/static/sammanfattning_eu_forordning_1371_2007.pdf)
    `}</Node>
  ),
  acceptRegistrationText: (
    <Node uri="AcceptTerms/register/text.md">{md`
      [Köpvillkor,](/static/kopvillkor_mtrx_2018_05_04.pdf)
      [Resevillkor,](/static/allmanna_resevillkor_mtrx_2018_05_04.pdf)
      [Integritetspolicy,](/static/integritetspolicy_mtrx_2018_05_04.pdf)
      [Rättigheter när du åker tåg](/static/sammanfattning_eu_forordning_1371_2007.pdf)
    `}</Node>
  ),
};

Terms.propTypes = {
  input: PropTypes.object.isRequired,
  error: PropTypes.bool,
  label: PropTypes.object,
  text: PropTypes.object,
};

Terms.defaultProps = {
  label: ACCEPT_TERMS.label,
  text: ACCEPT_TERMS.text,
  error: undefined,
};

export default function Terms({ error, input, label, text }) {
  return (
    <div
      className={classnames(styles.terms, {
        [styles.hasError]: error,
      })}
    >
      <label className={styles.termsLabel}>
        <Checkbox
          data-cy="acceptTerms"
          aria-invalid={error}
          className={styles.termsCheckbox}
          {...input}
        />
        <Text tag="span" color="primary" variant="paragraph14">
          {label || ACCEPT_TERMS.label}
        </Text>
      </label>
      <Text variant="paragraph14">{text || ACCEPT_TERMS.text}</Text>
      {error && (
        <div className={styles.termsError}>{ACCEPT_TERMS.required}</div>
      )}
    </div>
  );
}
