import { AddonsStatus } from "#utils/enums";

export default function countAddonsAndPrice(tickets = []) {
  return tickets.reduce((addons, ticket) => {
    if (ticket.addons.length === 0) {
      return addons;
    }

    for (const addon of ticket.addons) {
      const { type, status } = addon;

      // Filter out if the addon has been cancelled or is inactive
      if (status !== AddonsStatus.ACTIVE) {
        continue;
      }

      const count = (addons[type]?.count || 0) + 1;
      const prevPrice = addons[type]?.price || 0;
      addons[type] = {
        count,
        price: prevPrice + parseInt(addon.price.amount, 10),
      };
    }

    return addons;
  }, {});
}
