const PROPERTIES = [["length", 0]];

const METHODS = [
  ["key", null],
  ["getItem", null],
  ["setItem", undefined],
  ["removeItem", undefined],
  ["clear", undefined],
];

/**
 * `window.localStorage.setItem()` can throw `QuotaExceededError`s in Private
 * Browsing in Safari 10 and older. And simply accessing `window.localStorage`
 * can throw `SecurityError`s if the user has restricted settings. This wrapper
 * warns instead of throwing errors, in order to reduce the noise on Sentry and
 * to not crash things.
 */
class NonThrowingLocalStorage {
  removeItem(key) {
    console.warn(key);
    throw new Error("Method not implemented.");
  }
  constructor() {
    PROPERTIES.forEach(([name, defaultValue]) => {
      Object.defineProperty(this, name, {
        get: () => {
          try {
            return window.localStorage[name];
          } catch (error) {
            console.warn(`Access to localStorage.${name} failed`, {
              error,
              defaultValue,
            });
            return defaultValue;
          }
        },
      });
    });

    METHODS.forEach(([name, defaultValue]) => {
      this[name] = function (...args) {
        try {
          return window.localStorage[name](...args);
        } catch (error) {
          console.warn(`Call to localStorage.${name} failed`, {
            args,
            error,
            defaultValue,
          });
          return defaultValue;
        }
      };
    });
  }
}

const localStorage = new NonThrowingLocalStorage();

export default localStorage;

export function readFromLocalStorage(key) {
  const stored = localStorage.getItem(key);

  // Nothing has been stored yet.
  if (stored == null) {
    return null;
  }

  let parsed = undefined;
  try {
    parsed = JSON.parse(stored);
  } catch (error) {
    console.warn("readFromLocalStorage: Failed parse.", {
      localStorageKey: key,
      stored,
      error,
    });
    return null;
  }

  return parsed;
}

export function writeToLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}
