import { md, Node } from "djedi-react";
import React from "react";

export const OUTBOUND_HEADING = (
  <Node uri="booking/departures/outbound">Avresa</Node>
);
export const INBOUND_HEADING = (
  <Node uri="booking/departures/inbound">Returresa</Node>
);
export const STATION_TO = (
  <Node uri="booking/departures/station_to" station="FROM">{md`
[station] till
  `}</Node>
);
