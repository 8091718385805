import PropTypes from "prop-types";
import React from "react";

import { useBooking } from "../BookingContext";

const TRACKABLE_EVENTS = [
  "search.forward",
  "departures.journeys",
  "departures.select",
  "departures.confirm",
  "departures.forward",
  "passengers.forward",
  "seating.forward",
  "addons.forward",
  "payment.change",
  "payment.forward",
];

const TrackingContext = React.createContext();

export const useTracking = () => React.useContext(TrackingContext);

export const TrackingContextProvider = ({ children, tracking }) => {
  const ctx = useBooking();

  /**
   * Runs the provided tracking function with the booking context
   * as the first argument and any other data will be spread into
   * an array as the second argument.
   */
  const track = React.useCallback(
    (path, ...passedData) => {
      const passedTrackingFunc = tracking[path];

      if (!TRACKABLE_EVENTS.includes(path)) {
        console.warn(
          `<TrackingContext>: The event ${path} is not supported. Supported events are: ${TRACKABLE_EVENTS.join(
            ", "
          )}.`
        );
      }

      if (typeof passedTrackingFunc === "function") {
        passedTrackingFunc(ctx, passedData);
      }
    },
    [ctx, tracking]
  );

  return (
    <TrackingContext.Provider value={track}>
      {children}
    </TrackingContext.Provider>
  );
};

TrackingContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  tracking: PropTypes.object.isRequired,
};

export default TrackingContext;
