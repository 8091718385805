import classnames from "classnames";
import { ForceNodes, md, Node } from "djedi-react";
import { locales } from "i18n";
import { clamp } from "lodash";
import getConfig from "next/config";
import Link from "next/link";
import { withRouter } from "next/router";
import PropTypes from "prop-types";
import React from "react";
import { ROUTES } from "routes";

import AppContext from "#components/AppContext";
import ArrowLink from "#components/ArrowLink";
import Burger from "#components/Burger";
import Drawer from "#components/Drawer";
import Logout from "#components/Logout";
import NavBar from "#components/NavBar";
import WithWindowSize from "#components/WithWindowSize";
import MittVrLogo from "#icons/mitt-vr.svg";
import VrLogo from "#icons/vr.svg";
import { userPropTypes } from "#utils/proptypes";

import useEventListener from "../../hooks/useEventListener";
import useResizeObserver from "../../hooks/useResizeObserver";
import useScroll from "../../hooks/useScroll";
import cssGlobals from "../../main/css/globals";
import styles from "./Nav.module.css";
// import WithWindowSize from "../WithWindowSize";
import LinkItems from "./partials/LinkItems";
import UserButton from "./partials/UserButton";

const { publicRuntimeConfig } = getConfig();
const { HIDDEN_LANGUAGES } = publicRuntimeConfig;

const SHOWN_LANGUAGES = locales.filter(
  (language) => !HIDDEN_LANGUAGES.includes(language)
);

export const NAV_CHILDREN_ID = "navChildren";

const onOutsideClick = (callback) => (event) => {
  if (
    (event.target instanceof window.Node && event.target.nodeName === "A") ||
    event.target.nodeName === "BUTTON" ||
    event.target.closest("a") != null ||
    event.target.closest("button") != null
  ) {
    callback(false);
  }
};

export const NAV_ID = "navElement";

/*
  A NOTE ABOUT NAV LOGIC:

    On desktop:
      // - The menu is always fixed to the top of the screen.
      - The menu can be pushed down by messaging appearing above it.

    On phone:
      - The menu is hidden when the user scrolls past it. When scrolling upwards again, it appears from the top.

*/

const NAV_HEIGHT_IN_PX = parseInt(cssGlobals["g-navHeight"], 10);

function reducer(state, payload) {
  // clamp the value to the possible range
  return clamp(state + payload, -NAV_HEIGHT_IN_PX, 0);
}

const useNavState = ({ stickyAt = 0 }) => {
  const [offset, setOffset] = React.useReducer(reducer, 0);
  const scrollPos = useScroll();
  const lastScrollPos = React.useRef(0);
  // const hidden = scrollPos > NAV_HEIGHT_IN_PX;

  React.useEffect(() => {
    // When the element is sticky and set to `top=0`,
    // then scrollPos and stickyAt will be the same.
    if (scrollPos === stickyAt) {
      // find the relative change since last update.
      const change = lastScrollPos.current - scrollPos;
      setOffset(change);
      lastScrollPos.current = scrollPos;
    }
  }, [scrollPos, stickyAt]);

  return offset;
};

const ScrollAwayNav = (props) => {
  const { scrollBehaviour, ...otherProps } = props;
  const [setObserver, entries] = useResizeObserver();
  const navOffset = useNavState({ stickyAt: entries?.target?.offsetTop || 0 });

  return (
    <>
      <div
        ref={setObserver}
        className={classnames(
          styles.navRoot,
          scrollBehaviour ? styles.scrollAway : styles.desktopNav
        )}
        {...otherProps}
        style={{
          transform: scrollBehaviour
            ? `translateY(${navOffset}px)`
            : "translateY(0px)",
        }}
      />
    </>
  );
};

ScrollAwayNav.propTypes = {
  scrollBehaviour: PropTypes.bool.isRequired,
};

function Nav({ children, ...props }) {
  const { appMode } = React.useContext(AppContext);
  const content = (
    <>
      <NavBar className={styles.nav}>
        <NavContent {...props} />
      </NavBar>
      <div className={styles.navChildren} id={NAV_CHILDREN_ID}>
        {children}
      </div>
    </>
  );

  return (
    <WithWindowSize>
      {({ xsDown }) => (
        <ScrollAwayNav scrollBehaviour={xsDown} id={NAV_ID}>
          {!appMode && content}
        </ScrollAwayNav>
      )}
    </WithWindowSize>
  );
}

Nav.propTypes = {
  children: PropTypes.node,
};

Nav.defaultProps = {
  children: null,
};

const VR_FOR_BUSINESS = (
  <Node uri="nav/links/account/vr-for-business">Mitt VR för företag</Node>
);

const LOGIN = <Node uri="nav/links/account/login">Logga in</Node>;

const MY_VR = <Node uri="nav/links/account/my-membership">Mitt VR</Node>;

const LoggedInLinks = ({ user }) => {
  if (!user) {
    return null;
  }

  return (
    <div className={styles.clubDetails}>
      <div className={styles.logoutWrapper}>
        <Logout>
          <Node uri="nav/links/logout">Logga ut</Node>
        </Logout>
      </div>
    </div>
  );
};

LoggedInLinks.propTypes = { user: userPropTypes.isRequired };

const NavContent = withRouter(({ hero, router }) => {
  const [menuOpen, setmenuOpen] = React.useState(false);
  const { user, setLanguageCookie } = React.useContext(AppContext);

  const languageSwitcher = SHOWN_LANGUAGES.filter(
    (language2) => language2 !== router.locale
  ).map((language2, index) => {
    const link = (
      <a
        onClick={() => {
          setLanguageCookie(language2);

          if (typeof window?.fcWidget !== "undefined") {
            window.fcWidget.user.setLocale(language2);
          }
        }}
      >
        {language2.toUpperCase()}
      </a>
    );

    /* If Freshchat is enabled, use plain links that cause
  full page reloads. That’s the easiest way to refresh the
  Freshchat widget with the new language. */

    return (
      <Link
        key={index}
        href={{
          pathname: router.pathname,
          query: router.query,
        }}
        locale={language2}
      >
        {link}
      </Link>
    );
  });

  useEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      setmenuOpen(false);
    }
  });

  const closeHamburgerMenuIfClickLink = onOutsideClick(setmenuOpen);

  return React.useMemo(
    () => (
      <>
        <button
          type="button"
          aria-label="Open menu"
          onClick={() => setmenuOpen(true)}
        >
          <Burger cross={menuOpen} />
        </button>

        <Drawer open={menuOpen} onRequestClose={setmenuOpen}>
          <div
            className={classnames(styles.navInner)}
            onClick={menuOpen ? closeHamburgerMenuIfClickLink : undefined}
          >
            <div className={styles.topWrapper}>
              <ul>
                <LinkItems
                  className={styles.linkItem}
                  links={
                    <Node uri="nav/links/upper">{md`
                      | You can add navigation links here. One per line.
                      | Format: LINK TEXT | URL
                      | Lines starting with a pipe (|) are ignored.
                      | Remember to update Nav/Hamburger-breakpoint as well.

                      Sök resa | /sv/boka-resa
                      Min resa | /sv/min-resa
                      Kundservice | /sv/kundservice
                      Företag | /sv/foretag
                      Pendlarkort | /sv/pendlarkort
                      Res smart med MTRX | /sv/smart
                    `}</Node>
                  }
                />
              </ul>
            </div>

            <div className={styles.bottomWrapper}>
              <MittVrLogo className={styles.myVrLogo} />
              {user ? (
                <div className={styles.clubxLinks}>
                  <Link passHref href={ROUTES.account.pathname}>
                    <ArrowLink color="secondary" size="lg">
                      {MY_VR}
                    </ArrowLink>
                  </Link>
                  {user.corporate_memberships.length > 0 && (
                    <Link passHref href={ROUTES.corporates.pathname}>
                      <ArrowLink color="secondary" size="lg">
                        {VR_FOR_BUSINESS}
                      </ArrowLink>
                    </Link>
                  )}
                  <LoggedInLinks user={user} />
                  <div className={styles.hamburgerLanguageSwitch}>
                    {languageSwitcher}
                  </div>
                </div>
              ) : (
                <div className={styles.myMtrxLinks}>
                  <Link href={ROUTES.login.pathname} passHref>
                    <ArrowLink color="secondary" size="lg">
                      {LOGIN}
                    </ArrowLink>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </Drawer>
        <Link href={ROUTES.home.pathname}>
          <a className={styles.logoWrapper} alt="VR" aria-label="VR">
            <VrLogo className={styles.logo} />
          </a>
        </Link>
        <div className={styles.rightNav}>
          <div className={styles.languageSwitch}>{languageSwitcher}</div>
          <UserButton user={user} />
        </div>
        {hero != null && <div className={styles.hero}>{hero}</div>}
        <ForceNodes>
          {VR_FOR_BUSINESS}
          {LOGIN}
          {MY_VR}
        </ForceNodes>
      </>
    ),
    [closeHamburgerMenuIfClickLink, hero, languageSwitcher, menuOpen, user]
  );
});

NavContent.propTypespropTypes = {
  fixed: PropTypes.bool,
  hero: PropTypes.node,
  router: PropTypes.object.isRequired,
};

NavContent.propTypesdefaultProps = {
  fixed: true,
  hero: null,
};

export default Nav;
