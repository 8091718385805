import { Node } from "djedi-react";
import React from "react";

import { SwishState } from "#containers/Booking/consts";

export const STATES = {
  [SwishState.INITIATED]: (
    <Node uri="booking/session/payment/swish/instructions">
      Öppna Swish och betala.
    </Node>
  ),
  [SwishState.PENDING]: (
    <Node uri="booking/session/payment/swish/waiting">
      Väntar på svar ifrån Swish. Stäng inte fönstret.
    </Node>
  ),
  [SwishState.FINISHED]: (
    <Node uri="booking/session/payment/swish/succeeded">
      Betalningen är färdig. Du kommer nu att omdirigeras till kvittosidan...
    </Node>
  ),
};

export const BACK = (
  <Node uri="booking/session/payment/swish/back">Back to payment</Node>
);

export const ABORT = (
  <Node uri="booking/session/payment/swish/abort">Avbryt</Node>
);
