import classnames from "classnames";
import { Node } from "djedi-react";
import PropTypes from "prop-types";
import React from "react";

import EyeIcon from "#icons/eye.svg";
import EyeClosedIcon from "#icons/eye-closed.svg";

import styles from "./PasswordInput.module.css";

PasswordInput.propTypes = {
  className: PropTypes.string,
  // ...restProps
};

PasswordInput.defaultProps = {
  className: "",
};

export default function PasswordInput({ className, ...restProps }) {
  const [type, setType] = React.useState("password");

  return (
    <span className={styles.root}>
      <input
        {...restProps}
        type={type}
        className={classnames(styles.input, className)}
      />
      {type === "password" ? (
        <Node
          uri="PasswordInput/show"
          edit={false}
          render={(state) => (
            <button
              type="button"
              title={state.type === "success" ? state.content : undefined}
              className={styles.button}
              onClick={() => {
                setType("text");
              }}
            >
              <EyeIcon className={styles.icon} />
            </button>
          )}
        >
          Visa
        </Node>
      ) : (
        <Node
          uri="PasswordInput/hide"
          edit={false}
          render={(state) => (
            <button
              type="button"
              title={state.type === "success" ? state.content : undefined}
              className={styles.button}
              onClick={() => {
                setType("password");
              }}
            >
              <EyeClosedIcon className={styles.icon} />
            </button>
          )}
        >
          Dölj
        </Node>
      )}
    </span>
  );
}
