import { md, Node } from "djedi-react";
import React from "react";

export const HEADING = (
  <Node uri="booking/session/heading/details">Ange dina bokningsuppgifter</Node>
);

export const HEADING_READONLY = (
  <Node uri="booking/session/heading/details-readonly">
    Dina bokningsuppgifter
  </Node>
);

export const HEADING_GROUP_BOOKING = (
  <Node uri="booking/session/heading/group-booking">Gruppbokning</Node>
);

export const TERMS_OPTIN = {
  label: (
    <Node uri="booking/session/terms/label/optin">
      Jag vill få nyhetsbrev och erbjudanden från MTRX.
    </Node>
  ),
  text: (
    <Node uri="booking/session/terms/text/optin.md">{md`
      [Integritetspolicy](/static/integritetspolicy_mtrx_2018_05_04.pdf)
    `}</Node>
  ),
};
