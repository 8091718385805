import React from "react";
import { Field } from "react-final-form";
// This file contains validation and normalization functions to be used with
// react-final-form.

// `<Field {...trimOnBlur}>`
export const trimOnBlur = {
  format: (value = "") => (value != null ? value.trim() : ""),
  formatOnBlur: true,
};

// `<Field {...trimOnBlur}>`
export const normalizePhoneOnBlur = {
  format: normalizePhone,
  formatOnBlur: true,
};

export function maxLength(message, length = Infinity) {
  return (value = "") => (value.length <= length ? undefined : message);
}

function normalizePhone(value = "") {
  return value?.trim().replace(/[\s-]/g, "");
}

// Non-empty, and not just whitespace.
export function required(message) {
  return (value) =>
    value != null && value.trim() !== "" ? undefined : message;
}

// Must be truthy.
export function requiredBoolean(message) {
  return (value) => (value ? undefined : message);
}

// At least 10 digits. Can start with a plus. Allow spaces and dashes.
export function phoneIsh(message) {
  return (value) =>
    value != null && /^\+?\d{10,}$/.test(normalizePhone(value))
      ? undefined
      : message;
}

// whatever@whatever.whatever
// “whatever” after the “@” must not contain “@” or whitespace.
// https://en.wikipedia.org/wiki/Email_address#Examples
export function emailIsh(message) {
  return (value) =>
    value != null && /^.*[^@\s]@[^@\s]+\.[^@\s.]+$/.test(value.trim())
      ? undefined
      : message;
}

export function unique(message, user, favorites, initialValues = null) {
  const { email } = user;

  return (value) => {
    if (initialValues !== null) {
      const newArr = favorites.filter((x) => x.id !== initialValues.id);
      return value !== null &&
        value !== email &&
        !newArr.some((x) => x.email === value)
        ? undefined
        : message;
    }

    return value !== null &&
      value !== email &&
      !favorites.some((x) => x.email === value)
      ? undefined
      : message;
  };
}

// `combineValidators(required("Required"), emailIsh("Enter a valid email"))`
export function combineValidators(...validators) {
  return (value) => {
    /* eslint-disable-next-line */
    for (const validator of validators) {
      const result = validator(value);
      if (result != null) {
        return result;
      }
    }
    return undefined;
  };
}

/* eslint-disable react/prop-types */
export function Condition({ when, is, children }) {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  );
}
