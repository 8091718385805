import { md, Node } from "djedi-react";
import PropTypes from "prop-types";
import React from "react";

import { BookingClass, PassengerType } from "#utils/enums";
import { HOUR, MINUTE } from "#utils/index";

export const BOOKING_CONTAINER_ID = "bookingContainer";
export const MESSAGE_BANNER_ID = "messageBanner";

export const Steps = {
  SEARCH: 1,
  DEPARTURES: 2,
  PASSENGERS: 3,
  UPGRADE: 4,
  SEATING: 5,
  ADDONS: 6,
  PAYMENT: 7,
};

export const BOOKING_STEPS = [Steps.SEARCH, Steps.DEPARTURES];
export const SESSION_STEPS = [
  Steps.PASSENGERS,
  Steps.UPGRADE,
  Steps.SEATING,
  Steps.ADDONS,
  Steps.PAYMENT,
];

export const ABSOLUTE_MAX_PASSENGERS = 20;
export const STAFF_MAX_PASSENGERS = Infinity;
export const STAFF_MAX_COUNTS = {
  1: Infinity,
  2: Infinity,
  3: Infinity,
  4: Infinity,
  5: Infinity,
  6: Infinity,
  7: Infinity,
};
export const STAFF_MAX_PER_TYPE = Infinity;
export const MAX_PER_TYPE = 99;
export const SESSION_EXPIRED_STATUS = 410;
export const SEATS_TAKEN_STATUS = 409;

export const HANDLED_ERROR_STATUSES = [404, 409, SESSION_EXPIRED_STATUS, 412];
export const TIME_TO_WARNING = 5 * MINUTE; // ms

export const STATIONS = {
  Solna: "b9501164-fbb4-454a-8918-38a042780789",
  "Stockholm C": "b9501164-fbb4-454a-8918-38a042780790",
  "Stockholm Södra": "32102a6e-4763-49ac-9fd0-2fcdbe587a1a",
  Flemingsberg: "695b7726-3e8c-4e70-a727-e7ae1c1fec49",
  "Södertälje Syd": "b9501164-fbb4-454a-8918-38a042780791",
  "Södertälje Syd nedre": "8822bb83-c34e-484b-9f10-e1c9bff67fcc",
  Katrineholm: "8c0102b7-ca85-43ab-b813-b009c26ea3d2",
  Skövde: "b9501164-fbb4-454a-8918-38a042780792",
  Falköping: "83517751-7803-4cc8-9974-ef7f23f1eaca",
  Herrljunga: "b9501164-fbb4-454a-8918-38a042780793",
  Vårgårda: "ecf3b57f-b568-4739-959a-4f15cceba331",
  Alingsås: "b9501164-fbb4-454a-8918-38a042780794",
  "Borås C": "b9501164-fbb4-454a-8918-38a042780801",
  Partille: "b9501164-fbb4-454a-8918-38a042780750",
  Trollhättan: "b9501164-fbb4-454a-8918-38a042780802",
  "Göteborg C": "b9501164-fbb4-454a-8918-38a042780795",
  Varberg: "b9501164-fbb4-454a-8918-38a042780796",
  Falkenberg: "b9501164-fbb4-454a-8918-38a042780797",
  Halmstad: "b9501164-fbb4-454a-8918-38a042780798",
  Laholm: "b9501164-fbb4-454a-8918-38a042780799",
  Båstad: "b9501164-fbb4-454a-8918-38a042780800",
  Ängelholm: "af79abf5-aa54-4d1d-a08b-7d38603c8bf3",
};

export const GROUP_BOOKING_DISCOUNT_NAME = "GRUPPNYA";
export const EVENT_BOOKING_DISCOUNT_NAME = "EVENTACAU";
export const STAFF_BOOKING_DISCOUNT_NAMES = [
  GROUP_BOOKING_DISCOUNT_NAME,
  EVENT_BOOKING_DISCOUNT_NAME,
];

export const FROM_TO = {
  from: STATIONS["Stockholm C"],
  to: STATIONS["Göteborg C"],
};

export const PASSENGER_TYPES = [
  {
    id: PassengerType.ADULT,
    param: "adults",
    nameSingular: (
      <Node uri="PassengerChooser/type/adult/name-singular">Vuxen</Node>
    ),
    namePlural: (
      <Node uri="PassengerChooser/type/adult/name-plural">Vuxna</Node>
    ),
    age: <Node uri="PassengerChooser/type/adult/age" />,
    shortNote: <Node uri="PassengerChooser/type/adult/short-note2" />,
    longNote: <Node uri="PassengerChooser/type/adult/long-note" />,
  },
  {
    id: PassengerType.CHILD6,
    param: "small-children",
    nameSingular: (
      <Node uri="PassengerChooser/type/small-child/name-singular">Barn</Node>
    ),
    namePlural: (
      <Node uri="PassengerChooser/type/small-child/name-plural">Barn</Node>
    ),
    age: <Node uri="PassengerChooser/type/small-child/age">0–6 år</Node>,
    shortNote: (
      <Node uri="PassengerChooser/type/small-child/short-note2">
        Max 20% rabatt
      </Node>
    ),
    longNote: <Node uri="PassengerChooser/type/small-child/long-note" />,
  },
  {
    id: PassengerType.CHILD15,
    param: "children",
    nameSingular: (
      <Node uri="PassengerChooser/type/child/name-singular">Barn</Node>
    ),
    namePlural: <Node uri="PassengerChooser/type/child/name-plural">Barn</Node>,
    age: <Node uri="PassengerChooser/type/child/age">7–15 år</Node>,
    shortNote: (
      <Node uri="PassengerChooser/type/child/short-note2">Max 20% rabatt</Node>
    ),
    longNote: <Node uri="PassengerChooser/type/child/long-note" />,
  },
  {
    id: PassengerType.YOUTH,
    param: "youths",
    nameSingular: (
      <Node uri="PassengerChooser/type/youth/name-singular">Ungdom</Node>
    ),
    namePlural: (
      <Node uri="PassengerChooser/type/youth/name-plural">Ungdomar</Node>
    ),
    age: <Node uri="PassengerChooser/type/youth/age">16–25 år</Node>,
    shortNote: (
      <Node uri="PassengerChooser/type/youth/short-note2">Max 20% rabatt</Node>
    ),
    longNote: <Node uri="PassengerChooser/type/youth/long-note" />,
  },
  {
    id: PassengerType.INFANT,
    param: "infants",
    nameSingular: (
      <Node uri="PassengerChooser/type/infant/name-singular">Spädbarn</Node>
    ),
    namePlural: (
      <Node uri="PassengerChooser/type/infant/name-plural">Spädbarn</Node>
    ),
    age: <Node uri="PassengerChooser/type/infant/age">0–23 månader</Node>,
    shortNote: (
      <Node uri="PassengerChooser/type/infant/short-note2">Gratis</Node>
    ),
    longNote: (
      <Node uri="PassengerChooser/type/infant/long-note">
        Spädbarn reser gratis, men har ingen egen plats. Önskar ni en extra
        sittplats så får ni istället boka en barnbiljett.
      </Node>
    ),
  },
  {
    id: PassengerType.STUDENT,
    param: "students",
    nameSingular: (
      <Node uri="PassengerChooser/type/student/name-singular">Student</Node>
    ),
    namePlural: (
      <Node uri="PassengerChooser/type/student/name-plural">Studenter</Node>
    ),
    age: <Node uri="PassengerChooser/type/student/age" />,
    shortNote: (
      <Node uri="PassengerChooser/type/student/short-note2">
        Max 20% rabatt
      </Node>
    ),
    longNote: <Node uri="PassengerChooser/type/student/long-note" />,
  },
  {
    id: PassengerType.SENIOR,
    param: "seniors",
    nameSingular: (
      <Node uri="PassengerChooser/type/senior/name-singular">Pensionär</Node>
    ),
    namePlural: (
      <Node uri="PassengerChooser/type/senior/name-plural">Pensionärer</Node>
    ),
    age: <Node uri="PassengerChooser/type/senior/age" />,
    shortNote: (
      <Node uri="PassengerChooser/type/senior/short-note2">Max 20% rabatt</Node>
    ),
    longNote: <Node uri="PassengerChooser/type/senior/long-note" />,
  },
];

export const EXTRAS = {
  strollerLabel: (
    <Node uri="PassengerChooser/extras/stroller/label">Barnvagn</Node>
  ),
  strollerTitle: (
    <Node uri="PassengerChooser/extras/stroller/title">Barnvagn</Node>
  ),
  strollerHelp: (
    <Node uri="PassengerChooser/extras/stroller/help.md">{md`
      Barnvagn får tas med ombord på tåget, en barnvagn per bokning. Denna ska fällas ihop och placeras i särskilda bagagehyllor eller på av servicepersonalen anvisad plats. Av säkerhetsskäl får barnvagnen inte placeras i tågets gångar. Vi kan inte garantera att din barnvagn kan placeras i samma vagn som du sitter i och barnvagnar placeras endast i vagnarna C, D och E. Om du är osäker på vad som gäller när du är ombord så kan du alltid kontakta servicepersonalen som gärna hjälper dig.
    `}</Node>
  ),
  petLabel: <Node uri="PassengerChooser/extras/pet/label">Sällskapsdjur</Node>,
  petTitle: <Node uri="PassengerChooser/extras/pet/title">Sällskapsdjur</Node>,
  petHelp: (
    <Node uri="PassengerChooser/extras/pet/help.md">{md`
      Max två djur per person får medtagas. En större hund eller ett par mindre hundar eller andra sällskapsdjur tas med och placeras i Vagn D som är anpassad för sällskapsdjur. I det anvisade utrymmet får mindre sällskapsdjur i bur eller väska placeras på skyddande underlag på sittplats som, om möjligt, reserverats. Övriga sällskapsdjur ska placeras på golvet.

      <a href="/static/allmanna_resevillkor_mtrx_2018_05_04.pdf" target="_blank">Läs mer i MTRX allmänna villkor (pdf)</a>
    `}</Node>
  ),
  strollerDescription: (
    <Node uri="PassengerChooser/extras/stroller/description">...</Node>
  ),
  petDescription: (
    <Node uri="PassengerChooser/extras/pet/description">...</Node>
  ),
  wheelchairDescription: (
    <Node uri="PassengerChooser/extras/wheelchair/description">...</Node>
  ),
  wheelchairsLabel: (
    <Node uri="PassengerChooser/extras/wheelchairs/label">Rullstol</Node>
  ),
  wheelchairsTitle: (
    <Node uri="PassengerChooser/extras/wheelchairs/title">Rullstol</Node>
  ),
  wheelchairsHelp: (
    <Node uri="PassengerChooser/extras/wheelchairs/help.md">{md`
      Rullstol, rullator, permobil och liknande hjälpmedel får medtas i mån av plats. Det finns särskilda platser på tåget där man kan sitta i sin rullstol. Bokning av plats ska ske före resan i samband med bokning av biljetten.

      På grund av begränsningar i tågets utrymme, rullstolsliftens kapacitet och av säkerhetsskäl får hjälpmedlet inte överstiga följande mått;

      Bredd: 80 cm. Längd: 120 cm. Vikt: 350 kg. Markfrigång måste vara minst 5 cm.
    `}</Node>
  ),
  wheelchairsOne: (
    <Node uri="PassengerChooser/extras/wheelchairs/one">En rullstolsplats</Node>
  ),
  wheelchairsTwo: (
    <Node uri="PassengerChooser/extras/wheelchairs/two">
      Två rullstolsplatser
    </Node>
  ),
  walkerLabel: <Node uri="PassengerChooser/extras/walker/label">Rullator</Node>,
  walkersLabel: (
    <Node uri="PassengerChooser/extras/walkers/label">Rullator</Node>
  ),
  walkerDescription: (
    <Node uri="PassengerChooser/extras/walker/description">...</Node>
  ),
  walkersTitle: (
    <Node uri="PassengerChooser/extras/walkers/title">Rullator</Node>
  ),
  walkersHelp: (
    <Node uri="PassengerChooser/extras/walkers/help.md">{md`
      Rullator får medtas i mån av plats. Bokning av plats ska ske före resan i samband med bokning av biljetten.
    `}</Node>
  ),
  walkersOne: (
    <Node uri="PassengerChooser/extras/walkers/one">En rullator</Node>
  ),
  walkersTwo: (
    <Node uri="PassengerChooser/extras/walkers/two">Två rullator</Node>
  ),
  show: (
    <Node uri="PassengerChooser/extras/show">
      Jag reser med barnvagn, rullstol eller sällskapsdjur
    </Node>
  ),
  understood: <Node uri="PassengerChooser/understood">OK, jag förstår!</Node>,
  unavailable: (
    <Node uri="PassengerChooser/unavailable">
      Inte tillgängligt med ditt valda värdebevis/pendlarkort.
    </Node>
  ),
};

export const DEFAULT_COUNTS = PASSENGER_TYPES.reduce((result, type) => {
  result[type.id] = 0;
  return result;
}, {});

export const EMPTY_JOURNEY = {
  journey: { id: undefined },
  bookingClass: undefined,
};

export const LOCAL_STORAGE_MAX_AGE = HOUR;
export const INFINITE_VOUCHER = 10;
export const WEEKDAYS = [0, 1, 2, 3, 4, 5, 6];
export const VOUCHER_COOKIE_NAME = "voucher";
export const VOUCHER_UPGRADE_COOKIE_NAME = "voucher-upgrade";
export const SESSION_COOKIE_EXTRA_LIFETIME = 4; // hours

export const SwishState = {
  NONE: 0,
  INITIATED: 1,
  PENDING: 2,
  FINISHED: 3,
  FAILED: 4,
};

export const WarningStatus = {
  NOT_ALLOWED: 1,
  WARNING: 2,
  ALLOWED: 3,
};

export const PASSENGER_PRICES_MAP = {
  [PassengerType.ADULT]: 1,
  [PassengerType.CHILD6]: 0.8,
  [PassengerType.INFANT]: 0,
  [PassengerType.SENIOR]: 0.8,
  [PassengerType.STUDENT]: 0.8,
  [PassengerType.YOUTH]: 0.8,
  [PassengerType.CHILD15]: 0.8,
};

export const PassengerPropType = PropTypes.shape({
  type: PropTypes.oneOf(Object.values(PassengerType)).isRequired,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

// To come from backend at some point. Remove then.
export const CHANGE_FEE_PRICE = 100;
export const BOOKING_CLASSES_WITH_CHANGE_FEE = [BookingClass.FIX];
export const PASSENGER_TYPES_WITH_CHANGE_FEE = [
  PassengerType.ADULT,
  PassengerType.CHILD6,
  PassengerType.SENIOR,
  PassengerType.STUDENT,
  PassengerType.YOUTH,
  PassengerType.CHILD15,
];
