import PropTypes from "prop-types";
import React from "react";

import CloseIcon from "#icons/cross.svg";
import MittVrIcon from "#icons/mitt-vr.svg";
import { noop } from "#utils";

import Modal from "./base";
import styles from "./clubx.module.css";

WhiteModal.propTypes = {
  children: PropTypes.node.isRequired,
  onRequestClose: PropTypes.func,
};

WhiteModal.defaultProps = {
  onRequestClose: noop,
};

export default function WhiteModal({ children, onRequestClose, ...restProps }) {
  return (
    <Modal onRequestClose={onRequestClose} {...restProps}>
      <div className={styles.root} data-cy="modal">
        <div>
          <div className={styles.logoWrapper}>
            <MittVrIcon className={styles.logo} />

            <button
              onClick={onRequestClose}
              type="button"
              className={styles.close}
            >
              <CloseIcon className={styles.closeIcon} />
            </button>
          </div>
        </div>

        <div className={styles.inner}>{children}</div>
      </div>
    </Modal>
  );
}
