export default function extrasFromPassengers(passengers) {
  return {
    stroller: passengers.some((passenger) => passenger.has_stroller),
    pet: passengers.some((passenger) => passenger.has_pet),
    wheelchairs: Math.min(
      2,
      passengers.filter((passenger) => passenger.has_wheelchair).length
    ),
    walkers: Math.min(
      2,
      passengers.filter((passenger) => passenger.has_walker).length
    ),
  };
}
