import React from "react";
import { Sentry } from "sentry";

import { useAppContext } from "#components/AppContext";
import topNotification from "#components/topNotification";
import { useBooking } from "#containers/Booking/BookingContext";
import useSessionFetchers from "#containers/Booking/BookingContext/helpers/useSessionFetchers";
import {
  CHOSEN_SEATS_ALREADY_TAKEN,
  UPDATE_ERROR,
  UPDATE_ERROR_NETWORK,
} from "#containers/Booking/nodes";

import { SEATS_TAKEN_STATUS, SESSION_EXPIRED_STATUS } from "../../consts";

export default function useSeatingHandlers() {
  const { api } = useAppContext();
  const {
    session,
    setLoading,
    setPsp,
    setSeating,
    setSession,
    setSessionError,
  } = useBooking();
  const { getSeating, getPsp } = useSessionFetchers();

  const sessionId = session.id;

  const onSeatingChange = React.useCallback(
    async ({ changedTickets, remove = false }) => {
      try {
        setLoading(true);
        let seatingResponse = undefined;

        if (remove) {
          seatingResponse = await api.removeAddons({
            sessionId,
            addons: changedTickets
              .map(({ addons: { seating = [] } }) =>
                seating.map(({ id }) => id)
              )
              .flat(Infinity),
          });
        } else {
          seatingResponse = await api.updateAllocatedSeating({
            sessionId,
            tickets: changedTickets.map(({ seats, id }) => {
              return {
                ticket_id: id,
                seats: seats.map(({ number }) => number),
              };
            }),
          });
        }

        const { data: newSession } = seatingResponse;

        const [pspData, seatingData] = await Promise.all([
          getPsp(newSession.id),
          getSeating(newSession),
        ]);

        setPsp(pspData);
        setSession(newSession);
        setSeating(seatingData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(
          "Booking: Failed to update seating",
          error,
          error.response
        );

        const status = error?.response?.status;

        if (status === SESSION_EXPIRED_STATUS) {
          setSessionError({ status });
        } else if (status === SEATS_TAKEN_STATUS) {
          topNotification(CHOSEN_SEATS_ALREADY_TAKEN);
        } else if (status) {
          topNotification(React.cloneElement(UPDATE_ERROR, { status }));
        } else if (error.noResponse) {
          error.message = `Network error: ${error.message}`;
          topNotification(UPDATE_ERROR_NETWORK);
        } else {
          topNotification(React.cloneElement(UPDATE_ERROR, { status: 1500 }));
        }

        Sentry.captureException(error);
      }
    },
    [
      api,
      getPsp,
      getSeating,
      sessionId,
      setLoading,
      setPsp,
      setSeating,
      setSession,
      setSessionError,
    ]
  );

  return {
    onSeatingChange,
  };
}
