import { endOfMonth, setHours } from "date-fns";
import React from "react";

import { useAppContext } from "#components/AppContext";
import { makeCalendarKey } from "#containers/Booking/helpers";
import { toISODateString } from "#utils/";

import { useBooking } from "..";

export default function useGetDays() {
  const { api } = useAppContext();
  const { booking, daysInfo, setDaysInfo, isRebook } = useBooking();

  const { outbound, inbound, from, to, showInbound } = booking || {};

  const lastOutboundKey = React.useRef();
  const lastInboundKey = React.useRef();

  const outboundMonth = outbound?.month;
  const inboundMonth = inbound?.month;
  const fromId = from?.id;
  const toId = to?.id;

  React.useEffect(() => {
    (async () => {
      if (booking && !isRebook) {
        const outboundKey = makeCalendarKey(from, to, outboundMonth);
        const inboundKey = showInbound
          ? makeCalendarKey(to, from, inboundMonth)
          : "–";

        const outboundChanged = lastOutboundKey.current !== outboundKey;
        const inboundChanged = lastInboundKey.current !== inboundKey;

        if (outboundChanged || inboundChanged) {
          const [outboundCalendar, inboundCalendar] = await Promise.all([
            !daysInfo[outboundKey]
              ? api.getCalendar({
                  date_from: toISODateString(outboundMonth),
                  // Small hack to correct the +2 hours being added in endOfMonth
                  date_to: toISODateString(
                    setHours(endOfMonth(outboundMonth), 0)
                  ),
                  origin_id: fromId,
                  destination_id: toId,
                })
              : undefined,
            showInbound && !daysInfo[inboundKey]
              ? api.getCalendar({
                  date_from: toISODateString(inboundMonth),
                  // Small hack to correct the +2 hours being added in endOfMonth
                  date_to: toISODateString(
                    setHours(endOfMonth(inboundMonth), 0)
                  ),
                  origin_id: toId,
                  destination_id: fromId,
                })
              : undefined,
          ]);

          const newState = { ...daysInfo };

          if (outboundCalendar) {
            newState[outboundKey] = outboundCalendar.data;
          }
          if (inboundCalendar) {
            newState[inboundKey] = inboundCalendar.data;
          }

          setDaysInfo(newState);
        }

        lastOutboundKey.current = outboundKey;
        lastInboundKey.current = inboundKey;
      }
    })();
  }, [
    api,
    fromId,
    toId,
    outboundMonth,
    inboundMonth,
    showInbound,
    from,
    to,
    daysInfo,
    setDaysInfo,
    booking,
    isRebook,
  ]);
}
