import NProgress from "nprogress";
import React from "react";

// Hook interface for the progress bar.
const useNProgress = (loading) => {
  React.useEffect(() => {
    if (loading) {
      NProgress.start();
    } else {
      NProgress.done();
    }
    return () => NProgress.done();
  }, [loading]);
};

export default useNProgress;
