import { md, Node } from "djedi-react";
import React from "react";

// All nodes from other partials. Used to enforce them to be rendered for easier editing.
export * as SESSION_ERROR_NODES from "./partials/SessionError/nodes";
export * as ADDONS_NODES from "./steps/Addons/nodes";
export * as ADDONS_MEALS_NODES from "./steps/Addons/nodes";
export * as DEPARTURES_NODES from "./steps/Departures/nodes";
export * as DEPARTURES_DIRECTION_NODES from "./steps/Departures/partials/Direction/nodes";
export * as PASSENGERS_NODES from "./steps/Passengers/nodes";
export * as PAYMENT_NODES from "./steps/Payment/nodes";
export * as PAYMENT_SWISH_ONGOING_NODES from "./steps/Payment/partials/SwishOngoing/nodes";
export * as SEARCH_NODES from "./steps/Search/nodes";
export * as SEARCH_DEFINE_TRIP_NODES from "./steps/Search/partials/DefineTrip/nodes";
export * as SEARCH_PASSENGER_CHOOSER_NODES from "./steps/Search/partials/PassengerChooser/nodes";
export * as SEATING_NODES from "./steps/Seating/nodes";
export * as SEATING_CHANGE_SEATING_NODES from "./steps/Seating/partials/ChangeSeating/nodes";

// Contains more generic nodes that could potentially be used on many places.
export const CHOSEN_SEATS_ALREADY_TAKEN = (
  <Node uri="booking/session/already-taken-error">
    Något säte du har försökt välja har redan blivit uppbokat. Vänligen försök
    igen.
  </Node>
);

export const LIMIT_STORAGE_REACHED = (
  <Node uri="booking/session/storage-reached-error">
    Något säte du har försökt välja har redan blivit uppbokat. Vänligen försök
    igen.
  </Node>
);

export const UPDATE_ERROR = (
  <Node uri="booking/session/update-passengers-error" status="-1">
    Misslyckades att spara bokningsuppgifter ([status])
  </Node>
);

export const UPDATE_ERROR_NETWORK = (
  <Node uri="booking/session/update-passengers-error-network">
    Misslyckades att spara bokningsuppgifter. Kontrollera din
    Internet-anslutning!
  </Node>
);

export const RESTART_SESSION_ERROR = (
  <Node uri="booking/session/restart-session-error" status="-1">
    Misslyckades att starta om din bokningssession ([status])
  </Node>
);

export const RESTART_SESSION_ERROR_NETWORK = (
  <Node uri="booking/session/restart-session-error-network">
    Misslyckades att starta om din bokningssession. Kontrollera din
    Internet-anslutning!
  </Node>
);

export const RESTART_SESSION_ERROR_FULL = (
  <Node uri="booking/session/restart-session-error-full">
    Avgången har tyvärr hunnit bli fullbokad.
  </Node>
);

export const RESET_DEPARTURES_ERROR_PRICING_EXPIRED = (
  <Node uri="booking/session/reset-departures-error-pricing-expired">
    Avgångarna är utdaterade. Vänligen vänta medan vi uppdaterar dem åt dig.
  </Node>
);

export const TIMEOUT_MESSAGES = {
  warning: (
    <Node uri="booking/session/timeout/warning.md">{md`
      **Snart tar din bokningssession slut!**

      Sedan måste vi låta någon annan få chansen att boka de säten du valt. Passa på att slutföra din bokning!
    `}</Node>
  ),
  error: (
    <Node uri="booking/session/timeout/error.md" status="-1">{md`
      **Din bokningssession verkar ha tagit slut!**

      Tyvärr lyckades vi inte kontakta servern för att verifiera detta. ([status])
    `}</Node>
  ),
  errorNetwork: (
    <Node uri="booking/session/timeout/error-network.md">{md`
      **Din bokningssession verkar ha tagit slut!**

      Tyvärr lyckades vi inte kontakta servern för att verifiera detta. Kontrollera din Internet-anslutning!
    `}</Node>
  ),
};

export const PAYMENT_MESSAGES = {
  400: (
    <Node uri="booking/session/payment/general-error">
      Något gick fel. Vad god försök igen.
    </Node>
  ),
  401: (
    <Node uri="booking/session/payment/invalid-voucher">
      Den vouchern du har använt är inte giltig.
    </Node>
  ),
  default: (
    <Node uri="booking/session/payment/unknown-error">
      Något gick fel. Var god försök igen.
    </Node>
  ),
};

export const WARNING_TRAIN_HAS_DEPARTED = {
  title: (
    <Node uri="booking/warning/train-has-departed/title">
      Tåget har redan avgått
    </Node>
  ),
  text: (
    <Node uri="booking/warning/train-has-departed/text">
      Du har valt en avgångstid som redan har passerat
    </Node>
  ),
  onRequestCloseText: (
    <Node uri="booking/warning/train-has-departed/close">
      Välj annan avgång
    </Node>
  ),
  children: (
    <Node uri="booking/warning/train-has-departed/text">
      Den avgången du har valt har redan avgått.
    </Node>
  ),
  onConfirmText: (
    <Node uri="booking/warning/train-has-departed/confirm">Fortsätt ändå</Node>
  ),
};

export const WARNING_INBOUND_BEFORE_ARRIVAL = {
  title: (
    <Node uri="booking/warning/inbound-before-arrival/title">
      Returresa före avresans ankomsttid
    </Node>
  ),
  children: (
    <Node uri="booking/warning/inbound-before-arrival/text">
      Du har valt en returresa som infaller innan beräknad ankomst.
    </Node>
  ),
  onConfirmText: (
    <Node uri="booking/warning/inbound-before-arrival/confirm">
      Jag förstår
    </Node>
  ),
};

export const WARNING_INBOUND_CLOSE_TO_ARRIVAL = {
  title: (
    <Node uri="booking/warning/inbound-close-to-arrival/title">
      Returresa nära avresa
    </Node>
  ),
  onRequestCloseText: (
    <Node uri="booking/warning/inbound-close-to-arrival/close">
      Välj annan avgång
    </Node>
  ),
  onConfirmText: (
    <Node uri="booking/warning/inbound-close-to-arrival/confirm">
      Fortsätt ändå
    </Node>
  ),
  children: (
    <Node uri="booking/warning/inbound-close-to-arrival/text">
      Du har valt en returresa som infaller mindre än två timmar efter beräknad
      ankomst.
    </Node>
  ),
};

export const WARNING_NEW_BOOKING_SESSION = {
  title: (
    <Node uri="booking/warning/new_booking_session/title">
      Bokning pågår redan
    </Node>
  ),
  onRequestCloseText: (
    <Node uri="booking/warning/new_booking_session/close">Tillbaka</Node>
  ),
  onConfirmText: (
    <Node uri="booking/warning/new_booking_session/confirm">Fortsätt ändå</Node>
  ),
  children: (
    <Node uri="booking/warning/new_booking_session/text">
      Du har redan en bokning pågående. Om du fortsätter kommer den bokningen
      att avbrytas.
    </Node>
  ),
};

export const COOKIES_DISABLED_ERROR = (
  <Node uri="booking/cookies-disabled-error.md">{md`
    Du måste tillåta [**cookies**](/sv/kundservice/solutions/articles/44000589341-cookies) från MTRX för att kunna starta en bokning.
    [**Läs mer!**](/sv/kundservice/solutions/articles/44000589054-integritetspolicy)
  `}</Node>
);

export const CREATE_SESSION_ERROR = (
  <Node uri="booking/create-session-error" status="-1">
    Misslyckades att starta en bokningssession ([status])
  </Node>
);

export const CREATE_SESSION_ERROR_NETWORK = (
  <Node uri="booking/create-session-error-network">
    Misslyckades att starta en bokningssession. Kontrollera din
    Internet-anslutning!
  </Node>
);

export const CREATE_SESSION_ERROR_FULL = (
  <Node uri="booking/create-session-error-full" time="?" station="?">
    Avgången [time] till [station] har tyvärr hunnit bli fullbokad. Välj en
    annan avgång.
  </Node>
);
