import { isBefore, startOfMonth } from "date-fns";

export default function makeMonths(months) {
  const outboundMonth = startOfMonth(months.outbound);
  const inboundMonth = startOfMonth(months.inbound);

  return {
    outbound: outboundMonth,
    inbound: isBefore(inboundMonth, outboundMonth)
      ? outboundMonth
      : inboundMonth,
  };
}
