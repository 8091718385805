import { FORM_ERROR } from "final-form";
import React from "react";
import { Sentry } from "sentry";

import { useAppContext } from "#components/AppContext";
import topNotification from "#components/topNotification";
import { SESSION_EXPIRED_STATUS } from "#containers/Booking/consts";
import {
  getLocalStoragePassengerForm,
  passengerFormLocalStorageKey,
} from "#containers/Booking/helpers";
import { UPDATE_ERROR, UPDATE_ERROR_NETWORK } from "#containers/Booking/nodes";
import { useTracking } from "#containers/Booking/TrackingContext";
import localStorage, { writeToLocalStorage } from "#utils/localStorage";

import { useBooking } from "..";

export default function usePassengersHandlers() {
  const {
    session,
    setLoading,
    setSession,
    forward,
    setSessionError,
    canMakeGroupBooking,
  } = useBooking();
  const { api, user } = useAppContext();
  const { passengers } = session;
  const track = useTracking();

  const initialValues = { passengers };

  const getInitialValues = () => {
    // If the session has expired, clear localStorage. If there’s a
    // `voucherPassenger` the form is read-only and thus there’s no need for
    // localStorage.
    if (
      session == null ||
      (session && session?.passengers?.some((p) => !p.is_editable))
    ) {
      localStorage.removeItem(passengerFormLocalStorageKey());
      return initialValues;
    }

    const values = getLocalStoragePassengerForm();

    if (!values) {
      return initialValues;
    }

    // Use the passengers saved in localStorage if:
    //
    // - They are the same number as in the form.
    // - The type of each passenger matches with the corresponding passenger in
    //   the form.
    // - Each saved passenger is an object.
    const savedPassengersMatch =
      values.passengers.length === session.passengers.length &&
      values.passengers.every((savedPassenger, index) => {
        const passenger = session.passengers[index];
        return (
          typeof savedPassenger === "object" &&
          savedPassenger != null &&
          !Array.isArray(savedPassenger) &&
          savedPassenger.type === passenger.type &&
          savedPassenger.is_editable === passenger.is_editable &&
          savedPassenger.has_wheelchair === passenger.has_wheelchair &&
          savedPassenger.has_pet === passenger.has_pet &&
          savedPassenger.has_stroller === passenger.has_stroller &&
          savedPassenger.has_walker === passenger.has_walker
        );
      });

    if (!savedPassengersMatch) {
      return initialValues;
    }

    return values;
  };

  const onPassengersChange = (values) => {
    // `onChange` of `PassengerForm` can fire before we've had a chance to read
    // from localStorage yet. If we were to update localStorage then, we'd
    // overwrite the previously saved values (most likely with empty values) and
    // lose them.
    if (session) {
      writeToLocalStorage(passengerFormLocalStorageKey(), {
        ...values,
        timestamp: Date.now(),
      });
    }
  };

  const onPassengersSubmit = async (values) => {
    setLoading(true);

    const firstPassenger = values.passengers[0];
    try {
      const { data: newSession } = await api.updatePassengersOfBookingSession({
        sessionId: session.id,
        passengers: values.passengers.map(
          ({
            firstname,
            lastname,
            email,
            phone,
            has_wheelchair,
            has_optedin,
            type,
          }) => {
            const passengerdDetails = { firstname, lastname, email, phone };
            return {
              ...(canMakeGroupBooking ? firstPassenger : passengerdDetails),
              type,
              has_wheelchair: has_wheelchair == null ? false : has_wheelchair,
              has_optedin: has_optedin == null ? false : has_optedin,
            };
          }
        ),
      });

      // When adding a new favorite that has the
      // same email as the admin it crashes. Here's solution #1:
      // Basically it just skips to add the new favorite
      // since the email is being used by the logged in account.
      // It lets the user get on with the booking without any hassle, continue on line 1186
      const favoritesToAdd = values.passengers.filter(
        ({ add_favorite = undefined, email }) =>
          add_favorite === true && email !== user?.email
      );

      if (favoritesToAdd.length > 0 && user) {
        await api.createFavoritePassenger({
          data: favoritesToAdd,
        });
      }

      track("passengers.forward");
      setSession(newSession);
      setLoading(false);
      forward();

      return undefined;
    } catch (error) {
      setLoading(false);

      if (error.response != null) {
        const { status, data } = error.response;

        if (status === SESSION_EXPIRED_STATUS) {
          setSessionError({ status });
        }

        // Continue from line 1118
        // If we remove solution #1 above we'll halt the booking
        // process and show an error notification saying that
        // the added favorite can't have the same details as the
        // current logged in account
        if (data != null && typeof data === "object") {
          const { non_field_errors: nonFieldErrors, ...errors } = data;
          if (Array.isArray(data) && data[0].non_field_errors) {
            topNotification(
              React.cloneElement(UPDATE_ERROR, {
                status: data[0].non_field_errors,
              })
            );
          }
          return {
            [FORM_ERROR]: nonFieldErrors,
            ...errors,
          };
        }
      }

      console.error(
        "Booking: Failed to update passengers",
        error,
        error.response
      );
      const status = error.response != null ? error.response.status : undefined;
      if (status != null) {
        topNotification(React.cloneElement(UPDATE_ERROR, { status }));
      } else if (error.noResponse) {
        error.message = `Network error: ${error.message}`;
        topNotification(UPDATE_ERROR_NETWORK);
      } else {
        topNotification(React.cloneElement(UPDATE_ERROR, { status: 1500 }));
      }
      Sentry.captureException(error);

      return undefined;
    }
  };

  return {
    getInitialValues,
    onPassengersChange,
    onPassengersSubmit,
  };
}
