import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import withRoundness from "../../hocs/withRoundness";
import Clickable from "../Clickable";
import styles from "./Button.module.css";

Button.propTypes = {
  variant: PropTypes.oneOf(["contained", "outlined"]),
  color: PropTypes.oneOf(["primary", "secondary", "cancel", "tertiary"]),
  block: PropTypes.bool,
  big: PropTypes.bool,
  className: PropTypes.string,
  Icon: PropTypes.func,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  noBorder: PropTypes.bool,
};

Button.defaultProps = {
  variant: "contained",
  block: false,
  big: false,
  className: "",
  color: "primary",
  Icon: undefined,
  children: undefined,
  noBorder: false,
};

// A simple wrapper around `<Clickable>` to returns an element that looks like a
// button (but can be either an `<a>` or a `<button>` under the hood).
function Button({
  block,
  variant,
  big,
  className,
  children,
  color,
  Icon,
  noBorder,
  ...restProps
}) {
  const oldColor = (() => {
    switch (color) {
      case "red":
      case "pink":
      case "yellow":
      case "wine":
        return "#FF00FF";
      default:
        return undefined;
    }
  })();

  return (
    <MaybeJustButton
      {...restProps}
      style={{ backgroundColor: oldColor && `${oldColor} !important` }}
      className={classnames(
        styles.root,
        {
          // styles
          [styles.outlined]: variant === "outlined",
          [styles.contained]: variant === "contained",

          // colors
          [styles.primary]: color === "primary",
          [styles.secondary]: color === "secondary",
          [styles.cancel]: color === "cancel",
          [styles.tertiary]: color === "tertiary",

          // generics
          [styles.big]: big,
          [styles.block]: block,
          [styles.withIcon]: Icon != null,
          [styles.noBorder]: noBorder,
        },
        className
      )}
    >
      <span>{children}</span>
      {/* SVG */}
      {Icon && <Icon className={styles.icon} />}
    </MaybeJustButton>
  );
}

// If we just need to render a Button outside of the
// contexts of this application, e.g. a modal with ReactDOM.render,
// we cannot use AppContext (will cause crash). In those cases, don't
// redirect to Clickable as it uses AppContext. Just render a button
// normally. It's quite a weird case, but this solves the issue.

MaybeJustButton.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

MaybeJustButton.defaultProps = {
  type: undefined,
  disabled: false,
};

function MaybeJustButton({ type, disabled, ...restProps }) {
  return type === "button" ? (
    <button type="button" disabled={disabled} {...restProps} />
  ) : (
    <Clickable type={type} disabled={disabled} {...restProps} />
  );
}

export default withRoundness(Button)();
