import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";

import EventListener from "../EventListener";
import styles from "./base.module.css";

const isClient = typeof window !== "undefined";

const appElement =
  typeof document === "undefined"
    ? undefined
    : document.getElementById("__next") ||
      // Storybook support.
      document.getElementById("root");

ModalBase.propTypes = {
  isOpen: PropTypes.bool,
  animationDuration: PropTypes.number,
  contentLabel: PropTypes.string,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

ModalBase.defaultProps = {
  isOpen: false,
  animationDuration: 200, // ms
  contentLabel: undefined,
  onAfterOpen: undefined,
  children: undefined,
  className: "",
};

// Base modal component. Provides a backdrop and some animation, but no close
// button and no styling of the modal itself.
export default function ModalBase({
  isOpen,
  animationDuration,
  contentLabel,
  onAfterOpen,
  onRequestClose,
  className,
  children,
  ...restProps
}) {
  const transitionDuration = `${animationDuration}ms`;

  React.useEffect(() => {
    const freezeBody = (bool) => {
      document.body.style.position = bool ? "absolute" : "static";
      document.body.style.overflow = bool ? "hidden" : "visible";
    };

    if (isClient) {
      // Freeze body if client is open to prevent duplicate scrollbars.
      if (isOpen) {
        freezeBody(true);
      } else {
        freezeBody(false);
      }
    }

    return () => freezeBody(false);
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <EventListener
          eventName="mousedown"
          listener={(e) => {
            if (
              e.target.closest(".baseModal > *") == null &&
              e.target.closest(".djedi-node-outline") == null
            ) {
              onRequestClose();
            }
          }}
        />
      )}
      {/* For some reason the shouldCloseOnOverlayClick-prop of ReactModal 
      doesn't seem to work. This event listener does the trick. */}
      <ReactModal
        isOpen={isOpen}
        closeTimeoutMS={animationDuration}
        appElement={appElement}
        contentLabel={contentLabel}
        bodyOpenClassName="is-modalOpen"
        portalClassName="ModalBase"
        overlayClassName={{
          base: styles.overlay,
          afterOpen: styles.afterOpen,
          beforeClose: styles.beforeClose,
        }}
        className={{
          base: classnames(styles.content, className, "baseModal"),
          afterOpen: styles.afterOpen,
          beforeClose: styles.beforeClose,
        }}
        style={{
          overlay: { transitionDuration },
          content: { transitionDuration },
        }}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
        {...restProps}
      >
        {children}
      </ReactModal>
    </>
  );
}
