import React from "react";

import useGetScrollToTop from "#containers/Booking/BookingContext/helpers/useGetScrollToTop";

export default function useScrollTop() {
  const scrollToTop = useGetScrollToTop();

  React.useEffect(() => {
    scrollToTop();
  }, [scrollToTop]);

  return null;
}
