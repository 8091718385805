import Router from "next/router";
import PropTypes from "prop-types";
import React from "react";

import AppContext from "#components/AppContext";
import StyledLink from "#components/StyledLink";
import topNotification from "#components/topNotification";
import { ERRORS } from "#pages-helpers/konto/nodes";

import { ROUTES } from "../../routes";
import { Sentry } from "../../sentry";

const Logout = ({ disabled, component: Component = StyledLink, ...props }) => {
  const [loading, setLoading] = React.useState(false);
  const { logout } = React.useContext(AppContext);

  async function logoutHandler() {
    setLoading(true);
    try {
      await logout();
      // eslint-disable-next-line no-unused-vars

      try {
        window.navigator.credentials.preventSilentAccess();
        // eslint-disable-next-line no-empty
      } catch (err) {}

      await Router.push(ROUTES.home.pathname);

      // No need to call `NProgress.done()` or `setLoggingOut(false)` since
      // `Router.push` causes a pages load.
    } catch (error) {
      console.error("Account: Failed to logout", error, error.response);
      Sentry.captureException(error);
      setLoading(false);

      if (error.response != null) {
        topNotification(
          React.cloneElement(ERRORS.logout.other, {
            status: error.response.status,
          })
        );
      } else if (error.noResponse) {
        topNotification(ERRORS.logout.network);
      } else {
        topNotification(
          React.cloneElement(ERRORS.logout.other, { status: 1500 })
        );
      }
    }
  }

  return (
    <Component
      disabled={loading || disabled}
      onClick={logoutHandler}
      data-cy="logout-button"
      {...props}
    />
  );
};

Logout.propTypes = {
  component: PropTypes.func,
  disabled: PropTypes.bool,
};

Logout.defaultProps = {
  disabled: false,
  component: undefined,
};

export default Logout;
