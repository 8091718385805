import bookingClassFromJourney from "./bookingClassFromJourney";
import countsFromPassengers from "./countsFromPassengers";
import extrasFromPassengers from "./extrasFromPassengers";

const today = new Date();

export default function getSessionDetails(session) {
  const { journeys } = session;
  const [outbound, inbound] = journeys;

  return {
    outbound: {
      date: new Date(outbound.departure_at.date),
      id: outbound.id,
      bookingClass: bookingClassFromJourney(outbound),
    },
    inbound: {
      date: inbound?.ids
        ? new Date(inbound.departure_at.date)
        : // Functions shared with pages/booking/index.js expect this date to always exist.
          today,
      id: inbound?.id,
      bookingClass: bookingClassFromJourney(inbound),
    },
    counts: countsFromPassengers(session.passengers),
    extras: extrasFromPassengers(session.passengers),
  };
}
