import Router from "next/router";
import React from "react";

import ArrowLink from "#components/ArrowLink";
import Container from "#components/Container";
import { noop } from "#utils";

import LoginForm, { LOGIN_NODE, RESET_PASSWORD } from "../LoginForm";
import ModalClubX from "../Modal/clubx";
import SignupForm, { SIGNUP_NODE } from "../SignupForm";
import styles from "./LoginModal.module.css";

export { LOGIN_NODE };

export const LoginModalContext = React.createContext({
  isOpen: false,
  open: noop,
  close: noop,
});

export default function LoginModal() {
  const { isOpen, close } = React.useContext(LoginModalContext);

  // Always route back to the current page when logging in through the modal.

  const [signup, setSignup] = React.useState(false);
  const [resetPassword, setResetPasswordState] = React.useState(false);

  // Automatically close the modal if the route changes.
  React.useEffect(() => {
    if (isOpen) {
      Router.events.on("routeChangeStart", close);

      return () => {
        Router.events.off("routeChangeStart", close);
      };
    }

    return undefined;
  }, [close, isOpen]);

  return (
    <ModalClubX
      isOpen={isOpen}
      heading={
        signup ? SIGNUP_NODE : resetPassword ? RESET_PASSWORD : LOGIN_NODE
      }
      onRequestClose={close}
    >
      <Container>
        {signup ? (
          <SignupForm
            after={
              <ArrowLink
                onClick={() => {
                  setSignup(false);
                }}
              >
                {LOGIN_NODE}
              </ArrowLink>
            }
            className={styles.form}
          />
        ) : (
          <LoginForm
            onLogin={close}
            resetPassword={resetPassword}
            setResetPasswordState={setResetPasswordState}
            after={
              <ArrowLink
                onClick={() => {
                  setSignup(true);
                }}
              >
                {SIGNUP_NODE}
              </ArrowLink>
            }
            className={styles.form}
          />
        )}
      </Container>
    </ModalClubX>
  );
}
