import { Node } from "djedi-react";
import React from "react";

export const PICK_DEPARTURE_OUTBOUND = (
  <Node uri="DefineTrip/pick-departure/outbound">Avresedatum</Node>
);

export const PICK_DEPARTURE_INBOUND = (
  <Node uri="DefineTrip/pick-departure/inbound">Returresedatum</Node>
);

export const PASSENGERS = (
  <Node uri="DefineTrip/passenger-amount">Resenärer</Node>
);
