import { useRouter } from "next/router";
import React from "react";

import { useAppContext } from "#components/AppContext";
import ModalWarning from "#components/Modal/warning";
import { WARNING_NEW_BOOKING_SESSION } from "#containers/Booking/nodes";
import useNProgress from "#hooks/useNProgress";
import { PSP } from "#utils/enums";

import {
  BOOKING_STEPS,
  SESSION_STEPS,
  STAFF_BOOKING_DISCOUNT_NAMES,
  Steps,
} from "../consts";
import { makeQueryParamsBooking, makeQueryParamsSession } from "../helpers";
import {
  BookingContextDefaultProps,
  BookingContextPropTypes,
  INITIAL_SWISH_STATUS,
} from "./consts";
import { getInitialShow } from "./helpers";

const BookingContext = React.createContext();

export const useBooking = () => React.useContext(BookingContext);

export const BookingContextProvider = ({
  children,
  initialData: passedInitialData,
  steps: initialSteps,
  layout,
  onSubmitOverrides,
  isRebook,
  isUpgrade,
  oldJourney,
  ...rest
}) => {
  const { user } = useAppContext();
  const filteredInitialSteps = !isUpgrade
    ? initialSteps.filter((step) => step !== Steps.UPGRADE)
    : initialSteps;

  const { current: initialData } = React.useRef(passedInitialData);
  const initialRebookJourneyId = isRebook
    ? initialData?.["rebook-journey-id"]
    : null;
  const initialUpgradeJournalId = isUpgrade
    ? initialData?.["upgrade-journey-id"]
    : null;

  const voucherCode = initialData?.["voucher-code"];

  // States
  const [routes, setRoutes] = React.useState();
  const [voucher, setVoucher] = React.useState();
  const [show, setShow] = React.useState(getInitialShow(initialData?.show));

  const [eventPage, setEventPage] = React.useState();
  const [event, setEvent] = React.useState(initialData?.["event"]);

  const [rebookJourneyId, setRebookJourneyId] = React.useState(
    initialRebookJourneyId
  );
  const [upgradeJourneyId, setUpgradeJourneyId] = React.useState(
    initialUpgradeJournalId
  );

  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [booking, setBooking] = React.useState();
  const [session, setSession] = React.useState();
  const [favoritePassengers, setFavoritePassengers] = React.useState();
  const [psp, setPsp] = React.useState();
  const [seating, setSeating] = React.useState();
  const [addons, setAddons] = React.useState();
  const [daysInfo, setDaysInfo] = React.useState({});
  const [acceptMandatoryTerms, setAcceptMandatoryTerms] = React.useState({
    checked: false,
    touched: false,
  });
  const [acceptOptionalTerms, setAcceptOptionalTerms] = React.useState({
    checked: false,
    touched: false,
  });
  const [sessionError, setSessionError] = React.useState();
  const [expiredSession, setExpiredSession] = React.useState();
  const [timesNearlyUp, setTimesNearlyUp] = React.useState(false);
  const [steps, setSteps] = React.useState(filteredInitialSteps);
  const [swishStatus, setSwishStatus] = React.useState(INITIAL_SWISH_STATUS);
  const [departures, setDepartures] = React.useState({});
  const [newSessionWarning, setNewSessionWarning] = React.useState(undefined);
  const router = useRouter();
  const { asPath } = router;

  const { show: paramsShow = "" } = Object.fromEntries(
    new URLSearchParams(asPath)
  );

  const paramsShowNum = Steps[paramsShow.toUpperCase()];

  React.useEffect(() => {
    if (paramsShowNum) {
      setShow(paramsShowNum);
    }
  }, [paramsShowNum]);

  const isBookingStep = BOOKING_STEPS.includes(show);
  const isSessionStep = SESSION_STEPS.includes(show);

  const isStaffVoucher =
    STAFF_BOOKING_DISCOUNT_NAMES.includes(voucher?.code) ||
    session?.discount_codes?.some(({ discount }) =>
      STAFF_BOOKING_DISCOUNT_NAMES.includes(discount.code)
    );
  const isStaff = Boolean(user?.is_staff);

  const canMakeGroupBooking = isStaff && isStaffVoucher;

  const isValidRebook = Boolean(
    rebookJourneyId && Object.entries(departures).length
  );

  const isFreePayment = psp?.some(({ type }) => type === PSP.REBEL);

  const createQueryParams = () => {
    if (booking && isBookingStep) {
      return makeQueryParamsBooking({
        ...booking,
        event,
        voucherCode,
        show,
        rebookJourneyId,
        upgradeJourneyId,
      });
    }
    if (session && isSessionStep) {
      return makeQueryParamsSession({
        ...session,
        event,
        show,
        voucherCode,
        rebookJourneyId,
        upgradeJourneyId,
      });
    }

    return undefined;
  };

  const data = createQueryParams() || initialData;

  useNProgress(loading);

  const getNextStep = () => {
    const current = steps.indexOf(show);

    const next = steps[current + 1];
    const validStep = steps.includes(next);

    return validStep ? next : current;
  };

  const getPreviousStep = () => {
    const current = steps.indexOf(show);
    const previous = steps[current - 1];
    const validStep = steps.includes(previous);

    return validStep ? previous : current;
  };

  const forward = () => setShow(getNextStep());
  const back = () => setShow(getPreviousStep());

  const onSubmitOverride = onSubmitOverrides[show];

  const openNewSessionWarning = ({ onConfirm, onDismiss }) => {
    setNewSessionWarning({
      onConfirm: () => {
        setNewSessionWarning(undefined);
        onConfirm();
      },
      onDismiss: () => {
        setNewSessionWarning(undefined);
        onDismiss();
      },
    });
  };

  const context = {
    ...rest,
    acceptMandatoryTerms,
    acceptOptionalTerms,
    addons,
    back,
    booking,
    canMakeGroupBooking,
    data,
    daysInfo,
    departures,
    disabled,
    expiredSession,
    favoritePassengers,
    forward,
    getNextStep,
    getPreviousStep,
    initialData,
    isBookingStep,
    isFreePayment,
    isRebook,
    isUpgrade,
    isSessionStep,
    isStaff,
    isStaffVoucher,
    isValidRebook,
    layout,
    eventPage,
    setEventPage,
    loading,
    oldJourney,
    onSubmitOverride,
    event,
    setEvent,
    onSubmitOverrides,
    voucherCode,
    paramsShowNum,
    psp,
    rebookJourneyId,
    upgradeJourneyId,
    routes,
    seating,
    session,
    sessionError,
    setAcceptMandatoryTerms,
    setAcceptOptionalTerms,
    setAddons,
    setBooking,
    setDaysInfo,
    setDepartures,
    setDisabled,
    setExpiredSession,
    setFavoritePassengers,
    setLoading,
    setPsp,
    setRebookJourneyId,
    setUpgradeJourneyId,
    setRoutes,
    setSeating,
    setSession,
    setSessionError,
    setShow,
    setSteps,
    setSwishStatus,
    setTimesNearlyUp,
    setVoucher,
    show,
    steps,
    swishStatus,
    timesNearlyUp,
    voucher,
    openNewSessionWarning,
  };

  return (
    <BookingContext.Provider value={context}>
      <>
        <ModalWarning
          title={WARNING_NEW_BOOKING_SESSION.title}
          onRequestClose={() => newSessionWarning?.onDismiss()}
          onConfirm={() => newSessionWarning?.onConfirm()}
          onRequestCloseText={WARNING_NEW_BOOKING_SESSION.onRequestCloseText}
          onConfirmText={WARNING_NEW_BOOKING_SESSION.onConfirmText}
          isOpen={Boolean(newSessionWarning)}
        >
          {WARNING_NEW_BOOKING_SESSION.children}
        </ModalWarning>
        {typeof children === "function" ? children(context) : children}
      </>
    </BookingContext.Provider>
  );
};

BookingContextProvider.propTypes = BookingContextPropTypes;
BookingContextProvider.defaultProps = BookingContextDefaultProps;

export default BookingContext;
