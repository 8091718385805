import { isDigits } from "#utils/";
import { PassengerType } from "#utils/enums";

import {
  DEFAULT_COUNTS,
  MAX_PER_TYPE,
  PASSENGER_TYPES,
  STAFF_MAX_PER_TYPE,
} from "../consts";
import countInfants from "./countInfants";

export default function countsFromInitialData(
  query,
  maxCounts = {},
  canMakeGroupBooking
) {
  const maxPerType = canMakeGroupBooking ? STAFF_MAX_PER_TYPE : MAX_PER_TYPE;

  const counts = PASSENGER_TYPES.reduce((result, type) => {
    const paramCount = query[type.param];
    const { [type.id]: maxCountRaw = Infinity } = maxCounts;
    const maxCount = Math.min(maxPerType, maxCountRaw);
    const count =
      paramCount == null || !isDigits(paramCount)
        ? 0
        : Math.min(maxCount, Number(paramCount));
    result[type.id] = count;
    return result;
  }, {});

  const firstType = PASSENGER_TYPES.find((type) => {
    const { [type.id]: maxCountRaw = Infinity } = maxCounts;
    return maxCountRaw > 0;
  });

  const [numInfants, numNonInfants] = countInfants(counts);

  return numNonInfants === 0
    ? {
        ...DEFAULT_COUNTS,
        ...(firstType != null ? { [firstType.id]: 1 } : {}),
      }
    : numInfants > numNonInfants
    ? { ...counts, [PassengerType.INFANT]: numNonInfants }
    : counts;
}
