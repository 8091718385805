import { djedi, Node } from "djedi-react";
import React from "react";
import ReactDOM from "react-dom";

import { accessibilityFocus } from "#utils/dom";

import { Sentry } from "../../sentry";
import styles from "./topNotification.module.css"; // ms

const ID = "topNotification";
const DURATION = 5000; // ms
const TRANSITION_DURATION = 200;

let timeoutId = undefined;

// This is not a React component, but still a UI component. And `message` is
// allowed to be JSX. `topNotification("Hi there!")` shows “Hi there!” at the
// top of the viewport, and auto-closes after the given duration. There’s also a
// close button. There can only be one topNotification at a time – if calling
// `topNotification` while a notification is already shown, the old one will be
// replaced.
export default function topNotification(message, { duration = DURATION } = {}) {
  if (timeoutId != null) {
    clearTimeout(timeoutId);
  }

  const focusedElement = document.activeElement;

  const element = document.createElement("div");
  element.id = ID;
  element.setAttribute("data-cy", "topNotification");
  element.classList.add(styles.root, styles.hidden);
  element.style.transitionDuration = `${TRANSITION_DURATION}ms`;

  const close = () => {
    // Hide and wait for the animation.
    element.classList.add(styles.hidden);
    timeoutId = setTimeout(() => {
      timeoutId = undefined;
      // Remove all notifications.
      removePrevious();
      // Re-focus what was focused before the notification, if anything.
      if (focusedElement != null) {
        accessibilityFocus(focusedElement);
      }
    }, TRANSITION_DURATION);
  };

  let sentToSentry = false;

  const notification = (
    <>
      {message.type === Node
        ? React.cloneElement(message, {
            render: (state, data) => {
              if (state.type === "success" && !sentToSentry) {
                const content =
                  typeof state.content === "string"
                    ? state.content
                    : state.content.props.children;
                Sentry.addBreadcrumb({
                  category: "topNotification",
                  message:
                    typeof content === "string"
                      ? content
                      : `Unknown message: ${content}`,
                  level: "warning",
                });
                sentToSentry = true;
              }
              const { render = djedi.options.defaultRender } = message.props;
              return render(state, data);
            },
          })
        : message}
      <button
        type="button"
        aria-label="Close"
        className={styles.button}
        onClick={() => {
          if (timeoutId != null) {
            clearTimeout(timeoutId);
          }
          close();
        }}
      >
        ×
      </button>
    </>
  );

  if (message.type !== Node) {
    Sentry.addBreadcrumb({
      category: "topNotification",
      message:
        typeof message === "string" ? message : `Unknown message: ${message}`,
      level: "warning",
    });
    sentToSentry = true;
  }

  // Remove all previous notifications (if any), insert the new one and focus it.
  removePrevious();
  ReactDOM.render(notification, element);
  document.documentElement.appendChild(element);
  accessibilityFocus(element);

  // Show the notification after a small timeout. Otherwise the CSS transition
  // does not trigger.
  timeoutId = setTimeout(() => {
    element.classList.remove(styles.hidden);
    timeoutId = setTimeout(close, duration);
  }, 10);
}

function removePrevious() {
  /* eslint-disable-next-line */
  for (const previous of document.querySelectorAll(`#${ID}`)) {
    ReactDOM.unmountComponentAtNode(previous);
    previous.parentNode.removeChild(previous);
  }
}

// When hot reloading, there can be left-over elements from previous runs.
if (typeof document !== "undefined") {
  removePrevious();
}
