import classnames from "classnames";
import { ForceNodes, Node } from "djedi-react";
import Link from "next/link";
import PropTypes from "prop-types";
import React from "react";
import { ROUTES } from "routes";

import MittVrIcon from "../../../../icons/pictograms/mittvr.svg";
import { LOGIN_NODE, LoginModalContext } from "../../../LoginModal";
import styles from "./UserButton.module.css";

const ACCOUNT_STATUS = {
  logged_out: <Node uri="nav/logged-in/false">Logga in</Node>,
  logged_in: <Node uri="nav/logged-in/true">Mitt konto</Node>,
};

function UserButton({ user }) {
  const { open } = React.useContext(LoginModalContext);

  return React.cloneElement(LOGIN_NODE, {
    render: function render(state) {
      const button = (
        <button
          type="button"
          className={styles.userButton}
          onClick={user == null ? open : undefined}
          title={
            user != null
              ? // This seems to be a bug in eslint-plugin-react:
                // eslint-disable-next-line react/prop-types
                user.name
              : state.type === "success"
              ? state.content.props.children
              : undefined
          }
          data-cy="user-button"
        >
          <div
            className={classnames(styles.userButtonInner, {
              [styles.loggedOut]: user == null,
            })}
          >
            <MittVrIcon
              className={classnames(styles.myMtrxIcon, "preventDefaultIcon")}
            />
            <span className={styles.loggedInState}>
              {user == null
                ? ACCOUNT_STATUS.logged_out
                : ACCOUNT_STATUS.logged_in}
            </span>
          </div>
        </button>
      );

      return (
        <>
          {user != null ? (
            <Link passHref href={ROUTES.account.pathname}>
              <a>{button}</a>
            </Link>
          ) : (
            button
          )}{" "}
          <ForceNodes>{ACCOUNT_STATUS}</ForceNodes>
        </>
      );
    },
  });
}
UserButton.propTypes = {
  user: PropTypes.object,
};

UserButton.defaultProps = {
  user: undefined,
};

export default UserButton;
