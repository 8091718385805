export default function getAllDays({ from, to, daysInfo, isInbound = false }) {
  const monthInfoPrefix = !isInbound
    ? `${from.id}|${to.id}`
    : `${to.id}|${from.id}`;

  return Object.entries(daysInfo).reduce((obj, [key, value]) => {
    if (key.startsWith(monthInfoPrefix)) {
      return { ...obj, ...value };
    }
    return obj;
  }, {});
}
