import Router from "next/router";
import React from "react";
import { ROUTES } from "routes";
import { Sentry } from "sentry";
import { Socker } from "socker.js";

import { useAppContext } from "#components/AppContext";
import topNotification from "#components/topNotification";
import { useSessionFetchers } from "#containers/Booking/BookingContext/helpers";
import { SESSION_EXPIRED_STATUS, SwishState } from "#containers/Booking/consts";
import { PAYMENT_MESSAGES } from "#containers/Booking/nodes";
import { useTracking } from "#containers/Booking/TrackingContext";
import { deleteNil, onMobileDevice } from "#utils";
import { deleteCurrentBookingSessionData } from "#utils/booking";
import { PSP } from "#utils/enums";

import { useBooking } from "..";

export default function usePaymentHandlers() {
  const { api, userAgent } = useAppContext();
  const {
    session,
    setPsp,
    setLoading,
    setSessionError,
    psp,
    swishStatus,
    setSwishStatus,
    setSession,
  } = useBooking();
  const track = useTracking();
  const { getPsp } = useSessionFetchers();

  const sessionId = session?.id;
  const pspState = psp?.find(({ selected }) => selected);
  const initiatedPayment = pspState?.ongoing;

  const redirectToReceipt = () => {
    const { pathname } = ROUTES.bookingDone;
    Router.replace({ pathname, query: { sessionId } });
  };

  const handleSwishWebSocketMessage = async (data) => {
    const parsedData = JSON.parse(data);

    const newSwishStatus = parsedData.ok
      ? SwishState.FINISHED
      : SwishState.FAILED;
    setSwishStatus({ ...swishStatus, status: newSwishStatus });

    if (parsedData.ok === true) {
      redirectToReceipt();
    } else {
      // handle error
      // this.setState({ websocketError: parsedData.error, loading: false });
    }
  };

  const waitForSwish = ({ wsurl, channel, onMessage }) => {
    const socker = new Socker(wsurl);
    socker.on(channel, onMessage);
  };

  const onSwishPayment = async ({ number }) => {
    try {
      const onMobile = onMobileDevice(userAgent);
      setLoading(true);

      const data = onMobile ? {} : { phone: number };

      const { data: swish } = await api.internalPSPInit({
        method: "POST",
        url: pspState.snippet_endpoint,
        data,
      });

      if (swish.status === "ERROR") {
        setLoading(false);
        throw new Error(swish.error);
      }

      if (swish.success) {
        setSwishStatus({ ...swishStatus, status: SwishState.PENDING });

        waitForSwish({
          wsurl: swish.ws.u,
          channel: swish.ws.c,
          onMessage: handleSwishWebSocketMessage,
        });

        if (onMobile) {
          const appPush = `swish://paymentrequest?token=${
            swish.token
          }&callbackurl=${encodeURIComponent("mtrexpress://")}`;

          window.location = appPush;
        }
      }
    } catch (error) {
      console.error(error, error.response);

      Sentry.captureException(error);
    }
  };

  const onLoyaltyPayment = async () => {
    try {
      await api.paymentLoyalty({ sessionId });
      redirectToReceipt();
    } catch (error) {
      console.error(
        "Payment: Failed to complete payment",
        error,
        error.response
      );

      Sentry.captureException(error);
    }
  };

  const onBonusPayment = async () => {
    try {
      await api.paymentBonus({ sessionId });
      redirectToReceipt();
    } catch (error) {
      console.error(
        "Payment: Failed to complete payment",
        error,
        error.response
      );

      Sentry.captureException(error);
    }
  };

  const onInvoicePayment = async ({ reference: manual_reference }) => {
    try {
      setLoading(true);
      await api.paymentInvoice(
        deleteNil({
          sessionId: session.id,
          manual_reference: manual_reference === "" ? null : manual_reference,
        })
      );

      redirectToReceipt();
    } catch (error) {
      const status = error.response != null ? error.response.status : undefined;
      console.error(
        "Payment: Failed to complete payment",
        error,
        error.response
      );

      setLoading(false);
      topNotification(status ? PAYMENT_MESSAGES[status] : error.message);

      Sentry.captureException(error);
    }
  };

  const onFreePayment = async () => {
    try {
      setLoading(true);
      await api.freePSPFinish({ sessionId: session.id });

      redirectToReceipt();
    } catch (error) {
      console.error(
        "Payment: Failed to complete payment",
        error,
        error.response
      );
      setLoading(false);

      Sentry.captureException(error);
    }
  };

  const onPaymentSubmit = (values, selectedPsp) => {
    const paymentFuncions = {
      [PSP.SWISH]: () => onSwishPayment(values),
      [PSP.KLARNA]: () => undefined,
      [PSP.REBEL]: () => onFreePayment(values),
      [PSP.LOYALTY]: () => onLoyaltyPayment(values),
      [PSP.BONUS]: () => onBonusPayment(values),
      [PSP.INVOICE]: () => onInvoicePayment(values),
      [PSP.TRAVEL_CLEARING]: () => onPaymentTravelClearing(values),
    };

    track("payment.forward");
    const paymentFunc = paymentFuncions[selectedPsp];
    paymentFunc();

    // Clear out the cookie so that backend doesn't set pending Klarna booking as abandoned
    deleteCurrentBookingSessionData();
  };

  const onPaymentChange = React.useCallback(
    async (newPsp, { silent = false } = {}) => {
      try {
        if (!silent) {
          setLoading(true);
        }
        const { data: pspData } = await api.setBookingSessionPsp({
          sessionId,
          psp: newPsp,
        });

        track("payment.change", newPsp);
        setPsp(pspData);
        if (!silent) {
          setLoading(false);
        }
      } catch (error) {
        console.error(
          "Payment: Failed to update payment method",
          error,
          error.response
        );

        if (!silent) {
          setLoading(false);
        }

        const status = error?.response?.status;
        if (status === SESSION_EXPIRED_STATUS) {
          setSessionError({ status });
        }

        Sentry.captureException(error);
      }
    },
    [api, sessionId, setLoading, setPsp, setSessionError, track]
  );

  const reloadPsp = async () => {
    const newPsp = await getPsp(sessionId);

    setPsp(newPsp);
  };

  const onRemoveGiftCode = async () => {
    setLoading(true);
    try {
      const res = await api.removeGiftCode(sessionId);
      const { data } = await res.toJSON();
      await reloadPsp();
      setSession(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error("faile to remove gift card", e);
    }
  };

  const onAddGiftCode = async (values) => {
    setLoading(true);
    try {
      const res = await api.addGiftCode({ data: values, id: sessionId });
      if (res.status === 200) {
        const { data } = await res.toJSON();
        reloadPsp();
        setSession(data);
        setLoading(false);
      }
    } catch ({ response }) {
      setLoading(false);
      return { code: response.data.map(({ detail }) => detail)?.join(", ") };
    }
    return true;
  };

  const onPaymentTravelClearing = async ({ manual_references }) => {
    try {
      setLoading(true);

      const data = {
        manual_references: manual_references.filter(Boolean),
      };
      await api.paymentTravelClearing({
        id: sessionId,
        data,
      });
      redirectToReceipt();
    } catch (error) {
      console.error(error, error.response);
      setLoading(false);

      const errorDetail = error?.response?.data;
      if (errorDetail) {
        topNotification(errorDetail);
      }

      Sentry.captureException(error);
    }
  };

  return {
    onPaymentChange,
    onPaymentSubmit,
    pspState,
    initiatedPayment,
    onRemoveGiftCode,
    onAddGiftCode,
    onPaymentTravelClearing,
  };
}
