import React from "react";

import { useBooking } from "../BookingContext";
import { INITIAL_SWISH_STATUS } from "../BookingContext/consts";
import getCommonBookingParams from "./getCommonBookingParams";

export default function withBookingCommons(WrappedComponent) {
  const WithBookingCommons = (props) => {
    const {
      canMakeGroupBooking,
      data,
      routes,
      setAddons,
      setBooking,
      setPsp,
      setSeating,
      setSession,
      setSessionError,
      setSwishStatus,
      setTimesNearlyUp,
      voucher,
    } = useBooking();

    const lastVoucher = React.useRef(voucher?.code);
    const voucherCode = voucher?.code;

    const resetSessionStates = React.useCallback(() => {
      setSeating(undefined);
      setPsp(undefined);
      setAddons(undefined);
      setSessionError(undefined);
      setTimesNearlyUp(false);
      setSwishStatus(INITIAL_SWISH_STATUS);
      setSession(undefined);
    }, [
      setSeating,
      setPsp,
      setAddons,
      setSessionError,
      setTimesNearlyUp,
      setSwishStatus,
      setSession,
    ]);

    React.useEffect(() => {
      // Reset all state that depends on session
      resetSessionStates();
    }, [resetSessionStates]);

    React.useEffect(() => {
      const hasChanged = lastVoucher.current !== voucherCode;

      if (data && routes && hasChanged) {
        const bookingParams = getCommonBookingParams({
          routes,
          voucher,
          data,
          canMakeGroupBooking,
        });

        setBooking(bookingParams);
      }
    }, [
      data,
      routes,
      voucher,
      lastVoucher,
      setBooking,
      voucherCode,
      canMakeGroupBooking,
    ]);

    React.useEffect(() => {
      lastVoucher.current = voucherCode;
    }, [voucherCode]);

    return <WrappedComponent {...props} />;
  };

  return WithBookingCommons;
}
