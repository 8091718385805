export default function extrasToInitialData(extras) {
  if (extras == null) {
    return undefined;
  }
  return {
    stroller: extras.stroller ? "true" : undefined,
    pet: extras.pet ? "true" : undefined,
    wheelchairs:
      extras.wheelchairs === 0 ? undefined : String(extras.wheelchairs),
    walkers: extras.walkers === 0 ? undefined : String(extras.walkers),
  };
}
