/*
This exports the current browser as a string, in case you need some browser
specific workaround that cannot be detected any other way. If possible, prefer
using CSS (`html[data-browser="ie"] & { ... }`) instead of JS since then the
server-side rendered HTML will look correct even before the JS has loaded (the
browser detection script is inlined – see `_document.js`).
*/
const browser =
  typeof document !== "undefined"
    ? document.documentElement.getAttribute("data-browser")
    : "unknown";

export default browser;
