import PropTypes from "prop-types";
import React, { useContext } from "react";

import { noop } from "#utils/";

import AppContext from "../AppContext";

Clickable.propTypes = {
  href: PropTypes.string,
  disabled: PropTypes.bool,
  // ...restProps
};

Clickable.defaultProps = {
  href: undefined,
  disabled: false,
};

// An unstyled component that automatically switches between `<a>` and
// `<button>` based on the presence of a `href` and automatically applies some
// nice attribute defaults. Used by `<StyledLink>` and `<Button>`.
export default function Clickable({ href, disabled, ...restProps }) {
  const { baseUrl = "" } = useContext(AppContext);

  return href != null ? (
    disabled ? (
      // Support `disabled` on links by removing the `href`. Also override
      // `onClick` to match `<button disabled>`. There might be more events to
      // override, but `onClick` will do at least for now.
      <a {...restProps} onClick={noop} />
    ) : isExternal(href, baseUrl) ? (
      // Automatically open external links in new tabs.
      <a href={href} target="_blank" rel="noopener noreferrer" {...restProps} />
    ) : (
      <a href={href} {...restProps} />
    )
  ) : (
    // `type` can be overridden via `restProps`.
    <button type="button" disabled={disabled} {...restProps} />
  );
}

function isExternal(href, baseUrl) {
  return /^https?:\/\//.test(href) && !href.startsWith(`${baseUrl}/`);
}
