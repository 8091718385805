import { ResizeObserver } from "@juggle/resize-observer";
import React from "react";

const useResizeObserver = () => {
  const [entry, setEntry] = React.useState({});
  const [node, setNode] = React.useState(null);
  const observer = React.useRef(null);

  const disconnect = React.useCallback(() => {
    const { current } = observer;
    if (current?.disconnect) {
      current.disconnect();
    }
  }, []);

  const observe = React.useCallback(() => {
    observer.current = new ResizeObserver(([o]) => setEntry(o));
    if (node) {
      observer.current.observe(node);
    }
  }, [node]);

  React.useEffect(() => {
    observe();
    return () => disconnect();
  }, [disconnect, observe]);

  return [setNode, entry];
};

export default useResizeObserver;
