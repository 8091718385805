import countAddonsAndPrice from "./countAddonsAndPrice";
import ticketsFromJourney from "./ticketsFromJourney";

export default function getAddonsCountAndPrice({ journeys }) {
  const [outbound, inbound] = journeys;

  return {
    outbound: outbound
      ? countAddonsAndPrice(ticketsFromJourney(outbound))
      : undefined,
    inbound: inbound
      ? countAddonsAndPrice(ticketsFromJourney(inbound))
      : undefined,
  };
}
