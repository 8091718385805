import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import styles from "./Text.module.css";

Text.propTypes = {
  variant: PropTypes.oneOf([
    "paragraph12",
    "paragraph14",
    "paragraph16",
    "paragraph18",
    "monospace14",
    "monospace16",
  ]),
  weight: PropTypes.oneOf(["regular", "medium", "semibold", "bold"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "disabled",
    "black",
    "white",
    "inherit",
  ]),
  center: PropTypes.bool,
  coloredLinks: PropTypes.bool,
  article: PropTypes.bool,
  limit: PropTypes.bool,
  html: PropTypes.string,
  children: PropTypes.node,
  tag: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
};

Text.defaultProps = {
  variant: "paragraph16",
  weight: "regular",
  color: undefined,
  center: false,
  coloredLinks: true,
  preamble: false,
  article: false,
  html: undefined,
  grey: false,
  black: false,
  limit: false,
  small: false,
  children: null,
  tag: undefined,
  className: undefined,
  whiteLinks: false,
};

export default function Text({
  center,
  preamble,
  article,
  variant,
  weight,
  color,
  coloredLinks,
  html,
  limit,
  tag,
  children,
  className,
  whiteLinks,
  ...restProps
}) {
  if (html != null && children != null) {
    throw new Error("<Text>: Provide either `html` OR `children`, not both.");
  }

  if (
    variant.startsWith("monospace") &&
    ["medium", "semibold"].includes(weight)
  ) {
    throw new Error(
      "Only `regular` and `bold` weights are available for monospace variants."
    );
  }

  if (variant.startsWith("paragraph") && weight === "bold") {
    throw new Error(
      "Only `regular`, `medium` and `semibold` weights are available for paragraph variants."
    );
  }

  const commonProps = {
    className: classnames(
      styles.root,
      {
        [styles.black]: color === "black",
        [styles.white]: color === "white",
        [styles.primary]: color === "primary",
        [styles.secondary]: color === "secondary",
        [styles.disabled]: color === "disabled",
        [styles.inherit]: color === "inherit",

        [styles.paragraph12]: variant === "paragraph12",
        [styles.paragraph14]: variant === "paragraph14",
        [styles.paragraph16]: variant === "paragraph16",
        [styles.paragraph18]: variant === "paragraph18",
        [styles.monospace14]: variant === "monospace14",
        [styles.monospace16]: variant === "monospace16",

        [styles.regular]: weight === "regular",
        [styles.medium]: weight === "medium",
        [styles.semibold]: weight === "semibold",
        [styles.bold]: weight === "bold",

        [styles.center]: center,
        [styles.coloredLinks]: coloredLinks,
        [styles.article]: article,
        [styles.limit]: limit,
        [styles.preamble]: preamble,
        [styles.whiteLinks]: whiteLinks,
      },
      className
    ),
    ...restProps,
  };
  const Tag = tag ? tag : article ? "article" : "div";

  if (html != null) {
    return <Tag {...commonProps} dangerouslySetInnerHTML={{ __html: html }} />;
  }

  return <Tag {...commonProps}>{children}</Tag>;
}
