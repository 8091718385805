import { toISODateString } from "#utils";

import { PASSENGER_PRICES_MAP } from "../consts";

const getLowestPrice = ({ date, daysInfo, counts }) => {
  const dayInfo = daysInfo[toISODateString(new Date(date))];
  const lowestPrice = dayInfo?.price_lowest;

  if (lowestPrice == null) {
    return null;
  }

  const countsList = Object.entries(counts).reduce(
    (array, [typeNum, typeCounts]) => {
      const entries = Array.from({ length: typeCounts }).map(() => typeNum);
      return [...array, ...entries];
    },
    []
  );

  const totalPrice = countsList.reduce((total, typeNum) => {
    const typeMultiplier = PASSENGER_PRICES_MAP[Number(typeNum)];
    const priceForType = lowestPrice * typeMultiplier;
    return total + Math.round(priceForType);
  }, 0);

  return totalPrice;
};

export default getLowestPrice;
