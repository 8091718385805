import React from "react";
import { Sentry } from "sentry";

import { useAppContext } from "#components/AppContext";
import topNotification from "#components/topNotification";
import { SESSION_EXPIRED_STATUS } from "#containers/Booking/consts";
import { UPDATE_ERROR, UPDATE_ERROR_NETWORK } from "#containers/Booking/nodes";

import { useBooking } from "..";
import useSessionFetchers from "./useSessionFetchers";

export default function useAddonsHandlers() {
  const { api } = useAppContext();
  const { session, setLoading, setSession, setPsp, setSessionError } =
    useBooking();
  const { getPsp } = useSessionFetchers();

  const sessionId = session.id;

  const onAddonsChange = async ({ add = [], remove = [] }) => {
    if (remove.length > 0 || add.length > 0) {
      try {
        setLoading(true);
        let newSession = undefined;

        if (remove.length) {
          ({ data: newSession } = await api.removeAddons({
            sessionId,
            addons: remove.map(({ meal }) => meal.id),
          }));
        }

        if (add.length) {
          ({ data: newSession } = await api.setAddons({
            sessionId,
            ticket_addons: add.reduce(
              (tickets, { meal, ticket }) => ({
                ...tickets,
                [ticket.id]: [
                  { id: meal.id, subtype: meal.subtype, type: meal.type },
                ],
              }),
              {}
            ),
          }));
        }

        const pspData = await getPsp(newSession.id);
        setPsp(pspData);
        setSession(newSession);
        // const freePSPEnabled = isFreePSPEnabled(availablePsps);
        setLoading(false);
      } catch (error) {
        console.error(
          "Booking: Failed to update seating",
          error,
          error.response
        );
        setLoading(false);

        const status = error?.response?.status;

        if (status === SESSION_EXPIRED_STATUS) {
          setSessionError({ status });
        } else if (status != null) {
          topNotification(React.cloneElement(UPDATE_ERROR, { status }));
        } else if (error.noResponse) {
          error.message = `Network error: ${error.message}`;
          topNotification(UPDATE_ERROR_NETWORK);
        } else {
          topNotification(React.cloneElement(UPDATE_ERROR, { status: 1500 }));
        }
        Sentry.captureException(error);
      }
    }
  };

  return {
    onAddonsChange,
  };
}
