import {
  BOOKING_CLASSES_WITH_CHANGE_FEE,
  CHANGE_FEE_PRICE,
  PASSENGER_TYPES_WITH_CHANGE_FEE,
} from "#containers/Booking/consts";
import { AddonsType, TicketStatus } from "#utils/enums";

const DEFAULT = {
  outbound: undefined,
  inbound: undefined,
};

export default function getReebokingDeparturesAddons(journey) {
  if (!journey) return DEFAULT;

  return journey.departures[0].tickets.reduce((acc, ticket) => {
    const shouldCount =
      ticket.status !== TicketStatus.CANCELED &&
      BOOKING_CLASSES_WITH_CHANGE_FEE.includes(ticket.booking_class) &&
      PASSENGER_TYPES_WITH_CHANGE_FEE.includes(ticket.passenger.type);

    if (
      !shouldCount ||
      journey.has_disruption ||
      journey.had_rebooked_disruption
    ) {
      return acc;
    }

    const { count: previousCount = 0, price: previousPrice = 0 } =
      acc.outbound?.[AddonsType.REBOOKING_FEE] || {};

    return {
      ...DEFAULT,
      outbound: {
        [AddonsType.REBOOKING_FEE]: {
          count: previousCount + 1,
          price: previousPrice + CHANGE_FEE_PRICE,
        },
      },
    };
  }, DEFAULT);
}
