import ReactDOM from "react-dom";

const ID = "renderBarebones";

// This function will render a modal without the need of
// returning the actual modal in a Component. It can be useful
// for taking in user input and resolving a promise based on that.
// It can just be used to render a modal though. An issue is that
// it cannot use components that uses context.

export function renderBarebones({ children }) {
  const element = document.createElement("div");
  element.id = ID;

  const close = () => {
    ReactDOM.unmountComponentAtNode(element);
    element.parentNode.removeChild(element);
  };

  removePrevious();
  ReactDOM.render(children, element);
  document.documentElement.appendChild(element);

  return { close };
}

function removePrevious() {
  /* eslint-disable-next-line */
  for (const previous of document.querySelectorAll(`#${ID}`)) {
    ReactDOM.unmountComponentAtNode(previous);
    previous.parentNode.removeChild(previous);
  }
}

// When hot reloading, there can be left-over elements from previous runs.
if (typeof document !== "undefined") {
  removePrevious();
}
