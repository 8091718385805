import { PassengerType } from "#utils/enums";

export default function countInfants(counts) {
  const numInfants = counts[PassengerType.INFANT];
  const numNonInfants = Object.entries(counts)
    .filter(([id]) => Number(id) !== PassengerType.INFANT)
    .reduce((sum, [, count2]) => sum + count2, 0);

  return [numInfants, numNonInfants];
}
