// TODO: Deprecate this function
export default function mergeRoutes(routes) {
  const stationsById = routes.reduce((acc, route) => {
    for (const s of route.stations) {
      if (acc[s.id] == null) {
        acc[s.id] = s;
      }
    }
    return acc;
  }, {});

  return Object.values(stationsById);
}
