import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Clickable from "#components/Clickable";

import styles from "./StyledLink.module.css";

// A simple wrapper around `<Clickable>` to returns an element that looks like a
// link (but can be either an `<a>` or a `<button>` under the hood).
const StyledLink = React.forwardRef(function StyledLink(
  { className, color, ...restProps },
  _
) {
  return (
    <Clickable
      {...restProps}
      className={classnames(
        styles.root,
        {
          [styles.primary]: color === "primary",
          [styles.secondary]: color === "secondary",
          [styles.textPrimary]: color === "textPrimary",
          [styles.white]: color === "white",
        },
        className
      )}
    />
  );
});

StyledLink.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["primary", "secondary", "textPrimary", "white"]),
  // ...restProps
};

StyledLink.defaultProps = {
  className: "",
  color: "secondary",
};

export default StyledLink;
