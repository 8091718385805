import { md, Node } from "djedi-react";
import React from "react";

export const ERRORS = {
  logout: {
    other: (
      <Node uri="account/error/logout/other" status="-1">
        Misslyckades att logga ut ([status])
      </Node>
    ),
    network: (
      <Node uri="account/error/logout/network">
        Misslyckades att logga ut. Kontrollera din Internet-anslutning!
      </Node>
    ),
  },
  pagination: {
    other: (
      <Node uri="account/error/pagination/other" status="-1">
        Något gick fel när vi försökte hämta sidan ([status])
      </Node>
    ),
    network: (
      <Node uri="account/error/pagination/network">
        Misslyckades att hämta data. Kontrollera din Internet-anslutning!
      </Node>
    ),
  },
};

export const GO_TO_STATISTICS = (
  <Node uri="account/manage/showStatistics">Visa statistik</Node>
);

export const SALES_REP = (
  <Node points="0" uri="account/manage/salesRep">
    Säljansvarig
  </Node>
);

export const CODE = (
  <Node points="0" uri="account/manage/code">
    Avtalsnummer
  </Node>
);

export const POINTS = (
  <Node points="0" uri="account/generic/points">
    [points] poäng
  </Node>
);

export const NO_BOOKINGS = (
  <Node uri="account/bookings/no-bookings">
    Du har inga aktuella bokningar.
  </Node>
);

export const TRAIN_NUMBER = (
  <Node uri="account/trip-details/trainNumber">Tågnummer</Node>
);

export const NR_OF_TICKETS = (
  <Node number="0" uri="account/trip-details/number">
    ([number] st)
  </Node>
);

export const TRANSITS = (
  <Node number="-1" uri="account/trip-details/transits">
    [number] byte
  </Node>
);

export const LOCATION = (
  <Node vehicle="" location="" uri="account/trip-details/location">
    [vehicle] Avgår från [location]
  </Node>
);

export const TRANSIT = <Node uri="account/trip-details/transit">1 byte</Node>;

export const TICKET_TYPE = (
  <Node uri="account/trip-details/ticketType">Biljettyp</Node>
);
export const BOOKING_REFERENCE = (
  <Node uri="account/trip-details/reference">Bokningsnummer</Node>
);

export const SETTINGS = <Node uri="account/settings">Inställningar</Node>;
export const ACCOUNT_MY_BENEFITS = (
  <Node uri="account/my-benefits">Mina förmåner</Node>
);
export const ACCOUNT_MY_BONUS = <Node uri="account/my-bonus">Min bonus</Node>;
export const LOGOUT = <Node uri="account/logout">Logga ut</Node>;

export const YOUR_POINTS = (
  <Node uri="account/my-membership/your-points/heading">Dina poäng</Node>
);

export const ALL_TRIPS = (
  <Node uri="account/my-membership/all-trips/title">Alla resor</Node>
);

export const SHOW_DETAILS = (
  <Node uri="account/my-membership/all-trips/detail">Visa detaljer</Node>
);

export const POINTS_UNTIL_NEXT_LEVEL = {
  RED: (
    <Node uri="clubx/points-to-next-level/red" pointsFrom="-1" pointsTo="-1">
      [pointsFrom] / [pointsTo] till Röd nivå
    </Node>
  ),
  BLACK: (
    <Node uri="clubx/points-to-next-level/black" pointsFrom="-1" pointsTo="-1">
      [pointsFrom] / [pointsTo] till Svart nivå
    </Node>
  ),
};
export const MY_BENEFITS = <Node uri="clubx/myBenefits">Mina förmåner</Node>;
export const HIGHEST_LEVEL = (
  <Node uri="clubx/black-level">
    Du har nått den högsta tillgängliga nivån.
  </Node>
);

export const MY_POINTS_PREFIX = (
  <Node uri="clubx/your-progress-prefix">Du har samlat in</Node>
);

export const MY_POINTS_SUFFIX = (
  <Node uri="clubx/your-progress-suffic">ClubX-poäng</Node>
);

export const THIS_TRIP_PREFIX = (
  <Node uri="clubx/this-trip-prefix">Denna resa ger dig</Node>
);

export const THIS_TRIP_SUFFIX = (
  <Node uri="clubx/this-trip-suffic">ClubX-poäng</Node>
);

export const USE_BEFORE = (
  <Node uri="clubx/use-before">kvar att nyttja fram till 31 dec 2024</Node>
);

export const NOTICE = (
  <Node uri="account/my-membership/notice.md">Medlemsinformation</Node>
);

export const BONUS_HISTORY = (
  <Node uri="account/my-bonus/history">Bonushistorik</Node>
);

export const BONUS_DETAILS = (
  <Node uri="account/my-bonus/details">Bonusdetaljer</Node>
);

export const BONUS_TO_USE = (
  <Node uri="account/my-bonus/to-use">Att använda:</Node>
);

export const BONUS_EXPIRE_TO_USE = (
  <Node uri="account/my-bonus/expire">Förfaller:</Node>
);

export const BONUS_LABEL_EARNED = (
  <Node uri="account/my-bonus/label-earned">Intjänad bonus</Node>
);

export const BONUS_LABEL_SPENT = (
  <Node uri="account/my-bonus/label-spent">Använd bonus</Node>
);

export const TIER_PERKS = {
  WHITE: {
    level: (
      <Node uri="account/my-membership/current-level-title/white">
        Vit nivå
      </Node>
    ),
    perks: (
      <Node uri="account/my-membership/current-level-perks/white.md">{md`
        - Gratis kaffe och te ombord oavsett vilket biljettyp.
        - Alltid 10% rabatt i vårt café ombord. Gäller ej alkohol.
        - Gratis sätesval även när du reser med FIX-biljett.
        - 25% extra poäng (totalt 1,25 sek per spenderad tiokrona).
      `}</Node>
    ),
  },
  RED: {
    level: (
      <Node uri="account/my-membership/current-level-title/red">Röd nivå</Node>
    ),
    perks: (
      <Node uri="account/my-membership/current-level-perks/red.md">{md`
        - Gratis kaffe och te ombord oavsett vilket biljettyp.
        - Alltid 10% rabatt i vårt café ombord. Gäller ej alkohol.
        - Gratis sätesval även när du reser med FIX-biljett.
        - 25% extra poäng (totalt 1,25 sek per spenderad tiokrona).
      `}</Node>
    ),
  },
  BLACK: {
    level: (
      <Node uri="account/my-membership/current-level-title/black">
        Svart nivå
      </Node>
    ),
    perks: (
      <Node uri="account/my-membership/current-level-perks/black.md">{md`
        - Gratis kaffe och te ombord oavsett vilket biljettyp.
        - Alltid 10% rabatt i vårt café ombord. Gäller ej alkohol.
        - Gratis sätesval även när du reser med FIX-biljett.
        - 25% extra poäng (totalt 1,25 sek per spenderad tiokrona).
      `}</Node>
    ),
  },
};

export const BENEFIT_EXPIRES = (
  <Node date="" uri="account/my-membership/benefit/expires">
    Giltigt t.o.m. [date]
  </Node>
);

export const BENEFIT_PERSONAL_TITLE = (
  <Node uri="account/my-membership/benefit/title/personal-benefits">
    Personliga erbjudanden
  </Node>
);

export const BENEFIT_PERSONAL_DESCRIPTION = (
  <Node uri="account/my-membership/benefit/description/personal-benefits">
    Du får löpande ta del av personliga erbjudanden som skickas till dig via
    e-post
  </Node>
);

export const BENEFIT_COFFEE_TITLE = (
  <Node uri="account/my-membership/benefit/title/coffee">
    Gratis bryggkaffe eller te
  </Node>
);

export const BENEFIT_COFFEE_DESCRIPTION = (
  <Node uri="account/my-membership/benefit/description/coffee">
    Få en gratis bryggkaffee eller te genom att visa upp denna förmån i
    ceféterian ombord
  </Node>
);

export const BENEFIT_MEAL_MEDIUM_TITLE = (
  <Node uri="account/my-membership/benefit/title/meal-medium">
    10% caférabatt
  </Node>
);

export const BENEFIT_MEAL_MEDIUM_DESCRIPTION = (
  <Node uri="account/my-membership/benefit/description/meal-medium">
    Få 10% caférabatt genom att visa upp denna förmån i caféterian ombord
  </Node>
);

export const BENEFIT_MEAL_HIGH_TITLE = (
  <Node uri="account/my-membership/benefit/title/meal-high">
    20% caférabatt
  </Node>
);

export const BENEFIT_MEAL_HIGH_DESCRIPTION = (
  <Node uri="account/my-membership/benefit/description/meal-high">
    Få 20% caférabatt genom att visa upp denna förmån i caféterian ombord
  </Node>
);

export const BENEFIT_BONUS_TITLE = (
  <Node uri="account/my-membership/benefit/title/bonus">10% bonus</Node>
);

export const BENEFIT_BONUS_DESCRIPTION = (
  <Node uri="account/my-membership/benefit/description/bonus">
    Värdet av en bonus är 1 krona och du får 10% bonus i återbäring
  </Node>
);

export const BENEFIT_NSF_TITLE = (
  <Node uri="account/my-membership/benefit/title/nsf">Ledigt säte bredvid</Node>
);

export const BENEFIT_NSF_DESCRIPTION = (
  <Node uri="account/my-membership/benefit/description/nsf">
    Du får automatiskt ett ledigt säte bredvid när du bokar en resa
  </Node>
);

export const BENEFIT_PURCHASED_TITLE = (
  <Node uri="account/my-membership/benefit/status/title">
    Du har hittills köpt resor för
  </Node>
);

export const BENEFIT_PURCHASED_EXTRAS_BASIC = (
  <Node uri="account/my-membership/benefit/status/extras-basic">
    Fortsätt res med MTRX för att samla in mer bonus
  </Node>
);

export const BENEFIT_PURCHASED_EXTRAS_MEDIUM = (
  <Node uri="account/my-membership/benefit/status/extras-medium">
    Fortsätt res med MTRX för att samla in mer bonus
  </Node>
);

export const BENEFIT_PURCHASED_EXTRAS_HIGH = (
  <Node uri="account/my-membership/benefit/status/extras-high">
    Fortsätt res med MTRX för att samla in mer bonus
  </Node>
);

export const BENEFIT_PURCHASED_INFO = (
  <Node uri="account/my-membership/benefit/status/info.md">
    [**Här**](/kundservice) finner du de vanligaste frågorna och svaren om
    förmåner, bonus och Mitt MTRX.
  </Node>
);

export const BENEFIT_NEXT_TITLE_BASIC = (
  <Node uri="account/my-membership/benefit/next/title-basic">
    När du når 5 000 kr låser du upp nedan förmåner
  </Node>
);

export const BENEFIT_NEXT_TITLE_MEDIUM = (
  <Node uri="account/my-membership/benefit/next/title-medium">
    När du når 15 000 kr låser du upp nedan förmåner
  </Node>
);

export const TITLE = (
  <Node uri="account/my-membership/title">Mitt medlemskap</Node>
);

export const NO_HISTORY = (
  <Node uri="account/transaction-history/no-history">
    Det finns ingen historik.
  </Node>
);

export const NO_NEW_BONUS = (
  <Node uri="account/transaction-history/not-collecting.md">
    För närvarande samlar du ingen bonus. [**Här**](/kundservice) finner du de
    vanligaste frågorna och svaren om förmåner, bonus och Mitt MTRX.
  </Node>
);

export const HISTORY = (
  <Node uri="account/transaction-history/title">Poänghistorik</Node>
);

export const READ_MORE = (
  <Node uri="account/my-membership/your-points/button">Läs mer om ClubX</Node>
);

export const MEMBERSHIP_NUMBER = (
  <Node uri="account/my-membership/number">Medlemsnummer</Node>
);

export const CORPORATE_NUMBER = (
  <Node uri="account/my-corporate/number">Avtalsnummer</Node>
);

export const TITLE_HOME = <Node uri="account/title">Mitt konto</Node>;
export const MY_CORPORATE = <Node uri="account/myCorporate">Mina avtal</Node>;
export const MY_CORPORATE_LINK = (
  <Node uri="account/myCorporateLink">Se avtal</Node>
);
export const MY_MEMBERSHIP = (
  <Node uri="account/myMembership">Mina förmåner</Node>
);
export const MY_MEMBERSHIP_LINK = (
  <Node uri="account/myMembershipLink">Se förmåner</Node>
);
export const MY_BONUS = <Node uri="account/myBonus">Min bonus</Node>;
export const MY_BONUS_LINK = <Node uri="account/myBonusLink">Se bonus</Node>;
export const SETTINGS_LINK = (
  <Node uri="account/settingsLink">Inställningar</Node>
);
export const ALL_TRIPS_LINK = <Node uri="account/tripsLink">Alla resor</Node>;
export const NEXT_TRIP = <Node uri="account/nextTrip">Nästa resa</Node>;
export const WELCOME = (
  <Node name="" uri="account/welcome">
    Hej [name]!
  </Node>
);
export const NO_UPCOMING_BOOKBUTTON = (
  <Node uri="account/bookATrip">Boka en resa</Node>
);

export const NO_UPCOMING = (
  <Node uri="account/no_upcoming">Du har inga kommande resor</Node>
);
