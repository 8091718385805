import React from "react";

import { useBooking } from "../BookingContext";
import {
  useDefaultPaymentProvider,
  useSessionHandlers,
} from "../BookingContext/helpers";
import { Steps } from "../consts";
import SessionError from "../partials/SessionError";
import SessionTimer from "../partials/SessionTimer";

export default function withSessionCommons(WrappedComponent) {
  const WithSessionCommons = (props) => {
    const {
      sessionError,
      timesNearlyUp,
      session,
      setSteps,
      isFreePayment,
      setShow,
      steps,
    } = useBooking();
    const { startTimers } = useSessionHandlers();
    useDefaultPaymentProvider();
    const lastIsFreePayment = React.useRef(isFreePayment);

    const passengersHaveDetails = session?.passengers.every(
      ({ firstname, lastname }) => firstname && lastname
    );

    const addStep = React.useCallback(
      (step) => {
        const newSteps = [...steps, step].sort();
        setSteps([...new Set(newSteps)]);
      },
      [setSteps, steps]
    );

    const removeStep = React.useCallback(
      (step) => {
        setSteps(Object.values(steps).filter((stepNum) => stepNum !== step));
      },
      [setSteps, steps]
    );

    React.useEffect(() => {
      startTimers();
    }, [startTimers]);

    React.useEffect(() => {
      if (lastIsFreePayment.current !== isFreePayment) {
        addStep(Steps.PAYMENT);
      }

      lastIsFreePayment.current = isFreePayment;
    }, [isFreePayment, addStep, removeStep]);

    // Users can potentially start a session and manually
    // change the URL to bypass adding their passenger
    // details. If that is the case, redirect back to
    // the passengers step.
    React.useEffect(() => {
      if (session && !passengersHaveDetails) {
        setShow(Steps.PASSENGERS);
      }
    }, [passengersHaveDetails, session, setShow]);

    const errorStatus = sessionError?.status;

    return errorStatus ? (
      <SessionError errorStatus={errorStatus} />
    ) : (
      <>
        <WrappedComponent {...props} />
        {timesNearlyUp && session && <SessionTimer />}
      </>
    );
  };

  return WithSessionCommons;
}
