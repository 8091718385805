import { useEffect, useMemo } from "react";

// Test via a getter in the options object to see if the passive property is accessed
const canUsePassive = () => {
  if (typeof window === "undefined") {
    return false;
  }

  if (window.supportsPassiveEventListeners !== undefined) {
    return window.supportsPassiveEventListeners;
  }
  let supportsPassive = false;

  try {
    const opts = Object.defineProperty({}, "passive", {
      get() {
        supportsPassive = true;
        return null;
      },
    });
    window.addEventListener("test", null, opts);
  } catch (e) {
    console.error(e);
  }

  window.supportsPassive = supportsPassive;
  return supportsPassive;
};

const PASSIVE_OK = [
  "mousewheel",
  "wheel",
  "scroll",
  "touchstart",
  "touchcancel",
  "touchmove",
  "touchend",
];

const canBePassive = (eventName) => PASSIVE_OK.includes(eventName);

const useEventListener = (eventName, callback, passive = false) => {
  const allowPassive = canBePassive(eventName) || passive;
  const passiveSupported = useMemo(canUsePassive, []);
  useEffect(
    () => {
      window.addEventListener(eventName, callback);
      return () => window.removeEventListener(eventName, callback);
    },
    [callback, eventName],
    passiveSupported
      ? {
          passive: allowPassive,
        }
      : false
  );
};
export default useEventListener;
