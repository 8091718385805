import React from "react";

import { useAppContext } from "#components/AppContext";

import { useBooking } from "..";
import useSessionFetchers from "./useSessionFetchers";

export default function useSessionData({
  routes: fetchRoutes = true,
  session: fetchSession = true,
  seating: fetchSeating = false,
  addons: fetchAddons = false,
  psp: fetchPsp = false,
} = {}) {
  const {
    addons,
    psp,
    routes,
    seating,
    session,
    setAddons,
    setPsp,
    setRoutes,
    setSeating,
    setSession,
  } = useBooking();
  const { user } = useAppContext();
  const { getRoutes, getSession, getSeating, getAddons, getPsp } =
    useSessionFetchers();
  const hasFetched = React.useRef(false);

  React.useEffect(() => {
    if (!hasFetched.current) {
      (async () => {
        hasFetched.current = true;

        let sessionData = session;
        if (!sessionData && fetchSession) {
          sessionData = await getSession();
        }

        if (sessionData) {
          const [routesData, seatingData, addonsData, pspData] =
            await Promise.all([
              !routes && fetchRoutes ? getRoutes() : undefined,
              !seating && fetchSeating ? getSeating(sessionData) : undefined,
              !addons && fetchAddons ? getAddons(sessionData) : undefined,
              !psp && fetchPsp ? getPsp(sessionData.id) : undefined,
            ]);

          setSession(sessionData);

          if (routesData) {
            setRoutes(routesData);
          }

          if (seatingData) {
            setSeating(seatingData);
          }

          if (addonsData) {
            setAddons(addonsData);
          }

          if (pspData) {
            setPsp(pspData);
          }
        }
      })();
    }
  }, [
    addons,
    fetchAddons,
    fetchPsp,
    fetchRoutes,
    fetchSeating,
    fetchSession,
    getAddons,
    getPsp,
    getRoutes,
    getSeating,
    getSession,
    psp,
    routes,
    seating,
    session,
    setAddons,
    setPsp,
    setRoutes,
    setSeating,
    setSession,
    user,
  ]);
}
