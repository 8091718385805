import { FROM_TO } from "../consts";

export default function getFromTo({ voucher, stopsFrom, stopsTo }) {
  if (voucher == null) {
    return {
      from: stopsFrom.find(({ id }) => id === FROM_TO.from),
      to: stopsTo.find(({ id }) => id === FROM_TO.to),
    };
  }

  const fromStops =
    voucher.from_stops.length > 0 ? voucher.from_stops : stopsFrom;
  const toStops = voucher.to_stops.length > 0 ? voucher.to_stops : stopsTo;

  return {
    from: stopsFrom.find(
      ({ id }) => id === fromStops[0] || id === FROM_TO.from
    ),
    to: stopsTo.find(
      ({ id }) => id === toStops[toStops.length - 1] || id === FROM_TO.to
    ),
  };
}
