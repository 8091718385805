import classnames from "classnames";
import { ForceNodes, Node } from "djedi-react";
import PropTypes from "prop-types";
import React from "react";

import { useAppContext } from "#components/AppContext";
import Button from "#components/Button";
import StdSelect from "#components/Select";
import WithWindowSize from "#components/WithWindowSize";
import { STATIONS } from "#containers/Booking/consts";
import SwapIcon from "#icons/new/specific-search-directions-h@24x24px-01.svg";
import { TrackingMetric } from "#utils/enums";

import styles from "./SearchTrip.module.css";
// These are hard coded at least for now. Maybe we want to fetch these rules
// from the API in the future.
export const PREFERRED_DEFAULT_TRIP = {
  from: STATIONS["Stockholm C"],
  to: STATIONS["Göteborg C"],
};

SearchTrip.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  stopsFrom: PropTypes.arrayOf(PropTypes.object.isRequired),
  stopsTo: PropTypes.arrayOf(PropTypes.object.isRequired),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func,
  disableSwitch: PropTypes.bool,
};

SearchTrip.defaultProps = {
  stopsFrom: [],
  stopsTo: [],
  disabled: false,
  onSearch: undefined,
  disableSwitch: false,
};

const Select = ({ label, className, ...props }) => {
  return (
    <label className={classnames(styles.select, className)}>
      <span className={styles.selectLabel}>{label}</span>
      <StdSelect className={styles.selectInput} {...props} />
    </label>
  );
};

Select.propTypes = {
  label: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Select.defaultProps = {
  className: undefined,
};

const NODES = {
  ACCESSIBLE_SWAP_NAME: (
    <Node uri="SearchTrip/accesibleSwapAria">Ändra riktning</Node>
  ),
};

export default function SearchTrip({
  from: fromId,
  to: toId,
  stopsFrom,
  stopsTo,
  disabled: passedDisabled,
  leftDisabled,
  rightDisabled,
  onChange,
  onSearch,
  disableSwitch,
}) {
  const { api } = useAppContext();
  const [forbiddenRoutes, setForbiddenRoutes] = React.useState();

  const disabled = passedDisabled || !forbiddenRoutes;

  React.useEffect(() => {
    api.getForbiddenRoutes().then(({ data }) => {
      setForbiddenRoutes(data);
    });
  }, [api, setForbiddenRoutes]);

  const from = stopsFrom.find(
    ({ id, remappedFromId }) => remappedFromId === fromId || id === fromId
  );
  const to = stopsTo.find(
    ({ id, remappedFromId }) => remappedFromId === toId || id === toId
  );

  const swapped = {
    from: to,
    to: from,
  };

  return (
    <div
      className={classnames(styles.root, {
        [styles.noButton]: onSearch == null,
      })}
    >
      <div className={styles.selectWrapper}>
        {!disableSwitch &&
          React.cloneElement(NODES.ACCESSIBLE_SWAP_NAME, {
            render: function render(state) {
              return (
                <button
                  type="button"
                  className={styles.swapButton}
                  aria-label={
                    state.type === "success" ? state.content.props.children : ""
                  }
                  disabled={disabled || isForbidden(swapped, forbiddenRoutes)}
                  onClick={() => {
                    onChange(swapped);
                  }}
                >
                  <SwapIcon />
                </button>
              );
            },
          })}

        <Select
          label={<Node uri="SearchTrip/from">Från</Node>}
          value={from.id}
          disabled={leftDisabled || disabled || stopsFrom.length === 0}
          onChange={(event) => {
            const newFrom = event.target.value;

            onChange({ from: stopsFrom.find(({ id }) => id === newFrom), to });
          }}
        >
          {stopsFrom.map((stop) => (
            <option
              key={stop.id}
              value={stop.id}
              disabled={isForbidden(
                { from: stop.id, to: to.id },
                forbiddenRoutes
              )}
            >
              {stop.name}
            </option>
          ))}
        </Select>

        <Select
          className={styles.selectRight}
          label={<Node uri="SearchTrip/to">Till</Node>}
          value={to.id}
          disabled={rightDisabled || disabled || stopsTo.length === 0}
          onChange={(event) => {
            const newTo = event.target.value;
            onChange({ from, to: stopsTo.find(({ id }) => id === newTo) });
          }}
        >
          {stopsTo.map((stop) => (
            <option
              key={stop.id}
              value={stop.id}
              disabled={isForbidden(
                { from: from.id, to: stop.id },
                forbiddenRoutes
              )}
            >
              {stop.name}
            </option>
          ))}
        </Select>
      </div>
      <div
        className={classnames(styles.buttonWrapper, {
          [styles.hideButton]: onSearch == null,
        })}
      >
        <div>
          {onSearch != null && (
            <WithWindowSize>
              {({ xsDown }) => (
                <Button
                  block={xsDown}
                  big={xsDown}
                  className={styles.searchButton}
                  disabled={disabled}
                  id="gtm-searchTrip"
                  data-cy="search-trip"
                  onClick={() => {
                    // Track event for Mixpanel
                    api.trackEvent({
                      metric: TrackingMetric.BOOKING_ROUTE_SEARCHED,
                      data: {
                        origin: from.name,
                        origin_national_id: from.national_id,
                        origin_id: from.id,
                        destination: to.name,
                        destination_national_id: to.national_id,
                        destination_id: to.id,
                      },
                    });
                    onSearch({ from, to });
                  }}
                >
                  <Node uri="SearchTrip/search">Sök resa</Node>
                </Button>
              )}
            </WithWindowSize>
          )}
        </div>
      </div>
      <ForceNodes>{NODES}</ForceNodes>
    </div>
  );
}

export function isValidTrip({ from, to, stopsFrom, stopsTo }) {
  return (
    stopsFrom.some(({ id }) => id === from) &&
    stopsTo.some(({ id }) => id === to) &&
    from !== to &&
    !isForbidden({ from, to })
  );
}

function isForbidden({ from, to }, forbidden = []) {
  return forbidden.some((trip) => trip.from === from && trip.to === to);
}

// Returns a `{ from, to }` pair for the given stops. Prefers
// `PREFERRED_DEFAULT_TRIP`, and then `from` to come earlier in `stopsFrom`, and
// `to` to be far away from `from`.
export function getDefaultFromTo(stopsFrom, stopsTo) {
  return {
    from: stopsFrom.find(({ id }) => id === PREFERRED_DEFAULT_TRIP.from),
    to: stopsTo.find(({ id }) => id === PREFERRED_DEFAULT_TRIP.to),
  };
}
