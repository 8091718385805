import { addDays, max as getMaxDate, min as getMinDate } from "date-fns";

import { toISODateString } from "#utils/";

import { INFINITE_VOUCHER, WEEKDAYS } from "../consts";
import getDefaultDate from "./getDefaultDate";
import getInboundOutboundDate from "./getInboundOutboundDate";

export default function validateSelectedDates({
  voucher = undefined,
  dateString,
  dateBackString = undefined,
}) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const tenYearsFromNow = new Date();
  tenYearsFromNow.setFullYear(tenYearsFromNow.getFullYear() + INFINITE_VOUCHER);

  const maxDate =
    voucher == null
      ? undefined
      : new Date(voucher.travel_end_date || tenYearsFromNow);
  const minDate =
    voucher == null
      ? today
      : // Make sure that today + min_days_before_departure doesn’t end up
        // after the max date.
        getMinDate([
          maxDate,
          getMaxDate([
            addDays(today, voucher.min_days_before_departure),
            new Date(voucher.travel_start_date),
          ]),
        ]);

  const weekdays =
    voucher != null && voucher.weekdays.length > 0
      ? voucher.weekdays
      : WEEKDAYS;

  const maybeOutboundDate = getInboundOutboundDate(
    dateString || toISODateString(today),
    minDate,
    maxDate,
    weekdays
  );

  const outboundDate =
    maybeOutboundDate || getDefaultDate(minDate, maxDate, weekdays, dateString);
  const maybeInboundDate = getInboundOutboundDate(
    dateBackString || toISODateString(today),
    outboundDate,
    maxDate,
    weekdays
  );

  const inboundDate = maybeInboundDate || outboundDate;

  return {
    outboundDate,
    inboundDate,
    minDate,
    maxDate,
  };
}
