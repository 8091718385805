export default function orderStations(stations, routes) {
  const baseRoute = routes[0].stations;
  // Reverse baseRoute so that Solna/Stockholm will be first
  const orderedRouteIds = [...baseRoute.reverse().map(({ id }) => id)];

  routes.forEach((route) => {
    route.stations.forEach((station) => {
      const added = orderedRouteIds.includes(station.id);

      if (!added) {
        orderedRouteIds.push(station.id);
      }
    });
  });

  const orderedStations = [...stations].sort(
    (a, b) => orderedRouteIds.indexOf(a.id) - orderedRouteIds.indexOf(b.id)
  );

  return orderedStations;
}
