import { Node } from "djedi-react";
import React from "react";

export const HEADING_PICK_SEAT = (
  <Node uri="booking/session/heading/addons">Dina reseval</Node>
);
export const EXTRAS_IMAGE = (
  <Node uri="booking/session/extras/extras-image.img" />
);
// I have a suspicion that they might want this back at some point. Leaving it here and in the addon commented for now.
// eslint-disable-next-line no-unused-vars
export const EXTRAS_BADGE = (
  <Node uri="booking/session/extras/extras-badge">
    Pontus Frithof står bakom vår meny
  </Node>
);
