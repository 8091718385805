export default function getStops(routesMap, stations, defaultStops) {
  const stationIds = new Set(stations);
  const stops = Object.entries(routesMap)
    .filter(([, id]) => stationIds.has(id))
    .map((entry) => {
      const [name, national_id, id] = entry;
      const { is_searchable } = defaultStops.find((stop) => stop.id === id);

      return { name, id, national_id, is_searchable };
    });

  return stops.length > 0 ? stops : defaultStops;
}
