import NextLink from "next/link";
import PropTypes from "prop-types";
import React from "react";

import mtrLogo from "#images/mtr.png";
import { parseDjediLinks } from "#utils";

import Clickable from "../../../Clickable";
import styles from "./LinkItems.module.css";

function LinkItems({ links, className }) {
  return React.cloneElement(links, {
    edit: false,
    render: (state) => {
      if (state.type !== "success") {
        return null;
      }

      return parseDjediLinks(state.content).map((item, index) => {
        return (
          <li key={index}>
            {typeof item.url === "string" ? (
              <Clickable href={item.url} className={className}>
                {item.text.toLowerCase() === "mtr nordic" && (
                  <img src={mtrLogo} alt="" className={styles.mtrLogo} />
                )}
                {item.text}
              </Clickable>
            ) : (
              <NextLink {...item.url}>
                <a className={className}>{item.text}</a>
              </NextLink>
            )}
          </li>
        );
      });
    },
  });
}

LinkItems.propTypes = {
  links: PropTypes.element.isRequired,
  className: PropTypes.string.isRequired,
};

export default LinkItems;
