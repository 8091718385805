import PropTypes from "prop-types";
import React from "react";

import Button from "#components/Button";
import { useBooking } from "#containers/Booking/BookingContext";
import useSessionHandlers from "#containers/Booking/BookingContext/helpers/useSessionHandlers";

import { EXPIRED_BACK, EXPIRED_MESSAGES, EXPIRED_RESTART } from "./nodes";
import styles from "./SessionError.module.css";

export default function SessionError({ errorStatus }) {
  const { expiredSession, loading } = useBooking();
  const { resetSession, restartSession } = useSessionHandlers();
  const message = EXPIRED_MESSAGES[errorStatus] || EXPIRED_MESSAGES.default;

  return (
    <div className={styles.root}>
      <div className={styles.text}>
        {message} ({errorStatus})
      </div>
      <div className={styles.buttons}>
        {expiredSession != null && (
          <Button
            disabled={loading}
            onClick={() => {
              restartSession(expiredSession);
            }}
          >
            {EXPIRED_RESTART}
          </Button>
        )}

        <Button
          disabled={loading}
          onClick={() => {
            resetSession();
          }}
        >
          {EXPIRED_BACK}
        </Button>
      </div>
    </div>
  );
}

SessionError.propTypes = {
  errorStatus: PropTypes.number.isRequired,
};
