/*

Booking related utilites are usually kept in
`pages-helpers/booking/utils.js`. These ones have been extracted from there
so that we can clear all booking session data when logging out, without
having to import the entire `pages-helpers/booking/utils.js` file.

*/

import cookies from "js-cookie";

import { VOUCHER_UPGRADE_COOKIE_NAME } from "#containers/Booking/consts";

import localStorage from "./localStorage";

export const VOUCHER_COOKIE_NAME = "voucher";

// Note: IE11 supports at most 50 cookies per domain.
export const MAX_NUM_SESSION_COOKIES = 10;

export function getBookingSessionCookieName() {
  return `bookingsession`;
}

export function passengerFormLocalStorageKey() {
  return `PassengerForm`;
}

export function deleteCurrentBookingSessionData() {
  cookies.remove(getBookingSessionCookieName());
}

export function deleteAllBookingSessionData() {
  cookies.remove(getBookingSessionCookieName());
  localStorage.removeItem(passengerFormLocalStorageKey());
  cookies.remove(VOUCHER_COOKIE_NAME);
  cookies.remove(VOUCHER_UPGRADE_COOKIE_NAME);
}
