import { PASSENGER_TYPES } from "../consts";

const orderMap = PASSENGER_TYPES.reduce((result, type, index) => {
  result[type.id] = index;
  return result;
}, {});

// Sorts a list of passengers by their type, so the types end up in the same
// order as in `PASSENGER_TYPES`.
export default function sortPassengers(passengers) {
  return passengers.slice().sort((a, b) => {
    const {
      [a.passenger_type]: aWeight = PASSENGER_TYPES.length,
      [b.passenger_type]: bWeight = PASSENGER_TYPES.length,
    } = orderMap;
    return aWeight - bWeight;
  });
}
