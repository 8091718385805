import classnames from "classnames";
import Link from "next/link";
import PropTypes from "prop-types";
import React from "react";
import { ROUTES } from "routes";

import Burger from "#components/Burger";
import NavBar from "#components/NavBar";
import VrLogo from "#icons/vr.svg";

import styles from "./Drawer.module.css";

const lockWindowScroll = (open) =>
  `body {
    overflow: ${open ? "hidden" : "auto"};
  }`;

const Drawer = ({ children, direction, open, heading, onRequestClose }) => {
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);
  return (
    <>
      <div
        className={classnames(styles.root, {
          [styles.left]: direction === "left",
          [styles.right]: direction === "right",
          [styles.top]: direction === "top",
          [styles.bottom]: direction === "bottom",
          [styles.open]: open,
          [styles.animated]: isMounted, // prevent initial flash on mount
        })}
      >
        <NavBar>
          <>
            <div className={styles.headingWrapper}>
              {heading || (
                <Link href={ROUTES.home.pathname}>
                  <a alt="MTRX" aria-label="MTRX">
                    <VrLogo
                      onClick={() => onRequestClose()}
                      className={styles.svg}
                    />
                  </a>
                </Link>
              )}
            </div>
            <button
              type="button"
              aria-label="close menu"
              onClick={() => onRequestClose()}
            >
              {/** always display cross */}
              <Burger cross={true} />
            </button>
          </>
        </NavBar>
        {children}
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: lockWindowScroll(open),
        }}
      />
    </>
  );
};

Drawer.propTypes = {
  open: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(["right", "left", "bottom", "top"]),
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

Drawer.defaultProps = {
  direction: "left",
  open: false,
  heading: undefined,
};

export default Drawer;
