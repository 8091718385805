import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import Check from "#icons/check.svg";

import styles from "./LabeledField.module.css";

LabeledField.propTypes = {
  input: PropTypes.object.isRequired, // From react-final-form.
  meta: PropTypes.object.isRequired, // From react-final-form.
  label: PropTypes.node,
  warning: PropTypes.node,
  widget: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.func.isRequired,
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    // Passing a djedi `<Node>` as placeholder is supported.
    PropTypes.element.isRequired,
  ]),
  className: PropTypes.string,
  noSetHeight: PropTypes.bool,
  noCheck: PropTypes.bool,
  // ...restProps passed to widget
};

LabeledField.defaultProps = {
  label: undefined,
  warning: undefined,
  widget: "input",
  placeholder: undefined,
  className: "",
  noSetHeight: false,
  noCheck: false,
};

export default function LabeledField({
  input,
  meta,
  label,
  widget: Widget,
  placeholder,
  warning,
  className,
  noSetHeight,
  noCheck,
  ...restProps
}) {
  const { submitError: submitErrors = [] } = meta;

  const hasError =
    (meta.error != null || submitErrors.length > 0) && meta.touched;
  const hasNoError = meta.error == null && input.value !== "";

  const widget = (
    <Widget
      placeholder={placeholder}
      className={classnames(className, styles.input)}
      aria-invalid={hasError}
      {...input}
      // Workaround for some issue with react-final-form where React warns about
      // switching between controlled and uncontrolled for an input when erasing
      // the last character of a field with `{...trimOnBlur}` (from
      // `utils/forms.js`).
      // https://github.com/final-form/react-final-form/issues/529
      value={input.value || ""}
      {...restProps}
    />
  );

  return (
    <div className={styles.root}>
      {label && (
        <label className={styles.label}>
          <span>{label}</span>
        </label>
      )}
      <div
        className={classnames(styles.field, {
          [styles.hasError]: hasError,
          [styles.hasWarning]: !hasError && warning,
          [styles.hasFocus]: meta.active && !restProps.readOnly && !hasError,
          [styles.noLabel]: !label,
          [styles.noSetHeight]: noSetHeight,
        })}
      >
        {placeholder != null && typeof placeholder !== "string"
          ? React.cloneElement(placeholder, {
              edit: false,
              render: (state) =>
                state.type === "success"
                  ? React.cloneElement(widget, { placeholder: state.content })
                  : null,
            })
          : widget}

        {hasNoError && !noCheck && <Check className={styles.noError} />}
      </div>
      {hasError && (
        <MaybeDjediStringOrArray value={submitErrors}>
          {(submitError) => (
            <span className={styles.error}>
              {label} - {meta.error || submitError}
            </span>
          )}
        </MaybeDjediStringOrArray>
      )}
      {!hasError && warning && (
        <span className={styles.warning}>{warning}</span>
      )}
    </div>
  );
}

LabeledFieldGrid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

LabeledFieldGrid.defaultProps = {
  className: "",
};

export function LabeledFieldGrid({ children, className, ...restProps }) {
  return (
    <div className={classnames(styles.grid, className)} {...restProps}>
      {flattenReactChildren(children).map((child, index) => (
        <div key={index} className={styles.fieldWrapper}>
          {child}
        </div>
      ))}
    </div>
  );
}

function flattenReactChildren(children) {
  return children.reduce(
    (array, child) =>
      child.props != null &&
      child.props.children != null &&
      child.props.children.length > 0
        ? array.concat([...flattenReactChildren(child.props.children)])
        : array.concat(child),
    []
  );
}

export function MaybeDjediStringOrArray({ value, children }) {
  if (Array.isArray(value)) {
    return children(
      value.map((v, index) => {
        if (typeof v === "object") {
          if (v.type === Node) {
            return React.cloneElement(v, {
              key: index,
              edit: false,
              render: function render(state) {
                return state.type === "success" ? <>{state.content} </> : null;
              },
            });
          }

          return v.detail;
        }

        if (typeof v === "string") {
          return `${v} `;
        }
      })
    );
  }

  if (typeof value === "string") {
    return children(value);
  }

  if (value.type === Node) {
    return React.cloneElement(value, {
      edit: false,
      render: (state) =>
        state.type === "success" ? children(state.content) : null,
    });
  }

  throw new Error(
    `Invalid <SubmitError>. Expected a string or a djedi <Node> but got: ${value}`
  );
}
