import { BookingClass, getValue } from "#utils/enums";

import { EMPTY_JOURNEY } from "../consts";

export default function getJourneyFromInitialData(
  journeys,
  passedTime,
  passedBookingClass
) {
  if (journeys == null) {
    return EMPTY_JOURNEY;
  }

  // Convert back "." to ":" – see `escapeTime`.
  const time = passedTime == null ? undefined : passedTime.replace(/\./g, ":");
  const journey =
    time == null
      ? undefined
      : journeys.find((j) => j.departure_at.time === time);

  const bookingClass =
    passedBookingClass == null
      ? undefined
      : getValue(BookingClass, passedBookingClass);

  const canBookClass = Boolean(journey?.prices[bookingClass]);

  if (
    journey == null ||
    bookingClass == null ||
    journey?.passed ||
    !canBookClass
  ) {
    return EMPTY_JOURNEY;
  }

  return {
    journey,
    bookingClass,
  };
}
