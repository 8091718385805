import { TrainDirection } from "#utils/enums";

/*
Returns a list of objects that look like this:

    {
      from: string,
      to: string,
      // Between `from` and `to` the train goes in this direction:
      direction: "left" | "right",
    }

*/
export default function getDirections(departure, { routes }) {
  const stationNames = {};
  for (const route of routes) {
    for (const station of route.stations) {
      stationNames[station.id] = station.name;
    }
  }

  const last =
    stationNames[
      departure.legs[departure.legs.length - 1].destination.station.id
    ];

  const directionChanges = departure.legs.reduce((switches, leg, i, arr) => {
    const previous = arr[i - 1];
    const hasSameLegAsPrev =
      previous && previous.travel_direction === leg.travel_direction;

    if (!previous || !hasSameLegAsPrev) {
      return [...switches, leg];
    }
    return switches;
  }, []);

  const result = directionChanges.reduce((switches, leg, i, arr) => {
    const next = arr[i + 1];

    const from = stationNames[leg.origin.station.id];
    const to = next ? stationNames[next.origin.station.id] : last;

    return [
      ...switches,
      {
        from,
        to,
        direction:
          leg.travel_direction === TrainDirection.BACKWARD ? "right" : "left",
      },
    ];
  }, []);

  return result;
}
