/* global module, require, process */

// The entire Sentry setup (also in other files) is based on:
// https://github.com/zeit/next.js/blob/d7856c6698f3f88ae7abde1b28967607d3558ebc/examples/with-sentry/utils/sentry.js

// Add polyfills needed for Sentry in Internet Explorer.
// core-js is used by Babel for polyfills.
require("core-js/es/object/assign");

// `require("@sentry/node")` is replaced with `@sentry/browser` in the browser
// build thanks to the webpack config in next.config.js.
const Sentry = require("@sentry/nextjs");
const cookies = require("js-cookie");
const { default: getConfig } = require("next/config");

const config = getConfig();

const SENTRY_DSN =
  config != null
    ? config.serverRuntimeConfig.SENTRY_DSN ||
      config.publicRuntimeConfig.SENTRY_DSN
    : typeof process !== "undefined"
    ? process.env.SENTRY_DSN_SERVER || null
    : null;

const [SENTRY_ENVIRONMENT, SOURCE_COMMIT] =
  config != null
    ? [
        config.publicRuntimeConfig.SENTRY_ENVIRONMENT,
        config.publicRuntimeConfig.SOURCE_COMMIT,
      ]
    : typeof process !== "undefined"
    ? [
        process.env.SENTRY_ENVIRONMENT || "local",
        process.env.SOURCE_COMMIT || "unknown",
      ]
    : ["local", "unknown"];

Sentry.init({
  dsn: SENTRY_DSN,
  release: SOURCE_COMMIT,
  environment: SENTRY_ENVIRONMENT,
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  tracesSampleRate: 0.01,
});

module.exports = {
  Sentry,
  captureException: (err, { req, res, errorInfo, query, pathname }) => {
    Sentry.configureScope((scope) => {
      if (err.message) {
        // De-duplication currently doesn't work correctly for SSR / browser
        // errors so we force deduplication by error message if it is present.
        scope.setFingerprint([err.message]);
      }

      if (err.statusCode) {
        scope.setExtra("statusCode", err.statusCode);
      }

      if (res && res.statusCode) {
        scope.setExtra("statusCode", res.statusCode);
      }

      if (typeof window !== "undefined") {
        scope.setTag("ssr", false);
        scope.setExtra("query", query);
        scope.setExtra("pathname", pathname);
        const sessionId = cookies.get("sessionid");
        if (sessionId) {
          scope.setUser({ id: sessionId });
        }
      } else {
        scope.setTag("ssr", true);
        scope.setExtra("url", req.url);
        scope.setExtra("method", req.method);
        scope.setExtra("headers", req.headers);
        scope.setExtra("params", req.params);
        scope.setExtra("query", req.query);
        const sessionId = req.cookies.sessionid;
        if (sessionId) {
          scope.setUser({ id: req.cookies.sessionid });
        }
      }

      if (errorInfo) {
        Object.keys(errorInfo).forEach((key) =>
          scope.setExtra(key, errorInfo[key])
        );
      }
    });

    return Sentry.captureException(err);
  },
};
