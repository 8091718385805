/* global API_URL_STORYBOOK */
/* global require */
import { FORM_ERROR } from "final-form";
import unfetch from "isomorphic-unfetch";
import cookies from "js-cookie";
import getConfig from "next/config";
import PropTypes from "prop-types";

import {
  addQueryString,
  deleteNil,
  HOUR,
  makeQueryString,
  parseDateTimeString,
  url,
} from "#utils";
import { MINUTE } from "#utils/";
import { BookingClass, PassengerType } from "#utils/enums";

const config = getConfig();

const { APP_BASE_URL } = config.publicRuntimeConfig;

/* eslint-disable no-template-curly-in-string */
export const [API_URL, API_URL_APP, API_AUTH] =
  // `config` is undefined in Storybook.
  config == null
    ? [
        DEBUG
          ? `http://${window.location.hostname}:8000/api`
          : API_URL_STORYBOOK || "${API_URL_BROWSER}",
        "",
        ((auth) => (/^$|^\$\{/.test(auth) ? undefined : auth))("${API_AUTH}"),
      ]
    : [
        config.serverRuntimeConfig.API_URL ||
          config.publicRuntimeConfig.API_URL,
        config.serverRuntimeConfig.API_URL_APP ||
          config.publicRuntimeConfig.API_URL_APP,
        config.publicRuntimeConfig.API_AUTH,
      ];
/* eslint-enable no-template-curly-in-string */

const JSON_REGEX = /\bapplication\/json\b/;

// function journeysCache({ from, to, date, voucher }) {
//   return {
//     key:
//   }
// }

function routesCache() {
  const makeKey = () => "routes";
  return {
    key: makeKey(),
    makeKey,
    ttl: HOUR,
  };
}

function stopsCache() {
  const makeKey = () => "stops";
  return {
    key: makeKey(),
    makeKey,
    ttl: HOUR,
  };
}

function forbiddenRoutesCache() {
  const makeKey = () => "forbiddenRoutesCaches";
  return {
    key: makeKey(),
    makeKey,
    ttl: HOUR,
  };
}

function bookingSessionCache(input) {
  const makeKey = ({ sessionId }) => `bookingsession|${sessionId}`;
  return {
    key: makeKey(input),
    makeKey: (_, session) => makeKey({ sessionId: session.id }),
    ttl: HOUR,
  };
}

function calendarCache(data) {
  const makeKey = ({ date_from, date_to, origin_id, destination_id }) =>
    `calendar|${date_from}|${date_to}|${origin_id}|${destination_id}`;
  return {
    key: makeKey(data),
    makeKey,
    ttl: MINUTE * 10,
  };
}

function voucherCache(fullUrl) {
  const makeKey = (key) => `voucher|${key}`;
  return {
    key: makeKey(fullUrl),
    makeKey: (_, __, url2) => {
      return makeKey(url2);
    },
    ttl: HOUR,
  };
}

export default class Api {
  __envsubst = true;
  appMode = false;

  // We cache some API requests to avoid fetching data we already
  // have in the booking flow. The cache is cleared when reloading the page, or
  // usually after 1 hour. On the server, a new API client is instantiated for
  // every request, so that cached data isn’t shared between users.
  cacheStorage = new Map();

  ssrRequests = [];

  constructor({ req = undefined, res = undefined } = {}) {
    this.req = req;
    this.res = res;
  }

  getForbiddenRoutes() {
    return this._request("GET", url`/v1.0/routes/forbidden/`, {
      transform: transformForbidden,
      cache: forbiddenRoutesCache(),
    });
  }

  createRenamePassengerOrder(data) {
    return this._request(
      "POST",
      url`/v1.0/order/create_rename_passenger_order/`,
      {
        data,
      }
    );
  }

  /**
   *  data should take the form [{ticket_id, meal_id, sub_type}, ...]}
   */
  createPreorderedMealOrder(data) {
    return this._request(
      "POST",
      url`/v1.0/order/create_preordered_meal_order/`,
      {
        data,
      }
    );
  }

  getRoutes() {
    return this._request("GET", url`/v1.0/routes/`, {
      cache: routesCache(),
    });
  }

  getStops() {
    return this._request("GET", url`/v1.0/network/stops/`, {
      cache: stopsCache(),
    });
  }

  /**
   * getJourneys
   *
   * Returns a list of journeys.
   * A journey is a end-to-end trip that consists of a collection of departures
   *
   * journey {
   *  departures:[]
   *  prices: []              // total price of the combined departures
   *  reduced_from: []        // same as prices, but without reduction
   *  arrival_at: datetime    // the arrival_at of the last item in departures.
   *  departure_at: datetime  // the departure_at of the first item in departures.
   *  passed: bool            //
   *  available: bool         // availability could be fully booked / or any other reason
   * }
   */

  getJourneys(data) {
    return this._request("POST", url`/v1.0/departures/`, {
      data,
      transform: (journeys) => {
        return journeys.map(transformJourney);
      },
    });
  }

  postDepartureNotification(data) {
    return this._request("POST", url`/v1.0/departures/user/search/`, {
      data,
    });
  }

  getBookingSession({ sessionId, cache = true }) {
    const request = this._request(
      "GET",
      url`/v1.0/bookingsession/${sessionId}/`,
      {
        cache: cache ? bookingSessionCache({ sessionId }) : undefined,
        transform: transformSession,
      }
    );

    return request;
  }

  findBookingSession({ reference, ticket_reference, email, phone }) {
    return this._request("GET", url`/v1.0/bookingsession/find/`, {
      data: { reference, ticket_reference, email, phone },
    });
  }

  getBookingSessionInvoice({ sessionId }) {
    return this._request(
      "GET",
      url`/v1.0/bookingsession/${sessionId}/invoice/`
    );
  }

  getMovableJourneys({ id }) {
    return this._request("GET", url`/v1.0/journey/${id}/movable_to/`, {
      transform: (data) => data.journeys.map(transformJourney),
    });
  }

  moveJourneyTo({ id, ...data }) {
    return this._request("POST", url`/v1.0/journey/${id}/move/`, {
      data,
    });
  }

  cancelTicket({ id, ticketId }) {
    return this._request(
      "PATCH",
      url`/v1.0/bookingsession/${id}/ticket/${ticketId}/refund/`
    );
  }

  cancelJourney({ id }) {
    return this._request("PATCH", url`/v1.0/journey/${id}/refund/`);
  }

  cancelBooking({ id }) {
    return this._request("PATCH", url`/v1.0/bookingsession/${id}/refund/`);
  }

  createBookingSession(data) {
    return this._request("POST", url`/v1.0/bookingsession/`, {
      data,
      transform: transformSession,
    });
  }

  addInboundToBookingSession({ sessionId, ...data }) {
    return this._request(
      "PATCH",
      url`/v1.0/bookingsession/${sessionId}/add_inbound/`,
      {
        data,
        cache: bookingSessionCache({ sessionId }),
        transform: transformSession,
      }
    );
  }

  getAvailableSeats({ sessionId, ...data }) {
    return this._request(
      "GET",
      url`/v1.0/bookingsession/${sessionId}/available_seats/`,
      { data }
    );
  }

  getAvailableSeatsManage({ id, departure_id, ...data }) {
    return this._request(
      "GET",
      url`/v1.0/journey/${id}/${departure_id}/seats/`,
      { data }
    );
  }

  setSeatsManage({ id, departure_id, ...data }) {
    return this._request(
      "PATCH",
      url`/v1.0/journey/${id}/${departure_id}/seats/`,
      data
    );
  }

  getAvailableAddons({ sessionId, ...data }) {
    return this._request(
      "GET",
      url`/v1.0/bookingsession/${sessionId}/available_addons/`,
      { data }
    );
  }

  getAvailableAddonsManage({ id, departure_id, ...data }) {
    return this._request(
      "GET",
      url`/v1.0/journey/${id}/${departure_id}/addons/`,
      { data }
    );
  }

  setAddons({ sessionId, ...data }) {
    return this._request(
      "POST",
      url`/v1.0/bookingsession/${sessionId}/set_addons/`,
      {
        data,
        transform: transformSession,
      }
    );
  }

  setMealChanges({ sessionId, data }) {
    return this._request(
      "POST",
      url`/v1.0/bookingsession/${sessionId}/set_meal_changes/`,
      {
        data,
      }
    );
  }

  removeAddons({ sessionId, ...data }) {
    return this._request(
      "POST",
      url`/v1.0/bookingsession/${sessionId}/remove_addons/`,
      { data, transform: transformSession }
    );
  }

  updateAllocatedSeating({ sessionId, tickets }) {
    return this._request(
      "PATCH",
      url`/v1.0/bookingsession/${sessionId}/set_trip_seats/`,
      {
        data: tickets,
        cache: bookingSessionCache({ sessionId }),
        transform: transformSession,
      }
    );
  }

  updatePassengersOfBookingSession({ sessionId, passengers }) {
    return this._request(
      "PATCH",
      url`/v1.0/bookingsession/${sessionId}/passenger_info/`,
      {
        data: passengers,
        cache: bookingSessionCache({ sessionId }),
        transform: transformSession,
      }
    );
  }

  getBookingSessionPsp({ sessionId }) {
    const fullUrl = url`/v1.0/bookingsession/${sessionId}/psp/`;

    return this._request("GET", fullUrl);
  }

  setBookingSessionPsp({ sessionId, psp }) {
    return this._request("PATCH", url`/v1.0/bookingsession/${sessionId}/psp/`, {
      data: { psp },
    });
  }

  getVoucher({ code }) {
    const fullUrl = url`/v1.0/voucher/${code}/`;

    return this._request("GET", fullUrl, {
      cache: voucherCache(fullUrl),
      transform: (voucher) => ({
        ...voucher,
        passenger_type_limits: translate(
          voucher.passenger_type_limits,
          PassengerType
        ),
      }),
    });
  }

  getAnnouncements() {
    return this._request("GET", url`/v1.0/announcements/`);
  }

  getCalendar(data) {
    return this._request("GET", url`/v1.0/calendar/`, {
      data,
      cache: calendarCache(data),
    });
  }

  getAlterations(data) {
    return this._request("GET", url`/v1.0/alterations/`, {
      data,
    });
  }

  getAlteration({ id }) {
    return this._request("GET", url`/v1.0/alterations/${id}`);
  }

  getCampaigns({ ...data }) {
    return this._request("GET", url`/v1.0/campaign/`, { data });
  }

  getTimetables() {
    return this._request("GET", url`/v1.0/timetable/`);
  }

  getAvailableSeatsSilverrail({ ...data }) {
    return this._request("GET", url`/v1.0/silverrail/available_seats/`, {
      data,
    });
  }

  getAvailableAddonsSilverrail({ ...data }) {
    return this._request("GET", url`/v1.0/silverrail/available_addons/`, {
      data,
    });
  }

  createUser(data) {
    return normalizeFormErrorData(
      this._request("POST", url`/v1.0/account/`, { data })
    );
  }

  activateUser({ userId }) {
    return this._request("POST", url`/v1.0/account/${userId}/activate/`);
  }

  updateUser(data) {
    return normalizeFormErrorData(
      this._request("PATCH", url`/v1.0/account/update/`, {
        data,
        transform: transformUser,
      })
    );
  }

  getUser() {
    return this._request("GET", url`/v1.0/account/me/`, {
      // An empty string is returned when not logged in. Use `undefined` instead.
      transform: (user) => (user ? transformUser(user) : undefined),
    });
  }

  getUserBookings(data) {
    return this._request("GET", url`/v1.0/account/trips/`, {
      data,
      transform: (response) => ({
        ...response,
        results: response.results.map(transformSessionJourney),
      }),
    });
  }

  getUserJourney({ departureId, id }) {
    if (departureId) {
      throw new Error("Using deprecated property departureId", departureId);
    }
    // return this._request("GET", url`/v1.0/account/trips/${id}/${bookingId}/`, {
    return this._request("GET", url`/v1.0/account/trips/${id}/`, {
      transform: transformSessionJourney,
    });
  }

  login(data) {
    return normalizeFormErrorData(
      this._request("POST", url`/v1.0/account/login/`, {
        data,
        transform: transformUser,
      })
    );
  }

  logout() {
    return this._request("POST", url`/v1.0/account/logout/`);
  }

  forgotPassword(data) {
    return normalizeFormErrorData(
      this._request("POST", url`/v1.0/account/forgot_password/`, { data })
    );
  }

  resetPassword(data) {
    return normalizeFormErrorData(
      this._request("POST", url`/v1.0/account/reset_password/`, { data })
    );
  }

  // `partialResponse` is an object as returned by the `responseToJSON`
  // function. If it has a `cacheKey`, put the `data` into the cache.
  reviveCache(partialResponse) {
    const { cacheKey, data } = partialResponse;
    if (cacheKey != null) {
      this.cacheStorage.set(cacheKey, {
        timestamp: Date.now(),
        data,
      });
    }
  }

  clearCache() {
    this.cacheStorage.clear();
  }

  internalPSPInit({ method, url: pspURL, data }) {
    return this._request(method, pspURL, {
      data,
      absUrl: true,
    });
  }

  freePSPFinish({ sessionId }) {
    return this._request("post", url`/v1.0/payment/free/${sessionId}/finish/`);
  }

  paymentLoyalty({ sessionId }) {
    return this._request(
      "POST",
      url`/v1.0/payment/loyalty_program/${sessionId}/finish/`
    );
  }

  paymentBonus({ sessionId }) {
    return this._request(
      "POST",
      url`/v1.0/payment/loyalty/${sessionId}/finish/`
    );
  }

  paymentInvoice({ sessionId, ...data }) {
    return this._request(
      "POST",
      url`/v1.0/payment/invoicing/${sessionId}/finish/`,
      { data }
    );
  }

  paymentTravelClearing({ id, data }) {
    return this._request(
      "POST",
      url`/v1.0/payment/travel_clearing/${id}/finish/`,
      { data }
    );
  }

  getUserBonusCreditsLog({ id, ...data }) {
    return this._request("GET", url`/v1.0/loyalty/bonus/${id}/credits_log/`, {
      data,
    });
  }

  getUserBonusDebitsLog({ id, ...data }) {
    return this._request("GET", url`/v1.0/loyalty/bonus/${id}/debits_log/`, {
      data,
    });
  }

  getUserBonusLog({ id, ...data }) {
    return this._request("GET", url`/v1.0/loyalty/bonus/${id}/log/`, {
      data,
    });
  }

  createFavoritePassenger({ ...data }) {
    return this._request("POST", url`/v1.0/account/passengers/`, data);
  }

  deleteFavoritePassenger({ id }) {
    return this._request("DELETE", url`/v1.0/account/passengers/${id}/`);
  }

  editFavoritePassenger({ id, ...data }) {
    return this._request("PATCH", url`/v1.0/account/passengers/${id}/`, data);
  }

  getMealPreference({ ...data }) {
    return this._request("GET", url`/v1.0/account/meal/preferences/`, data);
  }

  updateMealPreference({ ...data }) {
    return this._request("PATCH", url`/v1.0/account/meal/preferences/`, data);
  }

  activateAndUpdate({ token, data }) {
    return this._request(
      "POST",
      url`/v1.0/account/${token}/activate-and-update/`,
      { data }
    );
  }

  activate({ token, data }) {
    return this._request("POST", url`/v1.0/account/${token}/activate/`, {
      data,
    });
  }

  getFavoritePassengers() {
    return this._request("GET", url`/v1.0/account/passengers/`);
  }

  authenticateWithMagicLink({ id }) {
    return this._request("GET", url`/auth?login=${id}`);
  }

  getMembers({ corporate_pk, ...data }) {
    return this._request("GET", url`/v1.0/corporate/${corporate_pk}/member/`, {
      data,
    });
  }

  getTravelClearing(corporate_id, id) {
    return this._request("GET", url`/v1.0/corporate/travelclearing/${id}/`, {
      data: {
        corporate_id,
      },
    });
  }

  updateTravelClearing(id, data) {
    return this._request("PATCH", url`/v1.0/corporate/${id}/clearing/`, {
      data,
    });
  }

  createTravelClearing(data) {
    return this._request("POST", url`/v1.0/corporate/travelclearing/`, {
      data,
    });
  }

  corporateReport({ asBlob = false, ...data }) {
    return this._request("POST", url`/v1.0/reports/`, { asBlob, ...data });
  }

  validateChangedEmail({ ...data }) {
    return this._request("POST", url`/v1.0/account/update_email/`, {
      data,
    });
  }

  getProducts({ types }) {
    return this._request("GET", url`/v1.0/products/`, {
      data: { type: types },
    });
  }

  createCommuterOrder({ ...data }) {
    return this._request("POST", url`/v1.0/order/create_commuter_order/`, {
      data,
    });
  }

  getOrder({ id, include }) {
    return this._request("GET", url`/v1.0/order/${id}/`, { data: { include } });
  }

  getDiscounts(data) {
    return this._request("GET", url`/v1.0/discount/`, {
      data,
      transform: transformDiscounts,
    });
  }

  getCorporateMembers(data) {
    return this._request("GET", url`/v1.0/corporate-member/`, {
      data,
    });
  }

  getCorporateMemberRequests(data) {
    return this._request("GET", url`/v1.0/corporate-member/requests/`, {
      data,
    });
  }

  acceptCorporateMemberRequest({ id, data }) {
    return this._request(
      "PATCH",
      url`/v1.0/corporate-member/${id}/request-accept/`,
      {
        data,
      }
    );
  }

  denyCorporateMemberRequest({ id, data }) {
    return this._request(
      "PATCH",
      url`/v1.0/corporate-member/${id}/request-deny/`,
      {
        data,
      }
    );
  }

  createCorporateMemberRequest({ data }) {
    return this._request("POST", url`/v1.0/corporate-member/request/`, {
      data,
    });
  }

  createCorporateMemberInvite({ id, data }) {
    return this._request("POST", url`/v1.0/corporate-member/${id}/invite/`, {
      data,
    });
  }

  removeCorporateMember({ id, data }) {
    return this._request("DELETE", url`/v1.0/corporate-member/${id}/`, {
      data,
    });
  }

  getCorporates(data) {
    return this._request("GET", url`/v1.0/corporate-member/corporates/`, {
      data,
    });
  }

  getCorporate({ id, data }) {
    return this._request("GET", url`/v1.0/corporate-member/${id}/corporate/`, {
      data,
    });
  }

  updateCorporateInvoicing({ id, data }) {
    return this._request(
      "PATCH",
      url`/v1.0/corporate-member/${id}/corporate-invoicing/`,
      {
        data,
      }
    );
  }

  updateCorporateDiscount({ id, data }) {
    return this._request(
      "PATCH",
      url`/v1.0/corporate-member/${id}/corporate-discount/`,
      {
        data,
      }
    );
  }

  updateCorporateMember({ id, data }) {
    return this._request("PATCH", url`/v1.0/corporate-member/${id}/`, {
      data,
    });
  }

  addGiftCode({ id, data }) {
    return this._request(
      "PATCH",
      url`/v1.0/bookingsession/gift_discount/${id}/`,
      {
        data,
        transform: transformSession,
      }
    );
  }

  removeGiftCode(id) {
    return this._request(
      "DELETE",
      url`/v1.0/bookingsession/gift_discount/${id}/`,
      {
        transform: transformSession,
      }
    );
  }

  forgetBookingSession(id) {
    return this._request("PATCH", url`/v1.0/bookingsession/${id}/forget/`);
  }

  setTrackedOrder({ id, ...data }) {
    return this._request("PUT", url`/v1.0/order/${id}/tracking/`, data);
  }

  getOrderPSPs({ id }) {
    return this._request("GET", url`/v1.0/order/${id}/psp/`);
  }

  setOrderPSP({ id, ...data }) {
    return this._request("POST", url`/v1.0/order/${id}/psp/`, { data });
  }

  setOrderPending({ id, ...data }) {
    return this._request("POST", url`/v1.0/order/${id}/pending/`, { data });
  }

  finishOrderInvoicing({ id, ...data }) {
    return this._request(
      "POST",
      url`/v1.0/payment/invoicing/${id}/finish-order/`,
      { data }
    );
  }
  /* Takes journeys and returns a menu object for group bookings */
  getMenu({ ...data }) {
    return this._request("POST", url`/v1.0/menu/`, { data });
  }

  /* Email validation */

  validateEmail(email) {
    return this._request(
      "GET",
      url`/v1.0/notify/email/validate/?email=${email}`
    );
  }

  /**
   * TEMP:
   * USED BY REBEL ADMIN UNTIL DJANGO-BANANAS HAS SUPPORT FOR NESTED ROUTES.
   */

  corporateList({ ...data }) {
    return this._request("GET", url`/v1.0/corporate/`, { data });
  }

  corporateAgreement({ id }) {
    return this._request("GET", url`/v1.0/corporate/${id}/`);
  }

  updateCorporateBillingAddress({ id, ...data }) {
    return this._request("PATCH", url`/v1.0/corporate/${id}/billing/`, data);
  }

  updateCorporateAgreement({ id, ...data }) {
    return this._request("PATCH", url`/v1.0/corporate/${id}/`, data);
  }

  updateMember({ corporate_pk, id, data }) {
    return this._request(
      "PATCH",
      url`/v1.0/corporate/${corporate_pk}/member/${id}/`,
      { data }
    );
  }

  createMember({ corporate_pk, data }) {
    return this._request("POST", url`/v1.0/corporate/${corporate_pk}/member/`, {
      data,
    });
  }

  corporateDiscountUpdate({ data, id }) {
    return this._request("POST", url`/v1.0/corporate/${id}/discount/`, {
      data,
    });
  }

  /**
   * TEMP ( USED BY BANANAS ):
   */

  deleteMember({ corporate_pk, id }) {
    return this._request(
      "DELETE",
      url`/v1.0/corporate/${corporate_pk}/member/${id}/`
    );
  }

  deleteNote({ corporate_pk, id }) {
    return this._request(
      "DELETE",
      url`/v1.0/corporate/${corporate_pk}/note/${id}/`
    );
  }

  getNotes({ corporate_pk, data }) {
    return this._request("GET", url`/v1.0/corporate/${corporate_pk}/note/`, {
      data,
    });
  }

  createNote({ corporate_pk, data }) {
    return this._request("POST", url`/v1.0/corporate/${corporate_pk}/note/`, {
      data,
    });
  }

  getAgencyMembers({ travelagency_pk }) {
    return this._request(
      "GET",
      url`/v1.0/travelagency/${travelagency_pk}/member/`
    );
  }

  deleteAgencyMember({ travelagency_pk, id }) {
    return this._request(
      "DELETE",
      url`/v1.0/travelagency/${travelagency_pk}/member/${id}/`
    );
  }

  updateAgencyMember({ travelagency_pk, id, data }) {
    return this._request(
      "PATCH",
      url`/v1.0/travelagency/${travelagency_pk}/member/${id}/`,
      { data }
    );
  }

  createAgencyMember({ travelagency_pk, data }) {
    return this._request(
      "POST",
      url`/v1.0/travelagency/${travelagency_pk}/member/`,
      {
        data: {
          ...data,
          role: 4, // force viewer role
        },
      }
    );
  }

  createAgencyRelations({ travelagency_pk, data }) {
    return this._request(
      "POST",
      url`/v1.0/travelagency/${travelagency_pk}/relations/`,
      {
        data,
      }
    );
  }

  updateAgencyRelation({ travelagency_pk, id, data }) {
    return this._request(
      "PATCH",
      url`/v1.0/travelagency/${travelagency_pk}/relations/${id}/`,
      { data }
    );
  }

  getAgencyRelations({ travelagency_pk }) {
    return this._request(
      "GET",
      url`/v1.0/travelagency/${travelagency_pk}/relations/`
    );
  }

  deleteAgencyRelation({ travelagency_pk, id }) {
    return this._request(
      "DELETE",
      url`/v1.0/travelagency/${travelagency_pk}/relations/${id}/`
    );
  }

  searchPage({ query }) {
    return this._request("GET", url`/v1.0/cms/faq/?query=${query}`);
  }

  getPage({ slug }) {
    return this._request("GET", url`/v1.0/cms/faq/${slug}/`);
  }

  getEventPage({ slug }) {
    return this._request("GET", url`/v1.0/cms/event/${slug}/`);
  }

  postTicket(data) {
    return this._request("POST", url`/v1.0/freshdesk/ticket/`, { data });
  }

  trackEvent(data) {
    return this._request("POST", url`/v1.0/marketing/track/`, { data });
  }

  postMissingGoods(data) {
    return this._request("POST", url`/v1.0/freshdesk/ticket/missing_goods/`, {
      data,
    });
  }

  postNewCorporateCreate(data) {
    return this._request("POST", url`/v1.0/freshdesk/ticket/new_corporate/`, {
      data,
    });
  }
  postNewGroupCreate(data) {
    return this._request("POST", url`/v1.0/freshdesk/ticket/new_group/`, {
      data,
    });
  }

  postSubscribeMarketing(data) {
    return this._request("POST", url`/v1.0/marketing/subscribe/`, { data });
  }

  generateHTMLFromTemplate(data, name) {
    return this._request("POST", `${APP_BASE_URL}/api/template/${name}`, {
      data,
      absUrl: true,
    });
  }

  /**
         * END OF TEMP
        /*
      
        Makes a request to `apiUrl` using `method` and sending `data`. Returns a
        `Promise` that resolves to an unfetch Response, with an added `.data` property
        which contains the response JSON (or the response string if the response isn’t
        JSON). The promise is rejected for error status codes and network errors. If
        available, `error.response` contains the Response object (including status
        code).
      
        Example usage:
      
            request("GET", "/api/something", { data: { foo: bar } }).then(
              response => {
                // Success: Use `response.data` (parsed JSON or string).
                // If needed, check `response.status` or `response.headers` etc.
              },
              error => {
                // Handle errors (network error, server error (500), JSON parse error, etc.)
                // error.response: ?Response
              },
            );
      
        You may optionally pass a `cache` object. GET requests can
        read the cache, while all methods can write to it. Cache objects look like this:
      
            {
              // The key to look for in the cache for GET requests.
              key: string,
              // The key to write to in the cache on response.
              makeKey: (data, responseData) => string,
              // How long (in milliseconds) a cached value should be used before expiring.
              ttl: number,
            }
      
        On the server, `response.cacheKey` is set. You can then run
        `reviveCache(response)` (for example in `componentDidMount`) to write to the
        cache on the browser side.
      
        You can also pass a `transform` function to transform the request. An important
        difference between `request(method, url, { transform })` and `request(method,
        url).then(transform)` is that when passed as an option the transformed result
        is cached (if `cache` is passed). Otherwise `.then(transform)` might fail on
        requests based on data from `reviveCache` (which is transformed, not original
        data).
        */

  async _request(
    method,
    apiUrl,
    {
      data = undefined,
      cache = undefined,
      transform = undefined,
      absUrl = undefined,
      asBlob = false,
    } = {}
  ) {
    const isGET = method.toLowerCase() === "get";
    const newApiUrl =
      data != null && isGET
        ? addQueryString(apiUrl, makeQueryString(data))
        : apiUrl;

    const baseUrl = this.appMode ? API_URL_APP : API_URL;
    const fullUrl = absUrl ? newApiUrl : `${baseUrl}${newApiUrl}`;

    if (isGET && cache != null) {
      const entry = this.cacheStorage.get(cache.key);
      if (entry != null) {
        if (entry.timestamp + cache.ttl >= Date.now()) {
          const response = cacheResponse(cache.key, fullUrl, data, entry.data);
          // Make the request show up in the console, since it does not show up in
          // the Network tab.
          // eslint-disable-next-line
          console.log("using cache:", response);
          return response;
        }
        // eslint-disable-next-line
        console.log("deleting cache:", fullUrl, cache);
        this.cacheStorage.delete(cache.key);
      }
    }

    const headers = deleteNil({
      Accept: "application/json",
      "Content-Type": data == null ? undefined : "application/json",
      Authorization: API_AUTH,
      ...(this.req != null
        ? {
            ...forwardHeaders(this.req, ["Accept-Encoding", "Accept-Language"]),
            Cookie: Object.entries({
              ...this.req.cookies,
              // language: this.req.originalUrl.startsWith("/sv") ? "sv" : "en",
            })
              .map(([key, value]) => `${key}=${value}`)
              .join("; "),
            Referer: `https://${this.req.headers.Host}${this.req.originalUrl}`,
            "X-CSRFToken": this.req.cookies.csrftoken,
          }
        : {
            "X-CSRFToken": cookies.get("csrftoken"),
          }),
    });

    // Cuz browser thought it would be a good idea to limit what methods where allowed...
    // if (!["GET", "POST", "HEAD"].includes(method)) {
    //   headers["X-HTTP-Method-Override"] = method;
    //   method = "POST"; // eslint-disable-line
    // }

    try {
      const response = await unfetch(fullUrl, {
        method,
        credentials: "include",
        body: data != null && !isGET ? JSON.stringify(data) : undefined,
        headers,
      });
      if (this.res != null) {
        const setCookie = response.headers.raw()["set-cookie"];
        if (setCookie) {
          this.res.setHeader("set-cookie", setCookie);
        }
      }

      // So you can return `response` as a prop in `getInitialProps`.
      response.toJSON = responseToJSON;

      // For debugging.
      response.__input = data;

      const isJSON = JSON_REGEX.test(response.headers.get("Content-Type"));
      const rawResponseData = asBlob
        ? await response.blob()
        : await (isJSON ? response.json() : response.text());
      response.data = rawResponseData;

      if (response.status >= 200 && response.status < 400) {
        if (transform != null) {
          try {
            response.data = transform(response.data);
          } catch (error) {
            error.response = response;
            throw error;
          }
        }

        if (cache != null) {
          const key = cache.makeKey(data, response.data, newApiUrl);
          this.cacheStorage.set(key, {
            timestamp: Date.now(),
            data: response.data,
          });
          if (typeof window === "undefined") {
            response.cacheKey = key;
          }
        }

        // Save requests made on the server when running locally so that we can
        // log them to the browser console on page load. This helps debugging.
        // We don’t do this in production since it increases the HTML size a lot
        // (all the data needs to be serialized and stored there).
        if (DEBUG && typeof window === "undefined") {
          this.ssrRequests.push({
            status: response.status,
            method,
            url: fullUrl,
            params: data,
            response: rawResponseData,
          });
        }

        return response;
      }

      const error = new Error(`Non-success status code: ${response.status}`);
      error.response = response;
      throw error;
    } catch (error) {
      const { response } = error;

      error.message = `${
        response == null
          ? "(no response)"
          : `${response.status} ${response.statusText}`
      } ${method} ${fullUrl}:\n${error.message}`;

      if (response == null) {
        error.noResponse = true;
      }

      throw error;
    }
  }
}

/**
 *
 * This function was used to transform getMovableDepartures for rebooking.
 * Leaving it here until I'm a 100% sure it can go.
 */
// function transformDepartureDates({ departures }) {
//   return departures.map(({ arrival_at, departure_at, ...rest }) => {
//     return {
//       arrival_at: parseDateTimeString(arrival_at),
//       departure_at: parseDateTimeString(departure_at),
//       datetime: new Date(arrival_at),
//       ...rest,
//     };
//   });
// }

/*
If you draw the train with carriage A the furthest to the left,
the "left" and "right" direction are defined as follows:

      _______ _______ _______ _______ _______
     /===A===|===B===|===C===|===D===|===E===\
     ‾‾‾‾‾‾‾‾ ‾‾‾‾‾‾‾ ‾‾‾‾‾‾‾ ‾‾‾‾‾‾‾ ‾‾‾‾‾‾‾
     ←                                       →
     left                                right

This is a very rough map of the route the trains take:


                  ____________ Stockholm
                 /
                /
    Göteborg ––<
                \
                |
                Halmstad

Göteborg Station is a "dead end" where the train tracks end. Trains have to come
in at one direction, and the "back out" again. This means that the MTRX trains
change direction not only at the end stations, but also in Göteborg. (Rotating
trains is not a thing, since it's easier to just drive the train the other way.)

    Stockholm → Göteborg:  left
    Göteborg  → Halmstad:  right
    Halmstad  → Göteborg:  left
    Göteborg  → Stockholm: right

This function adds `direction` to every station, which is the direction the
train has when LEAVING that station. This should probably be moved into backend
at some point.
*/

function transformDeparture(departure) {
  return {
    ...departure,
    departure_at: parseDateTimeString(departure.departure_at),
    arrival_at: parseDateTimeString(departure.arrival_at),
    tickets:
      departure.tickets != null
        ? departure.tickets.map((ticket) => ({
            ...ticket,
            price: normalizePrice(ticket.price),
            vat_amount: normalizePrice(ticket.vat_amount),
          }))
        : undefined,
  };
}

function transformSessionDeparture(departure) {
  const first = departure.legs[0];
  const last = departure.legs[departure.legs.length - 1];

  return {
    ...departure,
    origin: {
      name: first.origin.station.name,
      display_name: first.origin.station.display_name,
      id: first.origin.station.id,
      quay: first.origin.quay,
      stop_at: first.origin.stop_at,
      leave_at: first.origin.leave_at,
    },
    destination: {
      name: last.destination.station.name,
      display_name: last.destination.station.display_name,
      id: last.destination.station.id,
      quay: first.destination.quay,
      stop_at: first.destination.stop_at,
      leave_at: first.destination.leave_at,
    },
    departure_at: parseDateTimeString(first.origin.leave_at),
    arrival_at: parseDateTimeString(last.destination.stop_at),
    tickets:
      departure.tickets != null
        ? departure.tickets.map((ticket) => ({
            ...ticket,
            price: normalizePrice(ticket.price),
            vat_amount: normalizePrice(ticket.vat_amount),
          }))
        : undefined,
  };
}

function transformJourney(journey) {
  return {
    ...journey,
    departure_at: parseDateTimeString(journey.departure_at),
    arrival_at: parseDateTimeString(journey.arrival_at),
    // these were previously the top-level, now a journey can consist of several departures.
    departures: journey.departures.map(transformDeparture),
    // total price of the journey
    price: journey.price && normalizePrice(journey.price.amount),
    prices:
      journey.prices &&
      Object.entries(journey.prices).reduce((result, [key, price]) => {
        const id = BookingClass[key];
        const reducedFrom = journey.reduced_from[key];
        if (id != null) {
          result[id] = {
            current: normalizePrice(price),
            reducedFrom:
              reducedFrom != null ? normalizePrice(reducedFrom) : undefined,
          };
        }
        return result;
      }, {}),
  };
}

function transformForbidden(forbiddenJourneys) {
  return forbiddenJourneys.map(({ origin, destination }) => ({
    from: origin.id,
    to: destination.id,
  }));
}

function transformSessionJourney(journey) {
  const { departures } = journey;
  const route = departures.reduce((arr, { legs }) => {
    legs.forEach(({ origin, destination }) => {
      arr.push({
        origin,
        destination,
      });
    });

    return arr;
  }, []);

  const departureAt = journey.origin.leave_at;
  const arrivalAt = journey.destination.stop_at;

  return {
    ...journey,
    origin: {
      ...journey.origin,
      name: journey.origin.station.display_name,
    },
    destination: {
      ...journey.destination,
      name: journey.destination.station.display_name,
    },
    departure_at: parseDateTimeString(departureAt),
    arrival_at: parseDateTimeString(arrivalAt),
    departures: journey.departures.map(transformSessionDeparture),
    price: normalizePrice(journey.price.amount),
    route,
  };
}

function transformDiscounts(discounts) {
  return discounts.map((discount) => ({
    ...discount,
    total_use: discount.total_uses,
    code: discount.codes.map(({ code }) => code).join(", "),
    passenger_type_limits: translate(
      discount.passenger_type_limits,
      PassengerType
    ),
  }));
}

function transformSession({
  valid_seconds_left,
  journeys,
  discount_codes,
  consumed_giftcard_money,
  ...session
}) {
  return {
    ...session,
    discount_codes: discount_codes?.map((discount) => ({
      ...discount,
      discount: {
        ...discount.discount,
        data: {
          ...discount.discount.data,
          initial_amount: Number(discount.discount.data.initial_amount),
          remaining_amount: Number(discount.discount.data.remaining_amount),
        },
      },
    })),
    consumed_giftcard_money: consumed_giftcard_money
      ? {
          ...consumed_giftcard_money,
          amount: parseInt(consumed_giftcard_money.amount, 10),
        }
      : undefined,
    journeys: journeys.map(transformSessionJourney),
    // The session already has these fields:
    //
    //     valid_until: ISO-8601 date string when the session expires.
    //     valid_seconds_left: number of seconds left before the session expires.
    //
    // From those two, this creates a local timestamp at which the session
    // expires **according to the _user's_ clock.** This _should_ be the same as
    // `new Date(valid_until).getTime()`, but we quickly found out that some
    // users have their system clocks wrong. This caused the "Your session is
    // about to expire" message in the booking flow appear immediately for some
    // users (according to their clock, `valid_until` was in the past). It could
    // also be the other way around: The message _not_ appearing when it should,
    // due the `valid_until` being considered too far into the future.
    //
    // Note that this timestamp could be a couple of seconds late due to network
    // latency, but that shouldn't matter in practice, even if it's a minute
    // wrong.
    validUntil:
      valid_seconds_left != null
        ? {
            localTimestamp: Date.now() + valid_seconds_left * 1000,
          }
        : undefined,
  };
}

// Translate `"FIX": 100` into `"1": 100` etc. In other words, change the `dict`
// keys from being enum _names_ into the actual enum _values._
function translate(dict, enumObject) {
  return Object.entries(dict).reduce((result, [key, value]) => {
    const id = enumObject[key];
    if (id != null) {
      result[id] = value;
    }
    return result;
  }, {});
}

// The API returns prices in various different formats:
//
// - Number (100.00)
// - String ("100.00")
// - Money ({ amount: 100.00 | "100.00", currency: "SEK" })
//
// The frontend never uses the currency and sometimes needs to sum prices
// together, so normalize all prices to numbers to keep things simple.
function normalizePrice(price) {
  const value =
    typeof price === "number" || typeof price === "string"
      ? price
      : price != null
      ? price.amount
      : 0;
  return Number(value);
}

/*
Turn this:

    ["User already exists"]

And this:

    {"detail": "User already exists"}

Into:

    {"non_field_errors": ["User already exists"]}

So that it fits with the general case, which looks like this:

    {"non_field_errors": ["Passwords must match"], "<field_name>": ["This field is required"]}

Also, `"non_field_errors"` is replaced with `FORM_ERROR` from final-form, for
ease of use.
*/
function normalizeFormErrorData(promise) {
  return promise.catch((error) => {
    if (error.response != null) {
      const { data } = error.response;
      if (Array.isArray(data)) {
        error.response.data = {
          [FORM_ERROR]: data,
        };
      } else if (data != null && typeof data === "object") {
        const { non_field_errors = [], detail, ...errors } = data;
        error.response.data = {
          [FORM_ERROR]: non_field_errors.concat(detail).filter(Boolean),
          ...errors,
        };
      }
    }
    throw error;
  });
}

function transformUser(user) {
  return {
    ...user,
    name:
      [user.firstname, user.lastname].filter(Boolean).join(" ") || "Anonymous",
  };
}

// Based on: https://github.com/developit/unfetch/blob/92d9203e02edbd7ac6788be41dd629b5c8247299/src/index.mjs#L9-L24
function cacheResponse(cacheKey, fullUrl, inputData, outputData) {
  const bodyConsumed = () =>
    Promise.reject(new TypeError("Body has already been consumed."));
  return {
    ok: true,
    statusText: "OK",
    status: 200,
    url: fullUrl,
    text: bodyConsumed,
    json: bodyConsumed,
    blob: bodyConsumed,
    clone: () => {
      throw new Error(".clone() is not implemented for cached responses.");
    },
    headers: {
      keys: () => [],
      entries: () => [],
      get: () => undefined,
      has: () => false,
    },
    toJSON: responseToJSON,
    __input: inputData,
    data: outputData,
    cacheKey,
    __fromCache: true,
  };
}

/* eslint-disable babel/no-invalid-this */
function responseToJSON() {
  return {
    status: this.status,
    url: this.url,
    cacheKey: this.cacheKey,
    data: this.data,
  };
}
/* eslint-enable babel/no-invalid-this */

export const responsePropType = PropTypes.shape({
  cacheKey: PropTypes.string,
  data: PropTypes.any,
});

function forwardHeaders(req, headerNames) {
  return headerNames.reduce((result, name) => {
    result[name] = req.headers[name];
    return result;
  }, {});
}

// Patch the `Response` objects from "node-fetch" (used by "isomorphic-unfetch")
// to make the Node.js error logs easier to read.
export function patchResponse() {
  if (typeof window === "undefined") {
    // eslint-disable-next-line import/no-extraneous-dependencies, no-undef
    const { Response } = require("node-fetch");
    const inspect = Symbol.for("nodejs.util.inspect.custom");
    Response.prototype[inspect] = function () {
      function stringify(object) {
        try {
          return JSON.stringify(object, undefined, 2) || "undefined";
        } catch (error) {
          return `(JSON.stringify failed for \`${object}\`: ${error.message})`;
        }
      }
      // djedi-react uses `__output` instead of `data`.
      const { __input, __output, data = __output, ...rest } = this;
      const input = stringify(__input);
      const output =
        typeof data === "string"
          ? // Try to remove excessive data from Django error messages.
            DEBUG
            ? data.replace(
                /\n(Python Path|Installed (Applications|Middleware)):\s*\[[^\]]*\]|\n(META|Settings):(\nUsing.*)?(\n\S+ =.+)*/g,
                ""
              )
            : data
          : stringify(data);
      const indent = (string, i) => string.replace(/\n/g, `\n${" ".repeat(i)}`);
      return {
        ...rest,
        __io: {
          [inspect]: (depth, { indentationLvl: i }) =>
            indent(
              `INPUT:\n ${indent(input, 1)}\nOUTPUT:\n ${indent(output, 1)}`,
              i
            ),
        },
      };
    };
  }
}
