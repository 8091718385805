import classnames from "classnames";
import { ForceNodes, md, Node } from "djedi-react";
import { FORM_ERROR } from "final-form";
import createFocusDecorator from "final-form-focus";
import NProgress from "nprogress";
import PropTypes from "prop-types";
import React from "react";
import { Field, Form } from "react-final-form";

import {
  combineValidators,
  emailIsh,
  normalizePhoneOnBlur,
  phoneIsh,
  required,
  requiredBoolean,
  trimOnBlur,
} from "#utils/forms";

import AppContext from "../AppContext";
import Button from "../Button";
import ErrorsList from "../ErrorsList";
import LabeledField from "../LabeledField";
import sharedStyles from "../LoginForm/LoginForm.module.css";
import PasswordInput from "../PasswordInput";
import PasswordValidator, { validPassword } from "../PasswordValidator";
import Terms, { ACCEPT_TERMS } from "../Terms";

export const SIGNUP_NODE = <Node uri="SignupForm/signup">Registrera</Node>;

const NODES = {
  signup: SIGNUP_NODE,
  signupSuccess: (
    <Node uri="SignupForm/signup-success.md" email="?">{md`
      Ett aktiveringsmail har skickats till:<br>[email]
    `}</Node>
  ),
};

const FIELD_NODES = {
  firstname: {
    label: <Node uri="SignupForm/firstname/label">Förnamn</Node>,
    placeholder: <Node uri="SignupForm/firstname/placeholder">Förnamn</Node>,
    required: <Node uri="SignupForm/firstname/required">Ange ett förnamn</Node>,
  },
  lastname: {
    label: <Node uri="SignupForm/lastname/label">Efternamn</Node>,
    placeholder: <Node uri="SignupForm/lastname/placeholder">Efternamn</Node>,
    required: (
      <Node uri="SignupForm/lastname/required">Ange ett efternamn</Node>
    ),
  },
  email: {
    label: <Node uri="SignupForm/email/label">E-postadress</Node>,
    placeholder: <Node uri="SignupForm/email/placeholder">din@email.se</Node>,
    required: <Node uri="SignupForm/email/required">Ange en e-postadress</Node>,
    valid: (
      <Node uri="SignupForm/email/valid">Ange en giltig e-postadress</Node>
    ),
  },
  phone: {
    label: <Node uri="SignupForm/phone/label">Mobilnummer</Node>,
    placeholder: <Node uri="SignupForm/phone/placeholder">07x-xxx xx xx</Node>,
    required: <Node uri="SignupForm/phone/required">Ange ett mobilnummer</Node>,
    valid: (
      <Node uri="SignupForm/phone/valid">Ange ett giltigt mobilnummer</Node>
    ),
  },
  password: {
    label: <Node uri="SignupForm/password/label">Lösenord</Node>,
    placeholder: (
      <Node uri="SignupForm/password/placeholder">************</Node>
    ),
    required: <Node uri="SignupForm/password/required">Ange ett lösenord</Node>,
    valid: (
      <Node uri="SignupForm/password/valid">
        Ange ett giltigt lösenord – se nedan
      </Node>
    ),
  },
};

const ERRORS = {
  other: (
    <Node uri="SignupForm/error/other" status="-1">
      Misslyckades att registrera ([status])
    </Node>
  ),
  network: (
    <Node uri="SignupForm/error/network">
      Misslyckades att registrera. Kontrollera din Internet-anslutning!
    </Node>
  ),
};

const focusOnErrors = createFocusDecorator();

SignupForm.propTypes = {
  after: PropTypes.node,
  className: PropTypes.string,
};

SignupForm.defaultProps = {
  after: undefined,
  className: "",
};

export default function SignupForm({ after, className }) {
  const { api } = React.useContext(AppContext);

  const [signedUp, setSignedUp] = React.useState(false);

  return (
    <Form
      onSubmit={async (values) => {
        NProgress.start();
        try {
          await api.createUser(values);
          setSignedUp(true);
          try {
            if (window.navigator.credentials && window.PasswordCredential) {
              const cred = new window.PasswordCredential({
                id: values.email,
                password: values.password,
                name: `${values.firstname} ${values.lastname}`,
              });
              window.navigator.credentials.store(cred);
            }
            // eslint-disable-next-line no-empty
          } catch (err) {}
          NProgress.done();
          return undefined;
        } catch (error) {
          console.error("SignupForm: Failed to sign up", error, error.response);
          NProgress.done();

          if (error.response != null) {
            const { status } = error.response;

            if (status != null) {
              return {
                [FORM_ERROR]: [React.cloneElement(ERRORS.other, { status })],
              };
            }

            if (error.noResponse) {
              return {
                [FORM_ERROR]: [ERRORS.other],
              };
            }
          }

          return {
            [FORM_ERROR]: [React.cloneElement(ERRORS.other, { status: 1500 })],
          };
        }
      }}
      decorators={[focusOnErrors]}
    >
      {({
        handleSubmit,
        submitting,
        submitError: submitErrors = [],
        values,
      }) => {
        return (
          <form
            onSubmit={handleSubmit}
            noValidate
            className={classnames(
              {
                [sharedStyles.form]: !signedUp,
              },
              sharedStyles.spaced,
              className
            )}
          >
            {signedUp ? (
              <div
                className={classnames(sharedStyles.center, sharedStyles.spaced)}
              >
                {React.cloneElement(NODES.signupSuccess, {
                  email: values.email,
                })}
              </div>
            ) : (
              <div className={sharedStyles.spacedSmall}>
                <div className={sharedStyles.fieldContainer}>
                  <Field
                    className={sharedStyles.field}
                    name="firstname"
                    validate={required(FIELD_NODES.firstname.required)}
                    required
                    autoComplete="given-name"
                    {...trimOnBlur}
                    component={LabeledField}
                    label={FIELD_NODES.firstname.label}
                    placeholder={FIELD_NODES.firstname.placeholder}
                    autoFocus
                  />

                  <Field
                    className={sharedStyles.field}
                    name="lastname"
                    validate={required(FIELD_NODES.lastname.required)}
                    required
                    autoComplete="family-name"
                    {...trimOnBlur}
                    component={LabeledField}
                    label={FIELD_NODES.lastname.label}
                    placeholder={FIELD_NODES.lastname.placeholder}
                  />
                </div>

                <div className={sharedStyles.fieldContainer}>
                  <Field
                    className={sharedStyles.field}
                    name="phone"
                    validate={combineValidators(
                      required(FIELD_NODES.phone.required),
                      phoneIsh(FIELD_NODES.phone.valid)
                    )}
                    required
                    autoComplete="tel"
                    {...normalizePhoneOnBlur}
                    component={LabeledField}
                    label={FIELD_NODES.phone.label}
                    placeholder={FIELD_NODES.phone.placeholder}
                    type="tel"
                  />

                  <Field
                    className={sharedStyles.field}
                    name="email"
                    autoComplete="email"
                    validate={combineValidators(
                      required(FIELD_NODES.email.required),
                      emailIsh(FIELD_NODES.email.valid)
                    )}
                    required
                    {...trimOnBlur}
                    component={LabeledField}
                    label={FIELD_NODES.email.label}
                    placeholder={FIELD_NODES.email.placeholder}
                    type="email"
                  />
                </div>

                <div className={sharedStyles.fieldContainer}>
                  <div className={sharedStyles.spacedTiny}>
                    <Field
                      className={sharedStyles.field}
                      name="password"
                      validate={combineValidators(
                        required(FIELD_NODES.password.required),
                        validPassword(FIELD_NODES.password.valid)
                      )}
                      required
                      autoComplete="new-password"
                      component={LabeledField}
                      label={FIELD_NODES.password.label}
                      placeholder={FIELD_NODES.password.placeholder}
                      widget={PasswordInput}
                    />
                    <PasswordValidator password={values.password || ""} />
                  </div>
                </div>

                <div>
                  <Field
                    validate={requiredBoolean("error")}
                    type="checkbox"
                    name="acceptedTerms"
                    required
                  >
                    {({ input, meta }) => {
                      const error = meta.touched && meta.error;
                      return (
                        <Terms
                          label={ACCEPT_TERMS.acceptRegistrationTerms}
                          text={ACCEPT_TERMS.acceptRegistrationText}
                          input={input}
                          error={error}
                        />
                      );
                    }}
                  </Field>
                </div>
              </div>
            )}

            <ErrorsList errors={submitErrors} />

            {!signedUp && (
              <div className={sharedStyles.spacedSmall}>
                <Button
                  className={sharedStyles.field}
                  color="primary"
                  type="submit"
                  block
                  disabled={submitting}
                >
                  {NODES.signup}
                </Button>

                {after}
              </div>
            )}

            <ForceNodes>
              {NODES}
              {FIELD_NODES}
              {ERRORS}
            </ForceNodes>
          </form>
        );
      }}
    </Form>
  );
}
