import React from "react";

import { useBooking } from "..";
import usePaymentHandlers from "./usePaymentHandlers";

export default function useDefaultPaymentProvider() {
  const { psp } = useBooking();

  const fetching = React.useRef(false);
  const { onPaymentChange, initiatedPayment, pspState } = usePaymentHandlers();

  React.useEffect(() => {
    (async () => {
      if (!initiatedPayment && !pspState && psp && !fetching.current) {
        const { type: defaultPspType } = psp.find(({ enabled }) => enabled);
        fetching.current = true;
        await onPaymentChange(defaultPspType, { silent: true });
        fetching.current = false;
      }
    })();
  }, [initiatedPayment, onPaymentChange, psp, pspState]);

  return null;
}
