import { PASSENGER_TYPES } from "../consts";

export default function countsToInitialData(counts) {
  if (counts == null) {
    return undefined;
  }
  return PASSENGER_TYPES.reduce((result, type) => {
    const count = counts[type.id];
    if (count != null && count > 0) {
      result[type.param] = count;
    }
    return result;
  }, {});
}
