export default function getCheapestJourney(journey) {
  if (!journey || !journey.length) {
    return null;
  }

  const allPrices = journey
    .map(({ prices }) => {
      return Object.values(prices).map(({ current }) => current);
    })
    .flat(Infinity);

  return Math.min(...allPrices);
}
