import { addHours } from "date-fns";
import cookies from "js-cookie";

import { SESSION_COOKIE_EXTRA_LIFETIME } from "../consts";
import getBookingSessionCookieName from "./getBookingSessionCookieName";

// Save `sessionId` with the given `expires` date in a cookie.
export function saveBookingSession({ sessionId, expires }) {
  cookies.set(getBookingSessionCookieName(), `${Date.now()}|${sessionId}`, {
    expires: addHours(expires, SESSION_COOKIE_EXTRA_LIFETIME),
  });
}
