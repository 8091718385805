import { Node } from "djedi-react";
import React from "react";

export const SEATING_HEADING = (
  <Node uri="booking/session/seating/heading">Dina platser</Node>
);

export const CARRIAGE_RESERVED_FOR_EVENT = (
  <Node uri="booking/session/seating/carriage-reserved-for-event" event="">
    Reserverad för [event]
  </Node>
);
