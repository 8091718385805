import React from "react";

import { useAppContext } from "#components/AppContext";
import { HANDLED_ERROR_STATUSES } from "#containers/Booking/consts";
import { getBookingSessionIdData } from "#containers/Booking/helpers";

import { useBooking } from "..";

export default function useSessionFetchers() {
  const { api } = useAppContext();
  const { setSessionError } = useBooking();

  const getRoutes = React.useCallback(async () => {
    try {
      const routesResponse = await api.getRoutes();

      return routesResponse.data;
    } catch (error) {
      console.error(error, error.respose);

      return undefined;
    }
  }, [api]);

  const getSession = React.useCallback(async () => {
    try {
      const sessionIdData = getBookingSessionIdData();

      if (sessionIdData == null) {
        setSessionError({ status: 1404 });
        return undefined;
      }

      const { sessionId } = sessionIdData;

      const sessionResponse = await api.getBookingSession({ sessionId });

      return sessionResponse.data;
    } catch (error) {
      console.error(error, error.response);

      const status = error?.response?.status;
      if (HANDLED_ERROR_STATUSES.includes(status)) {
        setSessionError({ status });
        return undefined;
      }
      throw error;
    }
  }, [api, setSessionError]);

  const getSeating = React.useCallback(
    async (s) => {
      try {
        const { journeys } = s;
        const seatingData = [];

        for (const journey of journeys) {
          const { departures } = journey;
          const res = await Promise.all(
            departures.map((departure) =>
              api.getAvailableSeats({
                sessionId: s.id,
                departure_id: departure.id,
              })
            )
          );

          seatingData.push(res.map(({ data }) => data));
        }

        return seatingData;
      } catch (error) {
        console.error(error, error.response);

        return undefined;
      }
    },
    [api]
  );

  const getAddons = React.useCallback(
    async (s) => {
      try {
        const { journeys } = s;
        const addonsData = [];

        for (const journey of journeys) {
          const { departures } = journey;
          const res = await Promise.all(
            departures.map((departure) =>
              api.getAvailableAddons({
                sessionId: s.id,
                departure_id: departure.id,
              })
            )
          );

          addonsData.push(res.map(({ data }) => data));
        }

        return addonsData;
      } catch (error) {
        console.error(error, error.response);

        return undefined;
      }
    },
    [api]
  );

  const getPsp = React.useCallback(
    async (sessionId) => {
      try {
        const pspResponse = await api.getBookingSessionPsp({ sessionId });

        return pspResponse.data.sort((a, b) => a.order - b.order);
      } catch (error) {
        console.error(error, error.response);

        return undefined;
      }
    },
    [api]
  );

  return {
    getRoutes,
    getSession,
    getSeating,
    getAddons,
    getPsp,
  };
}
