import localStorage, { readFromLocalStorage } from "#utils/localStorage";

import { LOCAL_STORAGE_MAX_AGE } from "../consts";
import passengerFormLocalStorageKey from "./passengerFormLocalStorageKey";

export default function getLocalStoragePassengerForm() {
  const now = Date.now();

  const key = passengerFormLocalStorageKey();
  const values = readFromLocalStorage(key);
  if (
    values == null ||
    typeof values.timestamp !== "number" ||
    values.timestamp > now ||
    now - values.timestamp > LOCAL_STORAGE_MAX_AGE ||
    !Array.isArray(values.passengers)
  ) {
    if (localStorage) {
      localStorage.removeItem(key);
    }

    return undefined;
  }
  return values;
}
