import {
  addSeconds,
  differenceInDays,
  format,
  getDate,
  getYear,
  isSameMonth,
  isSameYear,
  parseISO,
} from "date-fns";
import sv from "date-fns/locale/sv";
import { locales } from "i18n";
import cookies from "js-cookie";
import Router from "next/router";

import * as dateLocales from "#components/DateChooser/locales";
import { NAV_ID } from "#components/Nav";

import { ROUTES } from "../routes";

export function matchPathname(pName) {
  return Object.values(ROUTES).find(({ pathname }) => pName === pathname);
}

export function isPathParam(str) {
  return /^\[\w+\]$/.test(str);
}

export function includesPathParam(str) {
  return /\[\w+\]/.test(str);
}

export function makeBreadcrumbs(route) {
  const routeStrings = route
    .split("/")
    .slice(1)
    .filter((str) => !isPathParam(str));

  const routesArray = Object.entries(ROUTES);

  const result = routeStrings
    .map((routeStr) => {
      const res = routesArray.find(([, value]) =>
        value.pathname.endsWith(routeStr)
      );

      if (!res) {
        return undefined;
      }

      const [routeName, routeObject] = res;

      return { route: routeName, object: routeObject };
    })
    .filter(Boolean);

  return result;
}

export function getRouteName(route) {
  const routesArray = Object.entries(ROUTES);
  const result = routesArray.find(([, value]) => value.page === route);
  return result ? result[0] : undefined;
}

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;

/**
 * Used in `getInitialProps` to abort rendering a page and instead respond with
 * the error page and a specific status code.
 *
 *     throw new HttpError({
 *       status: 404,
 *       message: `Unknown product id: ${id}`,
 *     })
 */
export class HttpError extends Error {
  constructor({ status, message }) {
    super(`HttpError: ${status}\n${message}`);
    this.status = status;
  }
}

/**
 * Adds `queryString` at the end of `url` (which might already have a query
 * string), taking care of the question mark.
 */
export function addQueryString(url, queryString) {
  const strippedUrl = url.replace(/\?$/, "");
  const separator = strippedUrl.includes("?") ? "&" : "?";
  return queryString === "" ? url : `${strippedUrl}${separator}${queryString}`;
}

/**
 * Remove all keys whose values are undefined or null from an object.
 */
export function deleteNil(object) {
  return Object.entries(object).reduce((result, [key, value]) => {
    if (value != null) {
      result[key] = value;
    }
    return result;
  }, {});
}

/**
 * Returns a template tag that escapes all interpolations using `fn`. Example
 * usage:
 *
 *     escape(encodeURIComponent)`/users/${user}`
 */
export function escape(fn) {
  // There’s always one more literal than interpolations.
  return (literals, ...interpolations) =>
    interpolations
      .map((interpolation, index) => `${literals[index]}${fn(interpolation)}`)
      .concat(literals[literals.length - 1])
      .join("");
}

/**
 * Formats a duration in milliseconds as "HH:mm".
 */
export function formatDuration(milliseconds) {
  const hours = Math.floor(milliseconds / HOUR);
  const rest = milliseconds - hours * HOUR;
  const minutes = Math.floor(rest / MINUTE);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
}

/**
 * Checks whether a string is a sequence of digits, and nothing else.
 */
export function isDigits(string) {
  return /^\d+$/.test(string);
}

/**
 * Checks whether a string is a "YYYY-MM-DD" date string.
 */
export function isISODateString(string) {
  return /^\d{4}-\d{2}-\d{2}$/.test(string);
}

/**
 * Turns an object with string, undefined or array-of-string values to a URL
 * query string.
 */
export function makeQueryString(data) {
  return []
    .concat(
      ...Object.entries(data).map(([key, value]) =>
        value == null
          ? []
          : Array.isArray(value)
          ? value.map((subValue) => [key, subValue])
          : [[key, value]]
      )
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    )
    .join("&");
}

/**
 * Because `noop` is nicer to read than `() => {}`.
 */
export function noop() {
  // Do nothing.
}

/**
 * Parses an ISO 8601 datetime string into an object.
 *
 * For example, `2018-12-19T12:00+01:00` is parsed into:
 *
 *     {
 *       date: "2018-12-19",
 *       time: "12:00",
 *     }
 *
 * In other words, the date and time components are split up, and they are kept
 * in the timezone specified in the ISO datetime string. Note that seconds and
 * fractions of seconds are discarded.
 *
 * The API returns departure and arrival times as ISO datetime strings in the
 * Sweden’s timezone, and we want to show all times in that timezone since the
 * trains operate in Sweden.
 *
 * The easiest way to achieve this (getting the date and time of the ISO string,
 * without changing time zones) is to slice the parts out.
 */
export function parseDateTimeString(isoString) {
  return {
    date: isoString.slice(0, 10),
    time: isoString.slice(11, 16),
  };
}

const COMMENT_REGEX = /^\s*\|/;
const LINK_REGEX = /^\s*(.+?)\s*\|\s*([^|\s]+)\s*$/;

/* Parses the content of a Djedi node into an array of links.

Example:

    | Comment
    Link text | https://example.com
    Internal | /sv/villkor

The above is parsed into:

    [
      {
        text: "Link text",
        url: "https://example.com"
      },
      {
        text: "Internal",
        url: { // Next.js `<Link>` props }
      },
    ]
*/
export function parseDjediLinks(content) {
  return content
    .split("\n")
    .map((line) => {
      const match = LINK_REGEX.exec(line);
      return COMMENT_REGEX.test(line)
        ? undefined
        : match != null
        ? { text: match[1], url: resolveDjediLink(match[2]) }
        : undefined;
    })
    .filter(Boolean);
}

function resolveDjediLink(url) {
  let urlWithoutLanguage = url;
  locales.forEach((locale) => {
    urlWithoutLanguage = urlWithoutLanguage.replace(`${locale}/`, "");
  });

  const routeMatch = Object.values(ROUTES).find(
    (routeObject) => routeObject.pathname === urlWithoutLanguage
  );

  return routeMatch ? { href: { pathname: routeMatch.pathname } } : url;
}

/**
 * Returns `[fn(0), fn(1), fn(2), ..., fn(n - 1)]`.
 */
export function range(n, fn) {
  return Array.from({ length: n }, (__, i) => fn(i));
}

/**
 * Redirects to `route` (with `params`), dealing with both server-side and
 * browser-side.
 */
export function redirect(res, { pathname, query = {} } = {}) {
  if (res != null) {
    res.writeHead(302, {
      Location: addQueryString(pathname, makeQueryString(query)),
    });
    res.end();
  } else {
    Router.push({ pathname, query });
  }
}

/**
 * Turns a `Date` instance into "YYYY-MM-DD" – in **Sweden.**
 * This is a bit of a nasty hack.
 *
 * MTR has actual customers booking from the USA and other parts of the world.
 * So making sure that dates are parsed and shown as Swedish dates is a bit
 * tricky.
 */
export function toISODateString(date) {
  // Sweden is UTC+1, and UTC+2 in the summer. But it’s hard to know if Sweden
  // currently has daylight saving time (DST).
  //
  // Here’s an example to get us started:
  // 2019-06-01T00:00+02:00 is 2019-05-31T22:00Z (UTC).
  //
  // If we first add 2 hours we can use `.toISOString()`, which uses UTC, to get
  // the date in Sweden.
  // 2019-06-01T00:00+02:00 → 2019-06-01T02:00+02:00 → 2019-06-01T00:00Z
  //
  // Luckily, adding 2 hours in the winter usually works as well, so we can
  // avoid the tricky problem of knowing whether Sweden currently has DST. It
  // does fail close to midnight, though:
  // 2019-01-01T23:00+01:00 → 2019-01-02T01:00+01:00 → 2019-01-02T00:00Z
  //
  // But – I don’t think we ever pass in dates with times 23:00 or later.
  // Usually, if not always, the dates coming in here have the time "00:00". So
  // we should be fine. But we probably want to look over the date handling in
  // the entire frontend at some point, doing it in a more robust way.
  const copy = new Date(date);
  copy.setHours(copy.getHours() + 2);
  return copy.toISOString().slice(0, 10);
}

/**
 * A template tag that contructs URLs by escaping all interpolations using
 * `encodeURIComponent`. Example usage:
 *
 *     url`/users/${user}`
 */
export const url = escape(encodeURIComponent);

export const parseTime = ({ date, time }) => new Date(`${date}T${time}Z`);

export function calculateDuration(departureDateTime, arrivalDateTime) {
  // This turns a `{ date, time }` object into a `Date` instance – but with the
  // wrong time zone (always UTC). But that doesn’t matter since we’re only
  // interested in the _difference_ between two dates.
  return parseTime(arrivalDateTime) - parseTime(departureDateTime);
}

export function fullNameOrEmail(user) {
  if (!user) {
    return undefined;
  }
  if (user.firstname && user.lastname) {
    return `${user.firstname} ${user.lastname}`;
  }
  return user.email;
}

export const percentageFromFraction = (nr) => `${Number(nr).toFixed(1) * 100}%`;

export const fullDaysFromSeconds = (seconds) =>
  differenceInDays(addSeconds(new Date(0), seconds), new Date(0));

export const capitalizeFirstLetter = (s) =>
  s ? s.charAt(0).toUpperCase() + s.slice(1) : "";

export function toDurationString(passedFrom, passedTo, localized = "sv") {
  if (!passedFrom || !passedTo) {
    return undefined;
  }

  const from = parseISO(passedFrom);
  const to = parseISO(passedTo);
  const yearAlike = isSameYear(from, to);
  const monthAlike = isSameMonth(from, to);
  const fullFormatStr = "d MMMM yyyy";
  const monthYearStr = "d MMMM";

  // localized format func
  const lf =
    localized === "sv"
      ? (d, dstr) => format(d, dstr, { locale: sv, localize: sv })
      : format;

  if (monthAlike && yearAlike) {
    return `${getDate(from)} - ${getDate(to)} ${lf(to, "MMMM yyyy")}`;
  }

  if (yearAlike) {
    return `${lf(from, monthYearStr)} - ${lf(to, monthYearStr)} ${getYear(to)}`;
  }

  return `${lf(from, fullFormatStr)} - ${lf(to, fullFormatStr)}`;
}

// (try to) smooth scroll to an element
export const scrollToElement = ({
  element,
  offset: extraOffset = 0,
  timeout = 0,
  behavior = "smooth",
  scrollSelector,
}) => {
  const offset =
    (document.getElementById(NAV_ID)?.clientHeight || 0) + extraOffset;

  setTimeout(() => {
    const elementOffset = element?.current?.offsetTop || element?.offsetTop;

    if (elementOffset == null) {
      return;
    }
    (document.querySelector(scrollSelector) || window).scrollTo({
      top: elementOffset - offset,
      left: 0,
      behavior,
    });
  }, timeout);
};

// Turns an array of tickets into an array with
// [[carriage][seat numbers]]. Useful for displaying
// seating per carriage.
export function getCarriagesWithSeats(tickets) {
  return Object.entries(
    tickets
      .filter(({ seats }) => seats.length > 0)
      .reduce((obj, { seats }) => {
        const { carriage } = seats[0];
        if (obj[carriage] == null) {
          return {
            ...obj,
            [carriage]: seats.map(({ display }) => display),
          };
        }
        return {
          ...obj,
          [carriage]: [
            ...obj[carriage],
            ...seats.map(({ display }) => display),
          ],
        };
      }, {})
  );
}

// Turns a an array of numbers into chunks and joins
// them together. [1,2,3,6,7,10] becomes [[1,2,3],[6,7],[10]]
// and then joins each sub-array into strings of min-max, or just
// the single number is it's not a sequence. The above would become:
// "1-3, 6-7, 10".
export function chunkSeats(passedSeats) {
  const seats = passedSeats.sort((a, b) => a - b);
  const chunks = [];
  let chunk = [];
  for (const seatNum of seats) {
    const after = seats.includes(seatNum + 1);
    const before = seats.includes(seatNum - 1);

    if (before || after) {
      chunk.push(seatNum);
    }

    if (before && !after) {
      chunks.push(chunk);
      chunk = [];
    }

    if (!before && !after) {
      chunks.push([seatNum]);
    }
  }
  return chunks
    .map((result) => {
      const min = Math.min(...result);
      const max = Math.max(...result);
      return min !== max ? `${min}-${max}` : min;
    })
    .join(", ");
}

export function djediNodeToList(content) {
  return content.replace(/(<([^>]+)>)/gi, "").split("\n");
}

/** Remove trailing decimals for money */

export function formatMoney(money) {
  return Number(money) % 1 ? `${money}:-` : `${parseInt(money, 10)}:-`;
}

export function commaJoinArray(array) {
  return array.reduce((arr, item, index) => {
    if (index === 0) {
      return [...arr, item];
    }
    return [...arr, ", ", item];
  }, []);
}

export function cookiesEnabled() {
  const name = "test-cookie";
  const value = "test-value";
  cookies.remove(name);
  cookies.set(name, value);
  const enabled = cookies.get(name) === value;
  cookies.remove(name);
  return enabled;
}

export function onMobileDevice(userAgent) {
  if (
    userAgent.match(/Android/i) ||
    userAgent.match(/iPhone/i) ||
    userAgent.match(/iPad/i) ||
    userAgent.match(/iPod/i)
  ) {
    return true;
  }
  return false;
}

export function escapeTime(timeString) {
  return timeString.replace(/:/g, ".");
}

export function deleteKeys(obj, keys) {
  const copy = { ...obj };

  for (const key in copy) {
    if (keys.includes(key)) {
      delete copy[key];
    }
  }

  return copy;
}

export function formatDate(date, language, short = false) {
  // eslint-disable-next-line import/namespace
  const locale = dateLocales[language] || dateLocales.en;
  const month = locale.months[date.getUTCMonth()] || "?";
  return `${date.getUTCDate()} ${
    language === "sv" ? month.slice(0, 3).toLowerCase() : month.slice(0, 3)
  }${!short ? ` ${date.getUTCFullYear()}` : ""}`;
}

export function formatDateLong(date, language) {
  // eslint-disable-next-line import/namespace
  const locale = dateLocales[language] || dateLocales.en;
  const day = locale.weekdaysLong[date.getUTCDay()] || "?";
  const month = locale.months[date.getUTCMonth()] || "?";
  return `${day} ${date.getUTCDate()} ${
    language === "sv" ? month.toLowerCase() : month
  } ${date.getUTCFullYear()}`;
}

export function constructDate({ date, time }) {
  return new Date(`${date}T${time}Z`);
}

export function deleteCookie(res, cookieName) {
  if (res != null) {
    res.clearCookie(cookieName);
  } else {
    cookies.remove(cookieName);
  }
}
