import { startOfMonth } from "date-fns";

import { isValidTrip } from "#components/SearchTrip";
import { toISODateString } from "#utils/";

import { STAFF_MAX_COUNTS } from "../consts";
import countsFromInitialData from "./countsFromInitialData";
import countsToPassengersArray from "./countsToPassengersArray";
import extrasFromInitialData from "./extrasFromInitialData";
import getFromTo from "./getFromTo";
import getStops from "./getStops";
import makeRoutesMap from "./makeRoutesMap";
import mergeRoutes from "./mergeRoutes";
import orderStations from "./orderStations";
import validateSelectedDates from "./validateSelectedDates";

export default function getCommonBookingParams({
  routes,
  voucher,
  event,
  data,
  canMakeGroupBooking,
}) {
  const {
    from: fromId,
    to: toId,
    date: dateString,
    "date-back": dateBackString,
  } = data;

  const today = new Date();
  const routesMap = makeRoutesMap(routes);
  const stopsUnordered = mergeRoutes(routes);
  const stops = orderStations(stopsUnordered, routes);

  const maybeFrom = stops.find(({ id }) => id === fromId);
  const maybeTo = stops.find(({ id }) => id === toId);

  const stopsFrom = !voucher
    ? stops
    : getStops(routesMap, voucher.from_stops, stops);
  const stopsTo = !voucher
    ? stops
    : getStops(routesMap, voucher.to_stops, stops);

  const validTrip = isValidTrip({
    from: maybeFrom?.id,
    to: maybeTo?.id,
    stopsFrom,
    stopsTo,
  });

  const { from, to } = validTrip
    ? {
        from: { name: maybeFrom.name, id: maybeFrom.id },
        to: { name: maybeTo.name, id: maybeTo.id },
      }
    : getFromTo({ voucher, stopsFrom, stopsTo });

  const isoToday = toISODateString(today);

  // Today, at 00:00 (just like all other dates in this file).
  const { outboundDate, inboundDate, minDate, maxDate } = validateSelectedDates(
    {
      voucher,
      event,
      dateString: dateString || isoToday,
      dateBackString: dateBackString || isoToday,
    }
  );

  const counts = countsFromInitialData(
    data,
    canMakeGroupBooking ? STAFF_MAX_COUNTS : voucher?.passenger_type_limits,
    canMakeGroupBooking
  );
  const passengers = countsToPassengersArray(counts);
  const extras = extrasFromInitialData(data, passengers.length);

  const outbound = {
    date: outboundDate,
    month: startOfMonth(outboundDate),
  };
  const inbound = {
    date: inboundDate,
    month: startOfMonth(inboundDate),
  };
  const showInbound = Boolean(data["date-back"]);

  return {
    counts,
    extras,
    from,
    inbound,
    inboundDate,
    maxDate,
    minDate,
    outbound,
    outboundDate,
    routesMap,
    showInbound,
    stops,
    stopsFrom,
    stopsTo,
    to,
  };
}
