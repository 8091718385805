import { Node } from "djedi-react";
import React from "react";

export const SEARCH = (
  <Node uri="booking/continue/to-departures">Sök resa</Node>
);

export const SEARCH_HEADING = <Node uri="booking/search-trip">Sök resa</Node>;

export const SEARCH_HEADING_REBOOK = (
  <Node uri="booking/search-trip-rebook" bookingRef="-1">
    Omboka resa ([bookingRef])
  </Node>
);
