import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import CheckIcon from "#icons/check.svg";

import styles from "./Checkbox.module.css";

Checkbox.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["regular", "white"]),
  // ...restProps
};

Checkbox.defaultProps = {
  className: "",
  variant: "regular",
};

export default function Checkbox({ className, variant, ...restProps }) {
  return (
    <span
      className={classnames(
        styles.root,
        {
          [styles.whiteRoot]: variant === "white",
        },
        className
      )}
    >
      <input type="checkbox" className={styles.input} {...restProps} />
      <span
        className={classnames(styles.checkbox, {
          [styles.whiteCheckbox]: variant === "white",
        })}
      >
        <CheckIcon className={styles.checkIcon} />
      </span>
    </span>
  );
}
