import React from "react";

import { useAppContext } from "#components/AppContext";

import { useBooking } from "..";

// Prefer to have favorite passengers outside of useSessionData because
// we don't need it to render.
export default function useFavoritePassengers() {
  const { user, api } = useAppContext();
  const { setFavoritePassengers } = useBooking();

  React.useEffect(() => {
    (async () => {
      if (user) {
        try {
          const favoritePassengersResponse = await api.getFavoritePassengers();
          setFavoritePassengers(favoritePassengersResponse.data);
        } catch (error) {
          console.error(error, error.response);
          setFavoritePassengers([]);
        }
      }
    })();
  }, [setFavoritePassengers, user, api]);

  return undefined;
}
