import React from "react";

import Container from "#components/Container";
import Countdown from "#components/Countdown";
import Text from "#components/Text";
import { useBooking } from "#containers/Booking/BookingContext";

import { TIMEOUT_MESSAGES } from "../../nodes";
import styles from "./SessionTimer.module.css";

export default function SessionTimer() {
  const { session } = useBooking();

  return (
    <div className={styles.bookingTimer}>
      <Container>
        <div className={styles.bookingTimerInner}>
          <Text>{React.cloneElement(TIMEOUT_MESSAGES.warning)}</Text>
          <div className={styles.bookingTimerTime}>
            <Countdown to={new Date(session.validUntil.localTimestamp)} />
          </div>
        </div>
      </Container>
    </div>
  );
}
