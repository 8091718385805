export default function makeJourney({ journey, bookingClass }, rebookAddons) {
  const rebookAddonsPricing = rebookAddons
    ? Object.values(rebookAddons).reduce((acc, { price }) => acc + price, 0)
    : 0;

  return journey?.id
    ? {
        ...journey,
        // put the class on the first ticket of each departure. The one used to check the class on a departure.
        // The class is always the same for a whole journey
        departures: journey.departures.map((dep) => ({
          ...dep,
          tickets: [{ booking_class: bookingClass }],
        })),
        price: bookingClass
          ? journey.prices?.[`${bookingClass}`]?.current + rebookAddonsPricing
          : undefined,
      }
    : {};
}
