import { isSameDay } from "date-fns";
import { Node } from "djedi-react";
import cookies from "js-cookie";
import React from "react";

import { useAppContext } from "#components/AppContext";
import { renderBarebones } from "#components/Modal/helpers";
import Modal from "#components/Modal/warning";
import { VOUCHER_COOKIE_NAME } from "#containers/Booking/consts";
import {
  VOUCHER_FIELD_ERRORS,
  VOUCHER_FIELD_NAME,
} from "#containers/Booking/steps/Search/partials/AddVoucher";
import { toISODateString } from "#utils/";

import validateSelectedDates from "../../helpers/validateSelectedDates";
import { useBooking } from "..";

const NODES = {
  TITLE: <Node uri="home/warning-modal/title">Vill du gå vidare?</Node>,
  ON_CONFIRM: (
    <Node uri="home/warning-modal/on-confirm">Fortsätt utan kod</Node>
  ),
  CLOSE: <Node uri="home/warning-modal/close">Ändra resval</Node>,
  CONTENT_STATIONS_INVALID: (
    <Node uri="home/warning-modal/content">
      Stationerna du har valt ingår inte i din biljett. Om du går vidare så
      kommer dina val att ändras till de som ingår i din biljett.
    </Node>
  ),
  CONTENT_DAYS_INVALID: (
    <Node uri="home/warning-modal/content-days-invalid">
      Dagarna du har valt ingår inte i din biljett. Om du går vidare så kommer
      dina val att ändras till de som ingår i din biljett.
    </Node>
  ),
};

export default function useVoucherHandlers() {
  const { api } = useAppContext();
  const { setLoading, setVoucher, booking, forward } = useBooking();

  const validateVoucher = async (voucher) => {
    const { from_stops: fromStops, to_stops: toStops } = voucher;

    const { from, to, outbound, inbound, showInbound } = booking;

    const { outboundDate, inboundDate } = validateSelectedDates({
      voucher,
      dateString: toISODateString(outbound.date),
      dateBackString: toISODateString(inbound.date),
    });

    const selectedDaysValid =
      isSameDay(outboundDate, outbound.date) &&
      (!showInbound ? true : isSameDay(inboundDate, inbound.date));

    const selectedStationsValid =
      (toStops.length === 0 && fromStops.length === 0) ||
      (fromStops.some((stop) => stop.id === from.id) &&
        toStops.some((stop) => stop.id === to.id));

    return new Promise((resolve) => {
      if (selectedDaysValid && selectedStationsValid) {
        resolve(undefined);
        return;
      }

      const { close } = renderBarebones({
        children: (
          <Modal
            isOpen={true}
            title={NODES.TITLE}
            onRequestClose={() => {
              resolve(false);
              close();
            }}
            onConfirm={() => {
              resolve(true);
              close();
            }}
            onConfirmText={NODES.ON_CONFIRM}
            onRequestCloseText={NODES.CLOSE}
          >
            {!selectedDaysValid
              ? NODES.CONTENT_DAYS_INVALID
              : NODES.CONTENT_STATIONS_INVALID}
          </Modal>
        ),
      });
    });
  };

  const onRemoveVoucher = async () => {
    cookies.remove(VOUCHER_COOKIE_NAME);
    setVoucher(null);
  };

  const onSubmitVoucher = async ({ code }) => {
    setLoading(true);

    let voucher = undefined;

    try {
      if (code !== "") {
        const codeResponse = await api.getVoucher({ code: code.trim() });
        voucher = codeResponse.data;
      }
    } catch (e) {
      setLoading(false);

      const getError = (response) => {
        switch (response.status) {
          case 404:
            return "not_found";
          case 412:
            // "max_used" | "expired" | "consumed"
            return response.data.code;
          case 429:
            return "too_many_requests";
          default:
            return "other";
        }
      };

      return {
        [VOUCHER_FIELD_NAME]: [
          VOUCHER_FIELD_ERRORS[getError(e.response)] ||
            VOUCHER_FIELD_ERRORS.other,
        ],
      };
    }

    const submitResponse = await validateVoucher(voucher);
    setLoading(false);
    if (submitResponse === false) {
      return undefined;
    }

    // If there already was a saved voucher and the user cleared it out and
    // pressed "Save" – remove the cookie.
    if (submitResponse == null) {
      if (code === "") {
        cookies.remove(VOUCHER_COOKIE_NAME);
      } else {
        cookies.set(VOUCHER_COOKIE_NAME, code);
        setVoucher(voucher);
      }
    }

    if (submitResponse) {
      forward();
    }

    return undefined;
  };

  return {
    validateVoucher,
    onRemoveVoucher,
    onSubmitVoucher,
  };
}
