import cookies from "js-cookie";

import getBookingSessionCookieName from "./getBookingSessionCookieName";

export default function getBookingSessionIdData() {
  const allCookies = cookies.get() || {};

  const cookieName = getBookingSessionCookieName();
  const value = allCookies[cookieName];

  if (value == null) {
    return undefined;
  }

  // Format: timestamp|sessionId
  const match = /^(\d+)\|(.+)$/.exec(value);

  if (match == null) {
    cookies.remove(cookieName);
    return undefined;
  }

  const [, timestamp, sessionId] = match;

  return { sessionId, timestamp: Number(timestamp), cookieName };
}
