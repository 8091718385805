import classnames from "classnames";
import { ForceNodes, md, Node } from "djedi-react";
import { FORM_ERROR } from "final-form";
import createFocusDecorator from "final-form-focus";
import { useRouter } from "next/router";
import NProgress from "nprogress";
import PropTypes from "prop-types";
import React from "react";
import { Field, Form } from "react-final-form";
import { ROUTES } from "routes";

import {
  combineValidators,
  emailIsh,
  required,
  trimOnBlur,
} from "#utils/forms";

import { Sentry } from "../../sentry";
import AppContext from "../AppContext";
import Button from "../Button";
import ErrorsList from "../ErrorsList";
import LabeledField from "../LabeledField";
import PasswordInput from "../PasswordInput";
import StyledLink from "../StyledLink";
import sharedStyles from "./LoginForm.module.css";

export const LOGIN_NODE = <Node uri="LoginForm/login">Logga in</Node>;
export const RESET_PASSWORD = (
  <Node uri="LoginForm/reset-password">Återställ ditt lösenord</Node>
);

const NODES = {
  login: LOGIN_NODE,
  forgot: <Node uri="LoginForm/forgot-password">Glömt ditt lösenord?</Node>,
  reset: RESET_PASSWORD,
  resetSuccess: (
    <Node uri="LoginForm/reset-success.md" email="?">{md`
      Ett återställningsmail har skickats till:<br>[email]
    `}</Node>
  ),
  cancelReset: <Node uri="LoginForm/cancel-reset">Tillbaka</Node>,
};

const FIELD_NODES = {
  email: {
    label: <Node uri="LoginForm/email/label">E-postadress</Node>,
    placeholder: <Node uri="LoginForm/email/placeholder">din@email.se</Node>,
    required: <Node uri="LoginForm/email/required">Ange en e-postadress</Node>,
    valid: <Node uri="LoginForm/email/valid">Ange en giltig e-postadress</Node>,
  },
  password: {
    label: <Node uri="LoginForm/password/label">Lösenord</Node>,
    placeholder: <Node uri="LoginForm/password/placeholder">************</Node>,
    required: <Node uri="LoginForm/password/required">Ange ett lösenord</Node>,
  },
};

const ERRORS = {
  login: {
    other: (
      <Node uri="LoginForm/error/login/other" status="-1">
        Misslyckades att logga in ([status])
      </Node>
    ),
    network: (
      <Node uri="LoginForm/error/login/network">
        Misslyckades att logga in. Kontrollera din Internet-anslutning!
      </Node>
    ),
  },
  reset: {
    other: (
      <Node uri="LoginForm/error/reset/other" status="-1">
        Misslyckades att återställa lösenord ([status])
      </Node>
    ),
    network: (
      <Node uri="LoginForm/error/reset/network">
        Misslyckades att återställa lösenord. Kontrollera din
        Internet-anslutning!
      </Node>
    ),
  },
};

const focusOnErrors = createFocusDecorator();

LoginForm.propTypes = {
  setResetPasswordState: PropTypes.func,
  onResetPasswordChange: PropTypes.func,
  after: PropTypes.node,
  className: PropTypes.string,
  onLogin: PropTypes.func,
  resetPassword: PropTypes.bool,
};

LoginForm.defaultProps = {
  onResetPasswordChange: undefined,
  after: undefined,
  className: "",
  onLogin: undefined,
  resetPassword: false,
  setResetPasswordState: () => undefined,
};

export default function LoginForm({
  onResetPasswordChange,
  after,
  onLogin,
  className,
  resetPassword,
  setResetPasswordState,
}) {
  const { api, login } = React.useContext(AppContext);
  const router = useRouter();
  const { next } = router.query;

  const [emailSent, setEmailSent] = React.useState(false);

  const isLoginPage = router.pathname === ROUTES.login.pathname;

  const rerouteTo =
    next || (isLoginPage ? ROUTES.account.pathname : router.asPath);

  return (
    <Form
      onSubmit={async ({ email, password }) => {
        NProgress.start();
        try {
          if (resetPassword) {
            await api.forgotPassword({ email });
            setEmailSent(true);
            NProgress.done();
          } else {
            await login({ email, password });

            if (typeof onLogin === "function") {
              onLogin();
            }

            await router.replace(rerouteTo);
            NProgress.done();
          }

          return undefined;
        } catch (error) {
          console.error("LoginForm: Failed to login", error, error.response);
          Sentry.captureException(error);
          NProgress.done();

          const errorNodes = resetPassword ? ERRORS.reset : ERRORS.login;

          if (error.response != null) {
            const { status, data } = error.response;

            if (data != null) {
              return data;
            }

            if (status != null) {
              return {
                [FORM_ERROR]: [
                  React.cloneElement(errorNodes.other, { status }),
                ],
              };
            }

            if (error.noResponse) {
              return {
                [FORM_ERROR]: [errorNodes.other],
              };
            }
          }

          return {
            [FORM_ERROR]: [
              React.cloneElement(errorNodes.other, { status: 1500 }),
            ],
          };
        }
      }}
      decorators={[focusOnErrors]}
    >
      {({
        handleSubmit,
        submitting,
        submitError: submitErrors = [],
        form,
        values,
      }) => {
        function setResetPassword(value) {
          setResetPasswordState(value);
          setEmailSent(false);
          // Remove all error messages.
          form.reset(values);
          if (onResetPasswordChange != null) {
            onResetPasswordChange(value);
          }
        }

        return (
          <form
            onSubmit={handleSubmit}
            noValidate
            className={classnames(
              sharedStyles.form,
              sharedStyles.spaced,
              className
            )}
          >
            {emailSent ? (
              <div className={sharedStyles.center}>
                {React.cloneElement(NODES.resetSuccess, {
                  email: values.email,
                })}
              </div>
            ) : (
              <div className={sharedStyles.fieldContainer}>
                <Field
                  className={sharedStyles.field}
                  name="email"
                  validate={combineValidators(
                    required(FIELD_NODES.email.required),
                    emailIsh(FIELD_NODES.email.valid)
                  )}
                  required
                  {...trimOnBlur}
                  component={LabeledField}
                  label={FIELD_NODES.email.label}
                  placeholder={FIELD_NODES.email.placeholder}
                  type="email"
                  autoFocus
                />

                {!resetPassword && (
                  <div className={sharedStyles.spacedTiny}>
                    <Field
                      className={sharedStyles.field}
                      name="password"
                      validate={required(FIELD_NODES.password.required)}
                      required
                      component={LabeledField}
                      label={FIELD_NODES.password.label}
                      placeholder={FIELD_NODES.password.placeholder}
                      widget={PasswordInput}
                    />

                    <p>
                      <StyledLink
                        onClick={() => {
                          setResetPassword(true);
                        }}
                      >
                        {NODES.forgot}
                      </StyledLink>
                    </p>
                  </div>
                )}
              </div>
            )}

            <ErrorsList errors={submitErrors} />

            <div className={sharedStyles.spacedSmall}>
              {!emailSent && (
                <Button
                  className={sharedStyles.field}
                  type="submit"
                  block
                  color="primary"
                  disabled={submitting}
                  data-cy="login-button"
                >
                  {resetPassword ? NODES.reset : NODES.login}
                </Button>
              )}

              {resetPassword ? (
                <p className={sharedStyles.center}>
                  <StyledLink
                    onClick={() => {
                      setResetPassword(false);
                    }}
                  >
                    {NODES.cancelReset}
                  </StyledLink>
                </p>
              ) : (
                after
              )}
            </div>

            <ForceNodes>
              {NODES}
              {FIELD_NODES}
              {ERRORS}
            </ForceNodes>
          </form>
        );
      }}
    </Form>
  );
}
