import { TicketStatus } from "#utils/enums";

export default function countRebookTickets(journey) {
  return journey.departures[0].tickets.reduce(
    (counts, ticket) =>
      ticket.status === TicketStatus.NOT_CANCELED ? Number(counts) + 1 : counts,
    [],
    0
  );
}
