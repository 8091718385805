import { ticketsFromJourney } from "#containers/Booking/helpers";
import {
  AddonsType,
  BookingClass,
  getKey,
  MealSubTypes,
  TRAFFIC_PROVIDERS,
  VEHICLE_TYPES,
} from "#utils/enums";

export function analytics(...args) {
  if (typeof window !== "undefined" && window.ga != null) {
    window.ga(...args);
  } else {
    // eslint-disable-next-line
    console.log("ga:", ...args);
  }
}

const collectTransportTypes = (departures) => {
  return Array.from(
    new Set(
      departures.map(({ transport_type }) =>
        getKey(VEHICLE_TYPES, transport_type)
      )
    )
  );
};
const collectProviders = (departures) => {
  return Array.from(
    new Set(
      departures.map(({ traffic_provider }) =>
        getKey(TRAFFIC_PROVIDERS, traffic_provider)
      )
    )
  );
};

/* eslint-disable prefer-rest-params */
/*
The official snippet looks like this:

    function gtag() {
      dataLayer.push(arguments);
    }

So we’re using `arguments` here too just to be sure. `...args` would an Array,
while `arguments` is an “arguments object” and Google might check specifically
for that.
*/
export function dataLayerPush(arg) {
  if (typeof window !== "undefined" && window.dataLayer != null) {
    window.dataLayer.push(arg);
  } else {
    // eslint-disable-next-line
    console.log("dataLayer.push:", arg);
  }
}

function getTrackableTicketCommons(journey, inbound) {
  const brand = getKey(
    BookingClass,
    journey.departures[0].tickets[0].booking_class
  );
  return {
    name: `${journey.origin.name} - ${journey.destination.name}`,
    variant: `${journey.departure_at.time} - ${journey.arrival_at.time}`,
    dimension1: collectTransportTypes(journey.departures).join(","), // transport_types
    dimension2: journey.departures.length, // number of departures
    dimension3: collectProviders(journey.departures).join(","), // providers
    category: inbound ? "inbound" : "outbound",
    brand,
  };
}

export function createTrackableTicketsWithSeats(journey, inbound) {
  const commons = getTrackableTicketCommons(journey, inbound);
  const tickets = ticketsFromJourney(journey);
  return tickets.map((ticket) => ({
    ...commons,
    id: ticket.id,
    dimension4: ticket.seats
      .map(
        ({ number, carriage }) => `${inbound ? "R" : "D"}C${carriage}S${number}`
      )
      .join(","), // DC/RC - departure/return car, S - seat
    price: ticket.price,
  }));
}

export function createTrackableTicketsWithAddons(journey, inbound) {
  const { name, category, variant, ...commons } = getTrackableTicketCommons(
    journey,
    inbound
  );
  const tickets = ticketsFromJourney(journey);
  return (
    tickets.map((ticket) => {
      const meals = ticket.addons.filter(
        ({ type }) => type === AddonsType.MEAL
      );

      return [
        {
          ...commons,
          name,
          id: ticket.id,
          price: ticket.price,
          variant,
          category,
        },
        meals.map((meal) => ({
          name: meal.name,
          id: meal.id,
          price: Number(meal.price.amount),
          variant: getKey(MealSubTypes, meal.subtype),
          category: "meal",
        })) || [],
      ];
    }) || []
  ).flat(Infinity);
}

export function createTrackableJourney(journey, classIndex, inbound) {
  return {
    name: `${journey?.origin?.name} - ${journey.destination.name}`,
    variant: `${journey.departure_at.time} - ${journey.arrival_at.time}`,
    id: journey.id,
    dimension1: collectTransportTypes(journey.departures).join(","), // transport_types
    dimension2: journey.departures.length, // departures
    dimension3: collectProviders(journey.departures).join(","), // providers
    price: journey.prices[classIndex]?.current,
    category: inbound ? "inbound" : "outbound",
    brand: getKey(BookingClass, Number(classIndex)),
  };
}

export function createTrackableJourneys(journeys, inbound, extra) {
  return journeys
    .map((journey, index) => {
      const {
        prices,
        departures,
        origin,
        id,
        arrival_at,
        departure_at,
        destination,
      } = journey;
      return Object.entries(prices).map(([classIndex, price]) => ({
        ...extra,
        id,
        name: `${origin.name} - ${destination.name}`,
        variant: `${departure_at.time} - ${arrival_at.time}`,
        dimension1: collectTransportTypes(departures).join(","), // transport_types
        dimension2: departures.length, // departures
        dimension3: collectProviders(departures).join(","), // providers
        price: price.current,
        category: inbound ? "inbound" : "outbound",
        brand: getKey(BookingClass, Number(classIndex)),
        position: index,
      }));
    }, [])
    .flat(Infinity);
}
