import React from "react";

import {
  BOOKING_CONTAINER_ID,
  MESSAGE_BANNER_ID,
} from "#containers/Booking/consts";

import { useBooking } from "..";

export default function useGetScrollToTop() {
  const { layout } = useBooking();
  const { scrollSelector, navSelector } = layout;

  const scrollToTop = React.useCallback(() => {
    const scrollContainer = document.querySelector(scrollSelector) || window;
    const bookingContainer = document.getElementById(BOOKING_CONTAINER_ID);
    const messageBanner = document.getElementById(MESSAGE_BANNER_ID);

    const offsetTop = bookingContainer?.offsetTop || 0;

    const messageBannerHeight = messageBanner?.clientHeight || 0;

    const navHeight = document.querySelector(navSelector)?.clientHeight || 0;

    const scrollY = offsetTop - navHeight - messageBannerHeight;

    setTimeout(() => {
      scrollContainer.scrollTo(0, scrollY);
    }, 0);
  }, [navSelector, scrollSelector]);

  return scrollToTop;
}
