import classnames from "classnames";
import { Node } from "djedi-react";
import PropTypes from "prop-types";
import React from "react";

import Check from "#icons/mtrx-check.svg";

import styles from "./PasswordValidator.module.css";

const MIN_LENGTH = 8;

PasswordValidator.propTypes = {
  password: PropTypes.string.isRequired,
};

export default function PasswordValidator({ password }) {
  return (
    <ul className={styles.root}>
      <Item checked={password.length >= MIN_LENGTH}>
        <Node uri="PasswordValidator/min-length">Minst 8 tecken.</Node>
      </Item>
    </ul>
  );
}

Item.propTypes = {
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

function Item({ checked, children }) {
  return (
    <li className={classnames({ [styles.checked]: checked })}>
      {children}
      {checked && (
        <>
          {" "}
          <Check />
        </>
      )}
    </li>
  );
}

export function validPassword(message) {
  return (value) =>
    value != null && value.length >= MIN_LENGTH ? undefined : message;
}
