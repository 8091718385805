import { deleteNil, escapeTime } from "#utils/";
import { BookingClass, getKey } from "#utils/enums";

import { Steps } from "../consts";
import bookingClassFromJourney from "./bookingClassFromJourney";
import countsFromPassengers from "./countsFromPassengers";
import countsToInitialData from "./countsToInitialData";
import extrasFromPassengers from "./extrasFromPassengers";
import extrasToInitialData from "./extrasToInitialData";

const makeClassKey = (num) => {
  const result = getKey(BookingClass, num);

  return result ? result.toLowerCase() : undefined;
};

export default function makeQueryParamsSession(session) {
  const { journeys, show, rebookJourneyId, upgradeJourneyId } = session;
  const [outbound, inbound] = journeys;
  const showKey = getKey(Steps, show).toLowerCase();

  const first = outbound.route[0];
  const last = outbound.route[outbound.route.length - 1];

  const outboundTime = escapeTime(outbound.departure_at.time);
  const inboundTime = inbound
    ? escapeTime(inbound.departure_at.time)
    : undefined;

  const bookingClass = bookingClassFromJourney(outbound);
  const bookingClassBack = bookingClassFromJourney(inbound);

  const counts = countsFromPassengers(session.passengers);
  const extras = extrasFromPassengers(session.passengers);

  return deleteNil({
    from: first.origin.station.id,
    to: last.destination.station.id,
    date: outbound.departure_at.date,
    time: outboundTime,
    class: makeClassKey(bookingClass),
    "date-back": inbound?.departure_at.date,
    "time-back": inboundTime,
    "class-back": makeClassKey(bookingClassBack),
    ...countsToInitialData(counts),
    ...extrasToInitialData(extras),
    show: showKey,
    "rebook-journey-id": rebookJourneyId,
    "upgrade-journey-id": upgradeJourneyId,
  });
}
