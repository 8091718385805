import cookies from "js-cookie";
import React from "react";

import {
  VOUCHER_COOKIE_NAME,
  VOUCHER_UPGRADE_COOKIE_NAME,
} from "#containers/Booking/consts";
import {
  getCommonBookingParams,
  getJourneyFromInitialData,
  makeDeparturesKey,
} from "#containers/Booking/helpers";
import { deleteNil } from "#utils/";
import { CampaignType } from "#utils/enums";

import { useBooking } from "..";
import useBoookingFetchers from "./useBookingFetchers";

export default function useBookingData({
  routes: fetchRoutes = true,
  voucher: fetchVoucher = true,
  departures: fetchDepartures = false,
} = {}) {
  const {
    booking: bookingState,
    canMakeGroupBooking,
    event,
    data,
    departures,
    rebookJourneyId,
    upgradeJourneyId,
    routes,
    setBooking,
    setDepartures,
    setRoutes,
    setVoucher,
    voucher,
    setEventPage,
    setEvent,
    voucherCode: voucherCodeState,
    isRebook,
    isUpgrade,
  } = useBooking();

  const allCookies = cookies.get() || {};
  const voucherCode = voucherCodeState ?? allCookies[VOUCHER_COOKIE_NAME];
  const hasFetchedGenerics = React.useRef(false);
  const departuresFetching = React.useRef(false);

  const { getRoutes, getVoucher, getDepartures, getEventPage, getCampaigns } =
    useBoookingFetchers();

  React.useEffect(() => {
    (async () => {
      if (!hasFetchedGenerics.current) {
        const [campaigns] = await Promise.all([
          getCampaigns(rebookJourneyId, upgradeJourneyId),
        ]);
        const campaignData =
          rebookJourneyId || upgradeJourneyId
            ? campaigns.filter(
                ({ campaign, discount_code }) =>
                  discount_code != null &&
                  campaign.type === CampaignType.DISCOUNT_ADDON_POST
              )[0]
            : undefined;

        const voucherCodeCurrent = voucherCode
          ? voucherCode
          : (rebookJourneyId || upgradeJourneyId) && campaignData
          ? campaignData.discount_code.code
          : undefined;

        const responses = await Promise.all([
          !routes && fetchRoutes ? getRoutes() : undefined,
          fetchVoucher && voucherCodeCurrent && !isRebook
            ? getVoucher(voucherCodeCurrent)
            : undefined,
          event && getEventPage(event),
        ]);
        const [routesData, voucherData, eventData] = responses;

        if (routesData) {
          setRoutes(routesData);
        }

        if (voucherData) {
          setVoucher(voucherData);
          cookies.set(VOUCHER_UPGRADE_COOKIE_NAME, voucherData.code);
        } else {
          cookies.remove(VOUCHER_UPGRADE_COOKIE_NAME);
        }

        if (eventData) {
          setEventPage(eventData);
        } else {
          setEvent(undefined);
        }

        hasFetchedGenerics.current = true;
      }

      if (routes && !departuresFetching.current) {
        departuresFetching.current = true;

        const booking =
          bookingState ||
          getCommonBookingParams({
            routes,
            voucher,
            event,
            data,
            canMakeGroupBooking,
          });

        const { counts, extras, showInbound, from, to, outbound, inbound } =
          booking;

        const outboundKey = makeDeparturesKey(from.id, to.id, outbound.date);
        const inboundKey = showInbound
          ? makeDeparturesKey(to.id, from.id, inbound.date)
          : "–";

        if (fetchDepartures) {
          const commonParams = deleteNil({
            counts,
            extras,
            event,
            voucher,
            rebook_journey_id: rebookJourneyId,
            upgrade_journey_id: upgradeJourneyId,
          });

          const getOutbound = !departures[outboundKey];
          const getInbound = showInbound && !departures[inboundKey];

          const [outboundDepatures, inboundDepartures] = await Promise.all([
            getOutbound
              ? getDepartures({
                  from,
                  to,
                  date: outbound.date,
                  forOutbound: true,
                  ...commonParams,
                })
              : undefined,
            getInbound
              ? getDepartures({
                  from: to,
                  to: from,
                  date: inbound.date,
                  forOutbound: false,
                  ...commonParams,
                })
              : undefined,
          ]);

          const newDeparturesState = { ...departures };

          if (outboundDepatures) {
            newDeparturesState[outboundKey] = outboundDepatures;
          }
          if (inboundDepartures) {
            newDeparturesState[inboundKey] = inboundDepartures;
          }

          if (outboundDepatures || inboundDepartures) {
            setDepartures(newDeparturesState);

            const outboundJourney = getOutbound
              ? getJourneyFromInitialData(
                  outboundDepatures,
                  data.time,
                  data.class
                )
              : {};

            const inboundJourney = getInbound
              ? getJourneyFromInitialData(
                  inboundDepartures,
                  data["time-back"],
                  data["class-back"]
                )
              : {};

            setBooking({
              ...booking,
              outbound: {
                ...booking.outbound,
                ...outboundJourney,
              },
              inbound: {
                ...booking.inbound,
                ...inboundJourney,
              },
            });
          }
        } else {
          setBooking(booking);
        }

        departuresFetching.current = false;
      }
    })();
  }, [
    fetchRoutes,
    fetchVoucher,
    getRoutes,
    getVoucher,
    getCampaigns,
    routes,
    setRoutes,
    setVoucher,
    voucherCode,
    isRebook,
    isUpgrade,
    event,
    getEventPage,
    setEvent,
    setEventPage,
    bookingState,
    canMakeGroupBooking,
    data,
    voucher,
    departures,
    fetchDepartures,
    getDepartures,
    setBooking,
    setDepartures,
    rebookJourneyId,
    upgradeJourneyId,
  ]);
}
