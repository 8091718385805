import { DiscountCodeType } from "#utils/enums";

const DEFAULT = { remaining: 0, value: 0 };

export default function getGiftCodeValue(
  discount_codes,
  consumed_giftcard_money
) {
  const giftCardCode = discount_codes?.find(
    ({ discount }) => discount.type === DiscountCodeType.GIFT_CARD
  );

  if (!giftCardCode) return DEFAULT;

  const { remaining_amount } = giftCardCode.discount.data;

  return {
    value: remaining_amount - (consumed_giftcard_money?.amount || 0),
    remaining: remaining_amount,
  };
}
