import cookies from "js-cookie";
import React from "react";
import { Sentry } from "sentry";

import { useAppContext } from "#components/AppContext";
import topNotification from "#components/topNotification";
import {
  SESSION_EXPIRED_STATUS,
  Steps,
  TIME_TO_WARNING,
} from "#containers/Booking/consts";
import {
  collectDepartureIds,
  getBookingSessionCookieName,
} from "#containers/Booking/helpers";
import { saveBookingSession } from "#containers/Booking/helpers/saveBookingSession";
import {
  RESTART_SESSION_ERROR,
  RESTART_SESSION_ERROR_FULL,
  RESTART_SESSION_ERROR_NETWORK,
} from "#containers/Booking/nodes";
import { TicketStatus } from "#utils/enums";

import { useBooking } from "..";
import useSessionFetchers from "./useSessionFetchers";

export default function useSessionHandlers() {
  const { api, language } = useAppContext();

  const {
    session,
    setAddons,
    setExpiredSession,
    setPsp,
    setSeating,
    setSession,
    setShow,
    setSessionError,
    setLoading,
    event,
    setTimesNearlyUp,
  } = useBooking();

  const { getSeating } = useSessionFetchers();

  const timesNearlyUp = React.useRef();
  const timesUp = React.useRef();

  const resetSessionState = React.useCallback(() => {
    setSession(undefined);
    setAddons(undefined);
    setPsp(undefined);
    setSeating(undefined);
    setSessionError(undefined);
  }, [setAddons, setPsp, setSeating, setSession, setSessionError]);

  const resetSession = React.useCallback(() => {
    cookies.remove(getBookingSessionCookieName());

    resetSessionState();
    setShow(Steps.SEARCH);
  }, [resetSessionState, setShow]);

  const restartSession = React.useCallback(
    async (oldSession = session) => {
      setSessionError(undefined);
      setLoading(true);
      cookies.remove(getBookingSessionCookieName());

      let newSession = undefined;
      try {
        const { journeys } = oldSession;
        const [outbound, inbound] = journeys;
        ({ data: newSession } = await api.createBookingSession({
          journey: collectDepartureIds(outbound.departures),
          booking_class: outbound.departures[0].tickets[0].booking_class,
          passengers: oldSession.passengers.map((p) => p.type),
          event,
          has_stroller: oldSession.passengers.some(
            (passenger) => passenger.has_stroller
          ),
          has_pet: oldSession.passengers.some((p) => p.has_pet),
          wheelchairs: oldSession.passengers.filter((p) => p.has_wheelchair)
            .length,
          walkers: oldSession.passenger.filter((p) => p.has_walker).length,
          voucher_code:
            oldSession.discount != null ? oldSession.discount.code : undefined,
          psp_locale: language,
        }));

        // Used to try to find out how long users with expired sections have been
        // taking.
        window.__createBookingSessionTimestamp = Date.now();

        if (inbound) {
          ({ data: newSession } = await api.addInboundToBookingSession({
            sessionId: newSession.id,
            event,
            journey: collectDepartureIds(inbound.departures),
            booking_class: inbound.tickets[0].booking_class,
          }));
        }

        const hasPassengerInfo = oldSession.passengers.every(
          (ticket) => ticket.firstname
        );

        if (hasPassengerInfo) {
          ({ data: newSession } = await api.updatePassengersOfBookingSession({
            sessionId: newSession.id,
            passengers: oldSession.passengers.map(
              ({ firstname, lastname, email, phone, has_wheelchair, type }) => {
                return {
                  firstname,
                  lastname,
                  email,
                  phone,
                  type,
                  has_wheelchair:
                    has_wheelchair == null ? false : has_wheelchair,
                };
              }
            ),
          }));
        }

        const seatingData = await getSeating(newSession);

        setSession(newSession);
        setLoading(false);
        setSeating(seatingData);
      } catch (error) {
        setLoading(false);
        console.error(
          "Booking: Failed to restart session",
          error,
          error.response
        );

        const status = error?.response?.status;
        if (status === 409) {
          // The departure has become full, so remove the "restart" button.
          setExpiredSession(undefined);
          topNotification(React.cloneElement(RESTART_SESSION_ERROR_FULL));
        } else if (status != null) {
          topNotification(
            React.cloneElement(RESTART_SESSION_ERROR, { status })
          );
        } else if (error.noResponse) {
          error.message = `Network error: ${error.message}`;
          topNotification(RESTART_SESSION_ERROR_NETWORK);
        } else {
          topNotification(
            React.cloneElement(RESTART_SESSION_ERROR, { status: 1500 })
          );
        }
        Sentry.captureException(error);
      }
    },
    [
      api,
      event,
      getSeating,
      language,
      session,
      setExpiredSession,
      setLoading,
      setSeating,
      setSession,
      setSessionError,
    ]
  );

  const createUpgradeSession = React.useCallback(
    async (oldJourney, bookingClass, voucherCode) => {
      setSessionError(undefined);
      setLoading(true);
      cookies.remove(getBookingSessionCookieName());

      const activeTickets = oldJourney.departures[0].tickets.filter(
        (t) => t.status !== TicketStatus.CANCELED
      );

      const oldSessionData = {
        journey: collectDepartureIds(oldJourney.departures),
        booking_class: bookingClass,
        passengers: activeTickets.map((t) => t.passenger.type),
        event,
        has_stroller: activeTickets.some((t) => t.passenger.has_stroller),
        has_pet: activeTickets.some((t) => t.passenger.has_pet),
        wheelchairs: activeTickets.filter((t) => t.passenger.has_wheelchair)
          .length,
        voucher_code:
          oldJourney.discount != null ? oldJourney.discount.code : voucherCode,
        psp_locale: language,
        upgrade_journey_id: oldJourney.id,
      };

      let newSession = undefined;
      try {
        ({ data: newSession } = await api.createBookingSession(oldSessionData));

        saveBookingSession({
          sessionId: newSession.id,
          expires: new Date(newSession.validUntil.localTimestamp),
        });

        // Used to try to find out how long users with expired sections have been
        // taking.
        window.__createBookingSessionTimestamp = Date.now();

        const seatingData = await getSeating(newSession);

        setSession(newSession);
        setLoading(false);
        setSeating(seatingData);
      } catch (error) {
        setLoading(false);
        console.error(
          "Booking: Failed to create booking session",
          error,
          error.response
        );

        const status = error?.response?.status;
        if (status === 409) {
          // The departure has become full, so remove the "restart" button.
          setExpiredSession(undefined);
          topNotification(React.cloneElement(RESTART_SESSION_ERROR_FULL));
        } else if (status != null) {
          topNotification(
            React.cloneElement(RESTART_SESSION_ERROR, { status })
          );
        } else if (error.noResponse) {
          error.message = `Network error: ${error.message}`;
          topNotification(RESTART_SESSION_ERROR_NETWORK);
        } else {
          topNotification(
            React.cloneElement(RESTART_SESSION_ERROR, { status: 1500 })
          );
        }
        Sentry.captureException(error);
      }
    },
    [
      api,
      event,
      getSeating,
      language,
      setExpiredSession,
      setLoading,
      setSeating,
      setSession,
      setSessionError,
    ]
  );

  const stopTimers = React.useCallback(() => {
    if (timesUp.current) {
      clearTimeout(timesUp.current);
      timesUp.current = undefined;
    }
    if (timesNearlyUp.current) {
      clearTimeout(timesNearlyUp.current);
      timesNearlyUp.current = undefined;
    }
  }, []);

  const startTimers = React.useCallback(() => {
    if (session == null) {
      return;
    }

    const timeLeft = Math.max(
      0,
      session.validUntil.localTimestamp - Date.now()
    );
    const timeToWarning = Math.max(0, timeLeft - TIME_TO_WARNING);

    stopTimers();

    timesUp.current = setTimeout(() => {
      timesUp.current = undefined;
      setTimesNearlyUp(false);
      setExpiredSession(session);
      setSessionError({ status: SESSION_EXPIRED_STATUS });
      stopTimers();
    }, timeLeft);

    timesNearlyUp.current = setTimeout(() => {
      timesNearlyUp.current = undefined;
      setTimesNearlyUp(true);
    }, timeToWarning);
  }, [
    session,
    setExpiredSession,
    setSessionError,
    setTimesNearlyUp,
    stopTimers,
  ]);

  return {
    createUpgradeSession,
    resetSession,
    restartSession,
    startTimers,
    stopTimers,
  };
}
