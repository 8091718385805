import PropTypes from "prop-types";

import { NAV_CHILDREN_ID } from "#components/Nav";

import { Steps, SwishState } from "../consts";

export const BOOKING_STATE_COOKIE_NAME = "last_started_booking";

export const initialDataPropType = PropTypes.shape({
  date: PropTypes.string,
  time: PropTypes.string,
  "date-back": PropTypes.string,
  "time-back": PropTypes.string,
  class: PropTypes.string,
  "class-back": PropTypes.string,
  adults: PropTypes.number,
  event: PropTypes.string,
  stroller: PropTypes.number,
  pet: PropTypes.number,
  wheelchairs: PropTypes.number,
  show: PropTypes.string,
});

export const layoutPropType = PropTypes.shape({
  hideSteps: PropTypes.bool,
  hideSearchForm: PropTypes.bool,
  stickySteps: PropTypes.bool,
  scrollSelector: PropTypes.string,
  navSelector: PropTypes.string,
});

export const layoutDefaultProp = {
  hideSteps: false,
  hideSearchForm: false,
  stickySteps: false,
  scrollSelector: undefined,
  navSelector: `#${NAV_CHILDREN_ID}`,
};

export const stepsPropType = PropTypes.arrayOf(PropTypes.number);
export const stepsDefaultProp = Object.values(Steps);

export const BookingContextPropTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  initialData: initialDataPropType.isRequired,
  layout: layoutPropType.isRequired,
  isRebook: PropTypes.bool.isRequired,
  steps: stepsPropType,
};

export const BookingContextDefaultProps = {
  steps: stepsDefaultProp,
  children: undefined,
};

export const INITIAL_SWISH_STATUS = {
  status: SwishState.NONE,
  error: undefined,
};
