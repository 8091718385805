// Copied from src/booking/enums.py and src/voucher/enums.py.

export const BookingClass = {
  FIX: 1,
  FLEX: 2,
  PLUS: 3,
  FIRST_PLUS: 4,
};

export const PassengerType = {
  ADULT: 1,
  CHILD6: 2,
  INFANT: 3,
  SENIOR: 4,
  STUDENT: 5,
  YOUTH: 6,
  CHILD15: 7,
};

export const TicketStatus = {
  NOT_CANCELED: 1,
  CANCELED: 2,
};

export const DiscountCodeType = {
  VOUCHER: 1,
  MONTHLY_CARD: 2,
  YEARLY_CARD: 3,
  HALF_YEARLY_CARD: 4,
  CLIP_CARD: 5,
  GIFT_CARD: 6,
};

export const DiscountStatus = {
  ACTIVE: 1,
  DISABLED: 2,
};

export const COMMUTER_CARD_DISCOUNT_TYPES = [
  DiscountCodeType.CLIP_CARD,
  DiscountCodeType.MONTHLY_CARD,
  DiscountCodeType.HALF_YEARLY_CARD,
  DiscountCodeType.YEARLY_CARD,
];

export const AddonsType = {
  NSF: 1,
  MEAL: 2,
  SEATING: 3,
  DRINK: 4,
  REBOOKING_FEE: 5,
  PASSENGER_CHANGE_FEE: 6,
  UPGRADE_FEE: 7,
};

export const AddonsStatus = {
  ACTIVE: 1,
  INACTIVE: 2,
  CANCELLED: 3,
};

export const MealSubTypes = {
  REGULAR: 1,
  GLUTENFREE: 2,
  VEGETARIAN: 3,
  VEGAN: 4,
  LACTOSEFREE: 5,
  SEAFOODFREE: 6,
};

export const MealStatus = {
  ACTIVE: 1,
  INACTIVE: 2,
  CANCELLED: 3,
};

export const BookingStatus = {
  SESSION: 1,
  BOOKING: 2,
  CANCELLED: 3,
  ABANDONED: 4,
  EXPIRED_SESSION: 5,
};

export const UserBookingPermission = {
  OWNER: 10,
  PASSENGER: 20,
};

export const EventLogTypes = {
  INVOICE_ROW_CANCELLATION: 1,
  INVOICE_ROW_CANCELLATION_WITH_REFUND: 2,
  BOOKING_SEATS_MOVED: 3,
  INVOICE_ROW_REFUND_ONLY: 4,
  LEGACY_CANCELLATION: 5,
  ABANDON_BOOKING: 6,
  RESEND_BOOKING_CONFIRMATION_EMAIL: 7,
  RESEND_BOOKING_CONFIRMATION_SMS: 8,
  BOOKING_SESSION_EXPIRED: 9,
  NOTIFY_CAMPAIGN_TRIGGER: 10,
  SEATS_MOVED: 11,
  HANDLE_DELAYED_DEPARTURE: 12,
  DEPARTURE_PASSENGER_WARNING: 13,
  DEPARTURE_INFO_DOWNLOADED: 14,
  VOUCHERS_DOWNLOADED: 15,
  ADDON_MEAL_DIET_CHANGED: 16,
  ADDON_MEAL_CHANGED: 17,
  DEPARTURE_TICKETS_MOVED: 18,
  SYSTEM_CLEANUP_SWISH_REFUND: 19,
  NOTIFY_CAMPAIGN_REPORT_UPDATE: 20,
  ADMIN_CREDIT_POINTS: 21,
  ADMIN_UPGRADE_TIER_LEVEL: 22,
  USER_UPDATE: 23,
  ADMIN_LOYALTY_ADD_BONUS: 61,
  ADMIN_LOYALTY_ADD_TIER: 62,
  ADMIN_LOYALTY_REMOVE_BONUS: 71,
};

export const UserEventLogTypes = {
  ADMIN_CREDIT_POINTS: EventLogTypes.ADMIN_CREDIT_POINTS,
  ADMIN_UPGRADE_TIER_LEVEL: EventLogTypes.ADMIN_UPGRADE_TIER_LEVEL,
  ADMIN_LOYALTY_ADD_BONUS: EventLogTypes.ADMIN_LOYALTY_ADD_BONUS,
  ADMIN_LOYALTY_ADD_TIER: EventLogTypes.ADMIN_LOYALTY_ADD_TIER,
  ADMIN_LOYALTY_REMOVE_BONUS: EventLogTypes.ADMIN_LOYALTY_REMOVE_BONUS,
};

export const TierLevel = {
  WHITE: 100,
  RED: 200,
  BLACK: 300,
};

export const UserTierLevel = {
  BASIC: 1,
  MEDIUM: 2,
  HIGH: 3,
};

export const UserBonusType = {
  REWARD_TRIP: 1,
  REWARD_COMMUTER: 2,
  REWARD_SYSTEM: 11,
  REWARD_SUPPORT: 12,
  PURCHASE_TRIP: 21,
  PURCHASE_COMMUTER: 22,
  REFUND_TRIP: 31,
  REFUND_COMMUTER: 32,
  REFUND_SUPPORT: 33,
};

export const ClubTierLevels = {
  WHITE: 0,
  RED: 500,
  BLACK: 1500,
};

export const ProductType = {
  COMMUTER: 1,
  CLIP_CARD: 2,
  BOOKING_PASSENGER_CHANGE: 3,
};

export const PSP = {
  KLARNA: 1,
  SILVERRAIL: 2,
  SWISH: 3,
  INVOICE: 9,
  TRAVEL_CLEARING: 10,
  REBEL: 199,
  LOYALTY: 299,
  BONUS: 399,
};

export const PaymentStatus = {
  ORDER: 1,
  PAYED: 2,
};

export const OrderStatus = {
  INIT: 1,
  COMPLETED: 2,
  EXPIRED: 3,
};

export const TrainDirection = {
  FORWARD: 1,
  BACKWARD: 2,
};

export function getKey(enumObject, value) {
  const match = Object.entries(enumObject).find(
    ([, value2]) => value === value2
  );
  return match == null ? undefined : match[0].toLowerCase();
}

export function getValue(enumObject, key) {
  return enumObject[key.toUpperCase()];
}

export const VEHICLE_TYPES = {
  BUS_REPLACEMENT: 714,
  BUS: 702,
  TRAIN: 101,
};

export const VEHICLE_BUSES = [VEHICLE_TYPES.BUS, VEHICLE_TYPES.BUS_REPLACEMENT];

export const TRAFFIC_PROVIDERS = {
  SJ: 74,
  SL: 275,
  VASTTRAFIK: 279,
  ARLANDA_EXPRESS: 287,
  MTRX: 812,
};

export const TRAFFIC_PROVIDER_NAMES = {
  SJ: "SJ",
  SL: "SL",
  VASTTRAFIK: "Västtrafik",
  ARLANDA_EXPRESS: "Arlanda Express",
  MTRX: "VR",
};

export const TRANSPORT_MODES = {
  FOOT: 0,
  BUS: 1,
  TROLLEYBUS: 2,
  TRAM: 3,
  METRO: 4,
  RAIL: 5,
  WATER: 6,
  FERRY: 7,
};

export function getTransportMode(key) {
  if (key === undefined) {
    return undefined;
  } else if (VEHICLE_BUSES.includes(key)) {
    return TRANSPORT_MODES.BUS;
  }
  return TRANSPORT_MODES.RAIL;
}

export const CampaignType = {
  GENERIC: 1,
  DISCOUNT_TRAVEL: 10,
  DISCOUNT_ADDON_PRE: 11,
  DISCOUNT_ADDON_POST: 12,
  BONUS_DOUBLE: 20,
};

export const TrackingMetric = {
  CUSTOMER_SERVICE_TICKET_SENT: "Customer service ticket sent",
  BOOKING_ROUTE_SEARCHED: "Route searched",
  BOOKING_DATES_SEARCHED: "Dates searched",
  BOOKING_TRIP_SEATS: "Trip seats",
  BOOKING_TRIP_MEALS: "Trip meals",
};

export const CorporateMemberRequestStatus = {
  INVITED: 0,
  REQUESTED: 1,
  APPROVED: 2,
  DENIED: 3,
  CANCELLED: 4,
};

export const DepartureMessageType = {
  TRAFFIC: 1,
  CATERING: 2,
  WHEELCHAIR: 3,
  ALTERATION: 4,
};

export const AnnouncementMessageType = {
  INFORMATION: 0,
  IMPORTANT: 1,
  CRITICAL: 2,
};
